@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
.ReactModal__Content.my-drawer-content {
  box-shadow: 0px 32px 72px rgba(50, 50, 93, 0.12), 0px 10px 15px rgba(0, 0, 0, 0.07), 0px -4px 16px rgba(0, 0, 0, 0.03);
  margin-right: -27rem;
  opacity: 0;
  transition: margin 200ms ease-in-out, opacity 200ms ease-in-out;
  background-color: white;
}

.my-drawer-content.ReactModal__Content--after-open {
  margin-right: 0rem;
  opacity: 1;
}

.my-drawer-content.ReactModal__Content--before-close {
  margin-right: -27rem;
  opacity: 0;
}

.custom-form-tooltip,
.custom-table-tooltip {
  box-shadow: 0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 8px !important ;
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-size: 0.875rem !important;
  line-height: 1.375rem;
  z-index: 1000 !important;
  opacity: 1 !important;
  padding: 1.8rem 2rem;
  max-width: 20rem;
}

.custom-form-tooltip {
  font-weight: 500;
}

.custom-table-tooltip {
  font-size: 1.125rem !important;
  line-height: 1.625rem;
}

/*!
 * Based on:
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html,
body {
  height: 100%;
}
@at-root {
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: "roboto", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  color: #333a46;
  text-align: left;
  background-color: white;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 0 1.8125rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: 500;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #027AA2;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  transition: color 0.2s;
}
a:hover {
  color: #014156;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
caption {
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333a46;
}
button {
  border-radius: 0;
  transition: color 0.2s;
}
button:not(:disabled):not(.disabled) {
  cursor: pointer;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #636974;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .scroll-to-top {
    display: none;
  }
}
h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
  font-weight: bold;
  font-weight: 700 !important;
}
h3 b,
h4 b,
h5 b,
h6 b,
.h3 b,
.h4 b,
.h5 b,
.h6 b,
h3 strong,
h4 strong,
h5 strong,
h6 strong,
.h3 strong,
.h4 strong,
.h5 strong,
.h6 strong {
  font-weight: bold;
}
h1,
.h1 {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  color: #003463;
  font-size: 1.8125rem;
  line-height: 2.625rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
h1 b,
.h1 b,
h1 strong,
.h1 strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
h2,
.h2 {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
  color: #636974;
  font-size: 1.25rem;
  line-height: 1.375rem;
  padding: 0.25rem 0;
  text-transform: uppercase;
}
.h2-alt {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  color: #003463;
  font-size: 1.625rem;
  line-height: normal;
  letter-spacing: 0.05em;
}
.h2-alt b,
.h2-alt strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
h3,
.h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
h4,
.h4 {
  font-size: 1rem;
  line-height: 1.5rem;
}
h5,
.h5 {
  font-size: 0.75rem;
  line-height: 1.25rem;
}
h6,
.h6 {
  font-size: 0.75rem;
  line-height: 1.25rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
small,
.small {
  font-size: 1rem;
  font-weight: 400;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
p {
  max-width: 700px;
  color: #333a46;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #40424B;
}
.blockquote-footer::before {
  content: "\2014 \00A0";
}
code {
  font-size: 87.5%;
  color: #FF5865;
  word-break: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #17171B;
  border-radius: 0;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  box-shadow: none;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #17171B;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
body {
  background: white;
}
body,
.perimeter,
.perimeter-inner,
.content,
.content-inner {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
.perimeter-inner {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.content-inner {
  min-width: 748px;
}
@media screen {
  .content-inner {
    background: #F5F6F7;
  }
}
.alerts-layout {
  padding: 2rem;
}
.alerts-layout-list {
  width: 360px;
}
.alerts-layout-current {
  background-color: white;
  position: fixed;
  right: 2rem;
  padding: 2rem;
  width: calc(100% - 360px - 2rem - 2rem - 2rem);
}
.alerts-layout-current-header {
  display: flex;
  align-items: flex-start;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #E8E9EB;
}
.alerts-layout-current-header > * {
  flex: 1 1 auto;
}
.alerts-layout-current-header .btn-archive {
  flex: 0 0 auto;
  margin-left: 2rem;
}
.alerts-layout-current-date {
  color: #979EA8;
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: 1.5rem;
}
.alerts-layout-current-title {
  font-size: 1.125rem;
  font-weight: bold;
  padding-bottom: 1rem;
}
.alerts-layout-current-message {
  font-size: 1rem;
}
.btn-archive {
  background: transparent none;
  border: 0 none;
  -webkit-user-select: none;
          user-select: none;
  color: #027AA2;
  text-align: center;
  font-size: 1rem;
  width: 70px;
}
.btn-archive:hover {
  color: #003463;
}
.btn-archive:focus {
  outline: 0 none;
}
.btn-archive > span {
  display: block;
  background: currentColor;
  width: 50px;
  font-size: 1.25rem;
  line-height: 50px;
  border-radius: 50%;
  margin: 0 auto 0.5rem;
}
.btn-archive > span > svg {
  color: white;
  position: relative;
  top: -0.125rem;
}
.p-content-lg p {
  max-width: 900px;
}
.company-engagement-table th {
  white-space: nowrap;
  margin-right: 15px;
}
.company-engagement-table th.company-engagement-count {
  padding-bottom: 1rem;
}
.company-engagement-table .company-engagement-count {
  width: 300px;
  text-align: center;
  white-space: nowrap;
}
.company-engagement-table tfoot td {
  font-weight: bold;
}
.dashboard-container {
  max-width: 1246px;
  margin: 0 auto;
  width: 100%;
}
.dashboard-container .card-deck .card {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  color: #333a46;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(33.3% - 30px);
  margin-bottom: 2rem;
}
.dashboard-container .card-deck .card.card-double {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(33.3%*2 - 30px);
}
.dashboard-container .card-deck .card-body {
  padding: 1.5rem;
}
.dashboard-container .h2-alt {
  color: #333a46;
  font-size: 1.25rem;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 1rem;
}
.dashboard-container .comparison-doughnut {
  position: relative;
}
.dashboard-container .chart-group {
  display: flex;
}
@media (max-width: 991px) {
  .dashboard-container .chart-group {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  .dashboard-container .chart-group {
    align-items: flex-end;
  }
}
.dashboard-container .chart-group .chart {
  flex: 1 0 30%;
  text-align: center;
}
@media (max-width: 991px) {
  .dashboard-container .chart-group .chart {
    margin-bottom: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .dashboard-container .chart-group .chart {
    margin-right: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .dashboard-container .chart-group .chart {
    margin-right: 1.5rem;
  }
}
.dashboard-container .chart-group .chart .caption {
  color: #003463;
  font-weight: normal;
  font-size: 1.25rem;
  margin-top: calc(-50% - 12px);
  width: 100%;
}
.dashboard-container .chart-group .chart-text {
  flex: 1 0 50%;
  font-weight: 400;
  line-height: 1.4em;
}
.dashboard-container .chart-group .chart-text a {
  color: #003463;
  display: block;
  padding-top: 1rem;
  text-align: right;
  white-space: nowrap;
}
.dashboard-container .card-ranking-group {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.dashboard-container .card-ranking-group .logo-glyph {
  color: #003463;
  display: block;
  width: 85px;
}
.dashboard-container .card-ranking-text {
  text-align: center;
}
.dashboard-container .card-ranking-text h3 {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  color: #003463;
  font-size: 3rem;
  font-weight: normal !important;
  line-height: normal;
  margin-bottom: 0;
  position: relative;
  text-transform: none;
}
.dashboard-container .card-ranking-text h3 b,
.dashboard-container .card-ranking-text h3 strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.dashboard-container .card-ranking-text h3 small {
  color: #333a46;
  font-size: 2rem;
  font-weight: 400;
  position: absolute;
  top: 0.7rem;
}
.dashboard-container .card-ranking-subtext {
  display: block;
}
.dashboard-container .card-col-group {
  display: flex;
  height: 100%;
}
.dashboard-container .card-col-group .card-col {
  flex: 1 0 50%;
}
.dashboard-container .panel-link {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  align-items: center;
  color: #027AA2;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  padding: 1rem;
  position: relative;
  text-decoration: none;
}
.dashboard-container .panel-link b,
.dashboard-container .panel-link strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.dashboard-container .panel-link.active {
  color: #333a46;
  cursor: default;
}
.dashboard-container .panel-link.active::after {
  content: '';
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 20px solid #008087;
  display: block;
  height: 0;
  position: absolute;
  right: -1.5rem;
  top: calc(50% - 10px);
  width: 0;
}
.dashboard-container .panel-link .issue-icon {
  display: inline-block;
  margin-right: 1rem;
  text-align: right;
}
.dashboard-container .panel-link .issue-icon svg {
  width: 2rem !important;
  height: 2rem;
}
.dashboard-container .panel-link-text {
  display: inline-block;
}
.dashboard-container .card-panels {
  justify-content: center;
  background: #008087;
  background: linear-gradient(90deg, #008087 0%, #00446d 100%);
  color: #fff;
  display: flex;
  flex-direction: column;
}
.dashboard-container .card-panel {
  display: none;
}
.dashboard-container .card-panel .card-body {
  padding-left: 2rem;
  padding-right: 2rem;
}
.dashboard-container .card-panel.active {
  display: block;
}
.dashboard-container .card-panel table {
  width: 100%;
}
.dashboard-container .card-panel table th,
.dashboard-container .card-panel table td {
  padding: 0.5rem;
}
.dashboard-container .card-panel table th:first-of-type,
.dashboard-container .card-panel table td:first-of-type {
  padding-left: 0;
}
.dashboard-container .card-panel table th:last-of-type,
.dashboard-container .card-panel table td:last-of-type {
  padding-right: 0;
  text-align: right;
  white-space: nowrap;
}
.dashboard-container .card-panel table th {
  font-size: 1.4rem;
  font-weight: 300;
  padding-bottom: 1rem;
}
.dashboard-container .card-panel table tbody tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.dashboard-container .card-panel table tbody tr:last-of-type {
  border-width: 0;
}
.dashboard-container .stat-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  padding-top: 2rem;
}
.dashboard-container .stat-group .stat {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(30% - 1rem);
  flex-direction: column;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  text-align: center;
}
.dashboard-container .stat-group .stat svg {
  height: 4rem;
  margin-bottom: 0.75rem;
  width: 2.5rem;
}
.dashboard-container .stat-group .stat-measure {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.dashboard-container .stat-group .stat-title {
  line-height: 1.2em;
}
.dashboard-container .stats-total {
  font-size: 1.25rem;
  line-height: 1.4em;
  text-align: center;
}
.dashboard-container .stats-total strong:first-of-type {
  display: block;
}
.dashboard-container .react-bootstrap-table {
  margin-bottom: 0;
}
.dashboard-container .react-bootstrap-table table {
  margin-bottom: 1rem;
}
.dashboard-container .link-footer {
  display: flex;
}
.dashboard-container .link-footer.bottom-right {
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
}
.dashboard-container .link-footer > span {
  flex: 1 0 50%;
  text-align: right;
}
.dashboard-container .link-footer a {
  color: #003463;
  display: block;
  font-weight: 400;
  text-align: right;
  white-space: nowrap;
}
.dashboard-section {
  background-image: linear-gradient(to bottom, rgba(199, 203, 207, 0) 0%, #C7CBCF 100%);
  background-repeat: repeat-x;
}
.dashboard-card-grid {
  display: flex;
  flex-flow: column;
}
.dashboard-card {
  background-color: #fff;
}
@media (max-width:1249px) {
  .dashboard-card {
    margin-bottom: 1rem;
  }
  .dashboard-card.is-main {
    order: -1;
  }
}
.dashboard-card-content {
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}
.dashboard-card-header {
  font-size: 0.875rem;
  line-height: 1.25rem;
  flex: 0 0 auto;
  height: 3.5rem;
}
.dashboard-card-body {
  flex: 1 0 auto;
}
.dashboard-card-footer {
  font-size: 0.875rem;
  line-height: 1.25rem;
  flex: 0 0 auto;
  align-self: flex-start;
}
.dashboard-card-name {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  font-size: 1.3125rem;
  line-height: 1.2;
  letter-spacing: normal;
}
.dashboard-card-name b,
.dashboard-card-name strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
@media (min-width:1250px) {
  .dashboard-card-grid {
    margin: 0 -1rem;
    flex-flow: row;
  }
  .dashboard-card {
    flex: 0 1 33%;
    margin: 0 1rem;
  }
  .dashboard-card-footer {
    width: 100%;
  }
}
.index-center-breakdown {
  background-color: white;
  padding: 1.5rem 2rem;
}
.index-center-breakdown-title {
  font-size: 1.25rem;
  line-height: 2rem;
}
@media (max-width: 1199px) {
  .index-center-intro {
    margin-bottom: 2rem;
  }
}
@media (min-width: 1200px) {
  .index-center-header {
    display: flex;
    justify-content: space-between;
  }
  .index-center-header > * {
    flex: 0 0 auto;
    width: calc(50% - 1rem);
  }
}
.index-center-list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
}
.index-center-list-item {
  margin: 0;
  border-top: 1px solid #E8E9EB;
  padding: 1rem 0;
}
.index-center-list-item.is-new-passive {
  position: relative;
}
.index-center-list-item.is-new-passive > * {
  position: relative;
  z-index: 1;
}
.index-center-list-item.is-new-passive:before {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(0, 197, 150, 0.1);
  top: 0.25rem;
  right: -1.25rem;
  bottom: 0.25rem;
  left: -1.25rem;
  z-index: 0;
  pointer-events: none;
}
.index-center-list-item-header {
  display: flex;
  align-items: center;
  min-height: 2.625rem;
}
.index-center-list-item-header > * + * {
  margin-top: 0.75rem;
}
.index-center-list-item-title {
  font-weight: 500;
}
.index-center-list-item-options {
  display: flex;
  align-items: center;
}
.index-center-list-item-content {
  display: flex;
  background-color: white;
  padding: 1rem;
  margin-top: 1rem;
}
.index-center-list-item-content .index-center-list-item-description {
  flex-grow: 1;
}
.index-center-list-item-content .index-center-list-item-description > div {
  line-height: 1.25em;
  max-width: 32rem;
}
.index-center-list-item-content .index-center-list-item-actions {
  flex: 0 0 14rem;
}
.index-center-list-item-content .index-center-list-item-actions .btn + .btn {
  margin-top: 0.5rem;
}
@media (max-width: 1159px) {
  .index-center-list-item-content {
    flex-flow: row wrap;
    align-items: center;
  }
  .index-center-list-item-content .index-center-list-item-wizard {
    flex: 1 0 auto;
  }
  .index-center-list-item-content .index-center-list-item-actions {
    order: 1;
    flex: 0 0 9rem;
  }
  .index-center-list-item-content .index-center-list-item-description {
    margin-top: 1rem;
    order: 2;
  }
}
@media (max-width: 1159px) and (min-width: 980px) {
  .index-center-list-item-content .index-center-list-item-actions {
    display: flex;
    padding-bottom: 1rem;
  }
  .index-center-list-item-content .index-center-list-item-actions .btn + .btn {
    margin-left: 0.5rem;
    margin-top: 0;
  }
}
@media (min-width: 1160px) {
  .index-center-list-item-content .index-center-list-item-description {
    margin-left: 2rem;
  }
  .index-center-list-item-content .index-center-list-item-actions {
    margin-left: 2rem;
  }
}
.index-center-list-item-assign {
  display: flex;
  align-items: center;
}
.index-center-list-item-options,
.index-center-list-item-status {
  flex: 0 0 18.75rem;
}
.index-center-list-item-actions {
  flex: 0 0 15rem;
}
.index-center-list-item-recommendation {
  flex: 0 0 34.5rem;
  color: #00C596;
}
@media (max-width: 1299px) {
  .index-center-list-item-header {
    flex-flow: row wrap;
  }
  .index-center-list-item-header > * {
    width: 100%;
  }
  .index-center-list-item-header br {
    display: none;
  }
  .index-center-list-item-status,
  .index-center-list-item-options {
    width: 18.75rem;
    margin-right: 0.5rem;
  }
}
@media (min-width: 1300px) {
  .index-center-list-item-header > * + * {
    margin-top: 0;
    margin-left: 1rem;
  }
  .index-center-list-item-title {
    flex: 1 1 25%;
  }
  .index-center-list-item-current-index {
    flex: 1 1 25%;
  }
}
.login-layout {
  background-color: #003463;
  background-image: linear-gradient(10deg, #2a86a0, #003462);
  text-align: center;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
}
.login-layout > canvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.login-layout .logo-glyph {
  height: 90px;
  width: 90px;
  margin-bottom: 0.75rem;
}
.login-layout,
.login-layout a,
.login-layout label,
.login-layout .form-control,
.login-layout .form-control:focus {
  color: white;
}
.login-layout .form-control,
.login-layout .form-control:focus {
  background: transparent;
}
.login-layout .form-control {
  border-color: white;
}
.login-layout .form-group {
  text-align: left;
  margin-bottom: 1rem;
  min-height: 0;
}
.login-layout .custom-control {
  text-align: left;
}
.login-layout label {
  font-weight: 300;
  font-weight: 300 !important;
}
.login-layout label b,
.login-layout label strong {
  font-weight: 500;
}
.login-layout a {
  opacity: 0.7;
}
.login-layout a:hover,
.login-layout a:focus,
.login-layout a:active {
  opacity: 1;
  text-decoration: none;
}
.login-layout .custom-control-label:before {
  border-color: white;
  background: transparent;
}
.login-layout .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: transparent;
}
.login-layout .custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.2);
}
.login-layout .btn.btn-white.btn-outline.disabled,
.login-layout .btn.btn-white.btn-outline:disabled {
  border-color: currentColor;
  color: rgba(255, 255, 255, 0.4);
}
.login-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.login-layout-inner {
  padding: 2rem;
  width: 26rem;
  max-width: 100%;
  position: relative;
}
.firm-ESG-description textarea {
  min-height: 215px;
}
.issue-grid-headers {
  float: right;
  display: flex;
  justify-content: flex-end;
  width: 71.5%;
}
.issue-grid-headers > span {
  flex: 1 0 14.25%;
  text-align: center;
}
.issue-grid-group {
  margin-bottom: 2.5rem;
}
.issue-grid .issue-grid-groups > div:nth-of-type(1) .col-title {
  border-left: 5px solid #007878;
}
.issue-grid .issue-grid-groups > div:nth-of-type(2) .col-title {
  border-left: 5px solid #255D78;
}
.issue-grid .issue-grid-groups > div:nth-of-type(3) .col-title {
  border-left: 5px solid #003463;
}
.issue-grid .col-group {
  align-items: stretch;
  display: flex;
}
.issue-grid .col-group .col {
  align-items: center;
  border-color: #C7CBCF;
  border-style: solid;
  border-width: 0 1px 1px 0;
  display: flex;
  flex: 1 0 14.25%;
  justify-content: center;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}
.issue-grid .col-group .col-title {
  border-left: 5px solid #636974;
  flex: 1 0 28.5%;
  justify-content: flex-end;
  padding-right: 2.5rem;
  padding-left: 1rem;
  position: relative;
  text-align: right;
}
.issue-grid .col-group .col-title a {
  font-size: 1.25rem;
}
.issue-grid .col-group .col-title a:hover {
  text-decoration: none;
}
.issue-grid .col-group .col-title a:hover::after {
  content: '\00BB';
  display: inline-block;
  font-size: 2rem;
  position: absolute;
  right: 16px;
  top: calc(50% - 14px);
}
.issue-grid .col-group > span:nth-of-type(2) {
  order: 2;
}
.issue-grid .col-group > span:nth-of-type(3) {
  order: 4;
}
.issue-grid .col-group > span:nth-of-type(4) {
  order: 6;
}
.issue-grid .col-group > span:nth-of-type(5) {
  order: 8;
}
.issue-grid .col-group > span:nth-of-type(6) {
  order: 10;
}
.issue-grid .col-group > span.col-rating-a {
  order: 3;
}
.issue-grid .col-group > span.col-rating-b {
  order: 5;
}
.issue-grid .col-group > span.col-rating-c {
  order: 7;
}
.issue-grid .col-group > span.col-rating-d {
  order: 9;
}
.issue-grid .col-group > span.col-rating-e {
  order: 11;
}
.issue-grid h3 {
  border-bottom: 1px solid #C7CBCF;
  margin-bottom: 0;
  padding-bottom: 0.75rem;
}
.onboarding-indexes-list {
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-top: 1px solid #C7CBCF;
}
.onboarding-indexes-list-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid #E8E9EB;
}
.onboarding-indexes-list-item > * + * {
  margin-left: 0.75rem;
}
.onboarding-indexes-list-item-title {
  flex: 1 1 40%;
  font-weight: 500;
}
.onboarding-indexes-list-item-current-index,
.onboarding-indexes-list-item-options {
  flex: 1 1 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.onboarding-indexes-list-item-current-index > * + *,
.onboarding-indexes-list-item-options > * + * {
  margin-left: 0.75rem;
}
.onboarding-indexes-list-item-actions {
  flex: 0 0 210px;
}
@media (max-width: 1199px) {
  .onboarding-indexes-list-item {
    flex-flow: row wrap;
  }
  .onboarding-indexes-list-item-title {
    flex-basis: 100%;
    margin-bottom: 0.75rem;
  }
  .onboarding-indexes-list-item-title + * {
    margin-left: 0;
  }
  .onboarding-indexes-list-item-current-index {
    flex: 1 1 auto;
  }
  .onboarding-indexes-list-item-options {
    flex: 0 0 auto;
  }
}
.landing-layout {
  background-color: #003463;
  background-image: linear-gradient(10deg, #2a86a0, #003462);
  text-align: center;
  display: flex;
  flex: 1 0 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.landing-layout > canvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.landing-layout .logo-glyph {
  height: 90px;
  width: 90px;
  margin-bottom: 0.75rem;
}
.landing-layout h1 {
  color: #fff;
  font-size: 22px;
}
.landing-layout-wrapper {
  display: flex;
  flex-direction: column;
}
.landing-layout-inner {
  padding: 4rem;
  width: 36rem;
  text-align: left;
  max-width: 100%;
  position: relative;
  background: rgba(0, 52, 99, 0.6);
  margin: 2rem auto 0;
  color: #fff;
}
.landing-layout-inner p {
  color: #fff;
}
.landing-btn-wrapper {
  display: block;
  text-align: center;
}
.padded-container {
  width: 100%;
  height: 100%;
  padding: 2rem 7.5%;
}
.panels.is-portfolio {
  max-height: 477px;
}
.panels.is-portfolio .loading-spinner-graphic {
  top: 7.2rem;
}
.panels.is-portfolio .panel.is-sectors,
.panels.is-portfolio .panel.is-companies {
  display: flex;
  flex-flow: column;
  padding-left: 1rem;
}
.panels.is-portfolio .panel.is-sectors .loading-spinner,
.panels.is-portfolio .panel.is-companies .loading-spinner {
  left: 1rem;
}
.panels.is-portfolio .panel.is-sectors .panel-graph {
  flex: 0 0 auto;
}
.panels.is-portfolio.is-panel-2-open .panel.is-distributions {
  padding-right: 1rem;
}
.panels.is-portfolio.is-panel-3-open .panel.is-sectors {
  padding-right: 1rem;
}
.panels.is-portfolio.is-panel-3-open .panel.is-sectors .loading-spinner,
.panels.is-portfolio.is-panel-3-open .panel.is-sectors .panel-close {
  right: 1rem;
}
@media print {
  .print-reset {
    font-size: 12.5px;
  }
  .print-reset .pt-header {
    padding-top: 0 !important;
  }
  .print-reset .header,
  .print-reset .footer,
  .print-reset #vanillatoasts-container {
    display: none;
  }
  .print-reset.is-safari {
    font-size: 12.5px;
  }
  .print-reset.is-edge,
  .print-reset.is-ie {
    font-size: 13.3px;
  }
  .print-reset.is-firefox {
    font-size: 14.1px;
  }
  .print-reset.is-chrome {
    font-size: 16px;
  }
  .print-reset .print-page {
    background-color: white;
    overflow: hidden;
    height: 94rem;
  }
  .print-page-container {
    position: absolute;
    width: 100%;
  }
  .print-page {
    page-break-after: always;
    page-break-inside: avoid;
  }
}
.print-simulation-hint {
  display: none;
}
.print-simulation {
  font-size: 12.5px;
}
.print-simulation *,
.print-simulation *::before,
.print-simulation *::after {
  text-shadow: none !important;
  box-shadow: none !important;
}
.print-simulation a:not(.btn) {
  text-decoration: underline;
}
.print-simulation abbr[title]::after {
  content: " (" attr(title) ")";
}
.print-simulation pre {
  white-space: pre-wrap !important;
}
.print-simulation pre,
.print-simulation blockquote {
  border: 1px solid #636974;
  page-break-inside: avoid;
}
.print-simulation thead {
  display: table-header-group;
}
.print-simulation tr,
.print-simulation img {
  page-break-inside: avoid;
}
.print-simulation p,
.print-simulation h2,
.print-simulation h3 {
  orphans: 3;
  widows: 3;
}
.print-simulation h2,
.print-simulation h3 {
  page-break-after: avoid;
}
@page {
  size: a3;
}
.print-simulation body {
  min-width: 992px !important;
}
.print-simulation .container {
  min-width: 992px !important;
}
.print-simulation .scroll-to-top {
  display: none;
}
.print-simulation .pt-header {
  padding-top: 0 !important;
}
.print-simulation .header,
.print-simulation .footer,
.print-simulation #vanillatoasts-container {
  display: none;
}
.print-simulation.is-safari {
  font-size: 12.5px;
}
.print-simulation.is-edge,
.print-simulation.is-ie {
  font-size: 13.3px;
}
.print-simulation.is-firefox {
  font-size: 14.1px;
}
.print-simulation.is-chrome {
  font-size: 16px;
}
.print-simulation .print-page {
  background-color: white;
  overflow: hidden;
  height: 94rem;
}
@media screen {
  .print-simulation {
    background-image: linear-gradient(to bottom, #003463 0%, #007878 100%);
    background-repeat: repeat-x;
    background-attachment: fixed;
  }
  .print-simulation,
  .print-simulation body {
    height: auto;
  }
  .print-simulation body {
    background-color: transparent;
  }
  .print-simulation .content-inner {
    background-color: transparent;
  }
  .print-simulation .print-page-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 6.5rem;
    margin: 0 auto;
    width: auto;
  }
  .print-simulation .print-page {
    margin: 2rem;
    width: 65rem;
    box-shadow: 0 8px 32px rgba(0, 52, 99, 0.4) !important;
  }
  .print-simulation .print-simulation-hint {
    position: fixed;
    color: #C7CBCF;
    background-color: rgba(40, 41, 47, 0.96);
    top: 2rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 1rem;
    width: 32rem;
    display: flex;
    border-radius: 0.5rem;
    align-items: center;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4) !important;
    z-index: 1030;
  }
}
@media (max-width: 767px) {
  .ratings-center .card-deck {
    flex-flow: column wrap;
  }
}
.ratings-center .card-deck .card-video {
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .ratings-center .card-deck .card-video {
    max-width: calc(50% - 15px * 2);
    min-width: calc(50% - 15px * 2);
  }
}
.ratings-center .card-deck .card-resource {
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .ratings-center .card-deck .card-resource {
    max-width: calc(33.3% - 15px * 2);
    min-width: calc(33.3% - 15px * 2);
  }
}
@media (min-width: 992px) {
  .ratings-center .card-deck-videos {
    margin-right: calc(-2.5rem - 15px);
    margin-left: calc(-2.5rem - 15px);
  }
}
.ratings-center .card {
  border-width: 0;
}
.ratings-center .card.card-resource {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.ratings-center .card.card-resource .card-body {
  padding: 2rem 2.5rem 0;
}
.ratings-center .card.card-resource .card-footer {
  background-color: transparent;
  border-width: 0;
  padding: 0 2.5rem 2rem;
}
.ratings-center .card.card-resource .btn {
  display: block;
  white-space: normal;
}
.ratings-center .card.card-video {
  background-color: transparent;
}
.ratings-center .card.card-video .card-body-video {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.ratings-center .card.card-video .card-body {
  margin-left: 2.5rem / 2;
  margin-right: 2.5rem / 2;
}
.ratings-center .card h3 {
  margin-bottom: 0.25rem;
}
.ratings-center .card .label-time {
  display: block;
  font-style: italic;
  margin-bottom: 0.5rem;
}
.setting-card-grid {
  margin: -1.65rem -1.65rem;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 991px) {
  .setting-card-grid {
    margin: -0.5rem;
  }
}
.setting-card {
  display: inline-block;
  margin: 1.65rem 1.65rem;
  height: 272px;
  width: 362px;
  background-color: #fff;
}
@media (max-width: 991px) {
  .setting-card {
    height: 302px;
    width: 344px;
    margin: 0.5rem;
  }
}
.setting-card-content {
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
}
.setting-card-header,
.setting-card-footer {
  min-height: 3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.setting-card-name {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  color: #003463;
  font-size: 1.8125rem;
  line-height: 2.625rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 21px;
  line-height: 1.2;
  letter-spacing: normal;
}
.setting-card-name b,
.setting-card-name strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.setting-card-body {
  min-height: 115px;
}
.setting-card-body p {
  margin-bottom: 0;
}
.setting-card-footer {
  display: flex;
  justify-content: space-between;
}
.setting-card-footer .btn {
  padding: 0.625rem 1.525rem;
  line-height: 1.75;
}
.settings-nav-title {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  color: #003463;
  font-size: 1.8125rem;
  line-height: 2.625rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 20px;
  letter-spacing: normal;
  padding-left: 2rem;
}
.settings-nav-title b,
.settings-nav-title strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.settings-nav-title a {
  color: #003463;
}
.settings-nav-title a:active,
.settings-nav-title a:focus,
.settings-nav-title a:hover {
  text-decoration: none;
}
.setting-nav {
  background-color: #fff;
  padding: 50px 0;
  width: 230px;
  flex: 0 0 auto;
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
}
.setting-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.setting-nav li {
  padding: 0;
  margin: 3px 0;
}
.setting-nav li a {
  line-height: 40px;
  display: block;
  padding-left: 1.75rem;
  border-left: 0.25rem solid transparent;
  color: #979EA8;
}
.setting-nav li a:active,
.setting-nav li a:focus,
.setting-nav li a:hover {
  text-decoration: none;
  color: #003463;
}
.setting-nav li a.active {
  background-color: rgba(245, 246, 247, 0.7);
  color: #003463;
  border-left-color: #003463;
}
.pt-header-with-secondary .setting-nav,
.pt-header-with-secondary .settings-body,
.pt-header-with-secondary .dashboard-container:first-child {
  margin-top: -50px;
}
@media (max-width: 950px) {
  .setting-nav {
    width: 200px;
  }
  .setting-nav ul a {
    font-size: 14px;
  }
}
.settings-body {
  padding: 3rem 3rem;
  flex: 0 1 auto;
  width: 1350px;
}
.settings-body h1 {
  margin-bottom: 0;
}
.settings-body .ISP-settings {
  height: auto;
}
@media (max-width: 886px) {
  .settings-body .ISP-settings select {
    margin: 10px 0 10px 25px !important;
  }
}
.settings-form {
  max-width: 760px;
}
.settings-form-lg {
  max-width: 1254px;
}
.company-roadmap-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
}
.company-roadmap-wrapper .small-spacing {
  margin-top: 20px;
}
.company-roadmap-wrapper .medium-spacing {
  margin-top: 35px;
}
.company-roadmap-wrapper .large-spacing {
  margin-top: 56px;
}
.company-roadmap-wrapper h1 {
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 27px;
  line-height: 35px;
  color: #939393;
  margin: 0;
}
.company-roadmap-wrapper h2 {
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 17px;
  line-height: 22px;
  color: #939393;
  text-transform: none;
  margin: 0;
}
.company-roadmap-wrapper .company-roadmap-container {
  margin-left: 9.6%;
  margin-right: 9.15%;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-title {
  margin-top: 41px;
  margin-bottom: 34px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .pledge-table-header {
  display: flex;
  flex-direction: row;
  height: 44px;
  width: 100%;
  background: #DDECF2;
  border-radius: 4px 4px 0px 0px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .pledge-table-header p {
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #6D6D6D;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .pledge-table-header .header-1 {
  display: flex;
  flex-grow: 1;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .pledge-table-header .header-2 {
  display: flex;
  width: 134px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .pledge-table-header .header-3 {
  display: flex;
  width: 124px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .pledge-table-header .header-4 {
  display: flex;
  width: 46px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .pledege-table {
  background: #FFFFFF;
  border-radius: 0px 0px 4px 4px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .pledege-table tr {
  height: 57px;
  width: 100%;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .pledege-table tr .cell-1 {
  display: flex;
  flex-grow: 1;
  height: 57px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .pledege-table tr .cell-1 p {
  height: 44px;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #6D6D6D;
  margin-left: 23px;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .pledege-table tr .cell-2 {
  width: 134px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .pledege-table tr .cell-3 {
  width: 124px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .pledege-table tr .cell-4 {
  width: 46px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table-header {
  display: flex;
  flex-direction: row;
  height: 87px;
  width: 100%;
  background: #DDECF2;
  border-radius: 4px 4px 0px 0px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table-header .header-start {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 87px;
  margin-left: 23px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table-header .header-start .engagement-letter-text {
  height: 21px;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #6D6D6D;
  margin-top: 25px;
  margin-bottom: 0px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table-header .header-start .engagement-date-text {
  height: 34px;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #6D6D6D;
  margin: 0;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table-header .header-section-container {
  display: flex;
  flex-direction: column;
  margin-top: 43px;
  margin-right: 44px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table-header .header-section-container .header-section {
  display: flex;
  height: 34px;
  cursor: pointer;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table-header .header-section-container .header-section p {
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: rgba(99, 99, 99, 0.4);
  margin-top: auto;
  margin-bottom: auto;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table-header .header-section-container .header-section .selected {
  color: #6D6D6D;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table-header .header-section-container .header-section .unselected {
  color: rgba(99, 99, 99, 0.4);
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table-header .header-section-container .selected-arrow {
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid white;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table {
  background: #FFFFFF;
  border-radius: 0px 0px 4px 4px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table th {
  height: 74px;
  vertical-align: bottom;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table th .first-section {
  margin-left: 23px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table th .other-section {
  margin-left: auto;
  margin-right: auto;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table th p {
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #6D6D6D;
  margin-top: auto;
  margin-bottom: 10px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table .table-row {
  height: 57px;
  background: rgba(187, 229, 219, 0.1);
  border: 0.5px solid #E5E5E5;
  box-sizing: border-box;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table .cell-1 {
  flex-grow: 1;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table .cell-1 p {
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #646363;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 23px;
  margin-right: 10px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table .cell-2 {
  width: 134px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table .cell-3 {
  width: 134px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table .cell-4 {
  width: 60px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table .cell-5 {
  width: 134px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table .cell-6 {
  width: 150px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table .cell-7 {
  width: 142px;
}
.company-roadmap-wrapper .company-roadmap-container .company-roadmap-table .engagement-table .cell-8 {
  width: 164px;
}
.company-roadmap-wrapper .company-roadmap-video-wrapper {
  width: 100%;
  height: 447px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
}
.company-roadmap-wrapper .company-roadmap-video-wrapper .video-header {
  margin-left: 12%;
  margin-right: 12.7%;
  margin-top: 35px;
  margin-bottom: 26px;
}
.company-roadmap-wrapper .company-roadmap-video-wrapper .video-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}
.company-roadmap-wrapper .company-roadmap-video-wrapper .video-container .arrow-container {
  width: 12%;
  display: flex;
}
.company-roadmap-wrapper .company-roadmap-video-wrapper .video-container .arrow-container .arrow-button {
  margin: auto;
  width: 18px;
  height: 29px;
  background: transparent;
  border: 0px;
}
.company-roadmap-wrapper .company-roadmap-video-wrapper .video-container .videos-wrapper {
  display: flex;
  justify-content: space-between;
  width: 76%;
}
.company-roadmap-wrapper .company-roadmap-video-wrapper .video-container .video-card {
  position: relative;
  width: 28%;
}
.company-roadmap-wrapper .company-roadmap-video-wrapper .video-container .video-card .video-player {
  width: 100%;
  height: 182px;
}
.company-roadmap-wrapper .company-roadmap-video-wrapper .video-container .video-card .video-title {
  display: flex;
  flex-direction: row;
  margin-top: 17px;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #636363;
}
.company-roadmap-wrapper .company-roadmap-video-wrapper .video-container .video-card .video-title .video-runtime {
  margin-left: 5px;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #636363;
}
.company-roadmap-wrapper .company-roadmap-video-wrapper .video-container .video-card .video-summary {
  margin-top: 13px;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #636363;
  max-height: 72px;
  overflow: hidden;
}
.company-roadmap-wrapper .company-roadmap-whitepapers-container {
  margin-left: 12%;
  margin-right: 12.7%;
}
.company-roadmap-wrapper .company-roadmap-whitepapers-container .whitepaper-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 27px;
}
.company-roadmap-wrapper .company-roadmap-whitepapers-container .whitepaper-card-container .whitepaper-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 28%;
  height: auto;
  min-height: 331px;
  background: #FFFFFF;
  border: 1px solid #E2E7EA;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 28px;
  margin-bottom: 20px;
}
.company-roadmap-wrapper .company-roadmap-whitepapers-container .whitepaper-card-container .whitepaper-card .title {
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  color: #636363;
  text-overflow: ellipsis;
}
.company-roadmap-wrapper .company-roadmap-whitepapers-container .whitepaper-card-container .whitepaper-card .read-time {
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: #636363;
  text-overflow: ellipsis;
  margin-top: 10px;
  margin-bottom: 10px;
}
.company-roadmap-wrapper .company-roadmap-whitepapers-container .whitepaper-card-container .whitepaper-card .summary {
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #636363;
  text-overflow: ellipsis;
}
.company-roadmap-wrapper .company-roadmap-whitepapers-container .whitepaper-card-container .whitepaper-card .download-whitepaper {
  display: flex;
  border: 1px solid #34B991;
  background-color: white;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  margin-top: auto;
  cursor: pointer;
}
.company-roadmap-wrapper .company-roadmap-whitepapers-container .whitepaper-card-container .whitepaper-card .download-whitepaper p {
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #34B991;
  margin: auto;
}
.company-roadmap-wrapper .confirmation-modal-background {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.company-roadmap-wrapper .confirmation-modal-background .confirmation-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 410px;
  left: calc(50% - 410px/2);
  top: 238px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.company-roadmap-wrapper .confirmation-modal-background .confirmation-modal .confirmation-modal-icon {
  position: relative;
  width: 46px;
  height: 39px;
  margin-top: 45px;
  margin-left: auto;
  margin-right: auto;
  color: #003462;
}
.company-roadmap-wrapper .confirmation-modal-background .confirmation-modal .confirmation-modal-text {
  position: relative;
  width: 341px;
  height: 46px;
  margin-top: 19px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #636363;
}
.company-roadmap-wrapper .confirmation-modal-background .confirmation-modal .confirmation-modal-button-container {
  position: relative;
  flex-direction: row;
  margin-top: 39px;
  margin-bottom: 24px;
  margin-left: 37px;
  margin-right: 37px;
}
.company-roadmap-wrapper .confirmation-modal-background .confirmation-modal .confirmation-modal-button-container .confirmation-modal-button-cancel {
  width: 161px;
  height: 40px;
  margin-right: 14px;
  border: 1px solid #003462;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #003462;
}
.company-roadmap-wrapper .confirmation-modal-background .confirmation-modal .confirmation-modal-button-container .confirmation-modal-button-continue {
  width: 161px;
  height: 40px;
  background: #003462;
  border-radius: 4px;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}
input {
  height: 34px;
  width: 112px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #6D6D6D;
  margin-left: auto;
  margin-right: auto;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.readOnly-input {
  border: 0px;
  background-color: transparent;
  text-align: center;
}
.download-button {
  width: 34px;
  height: 34px;
  background: #DDECF2;
  border-radius: 17px;
  border-width: 0px;
  position: relative;
  display: flex;
  cursor: pointer;
}
.download-button .download-icon {
  width: 12px;
  height: 15px;
  margin: auto;
}
.company-rating-container {
  margin-top: 3.75rem;
  z-index: 1;
}
.company-rating-backdrop {
  z-index: 0;
  left: 0;
  top: 0;
  height: 15.875rem;
  background-color: #c1d8e8;
  background: linear-gradient(0deg, #c1d8e8 0%, #e9f2f8 100%);
}
.company-rating-card {
  background: #fdfdfd;
  border: 0.5px solid #e5e5e5;
  border-radius: 0.25rem;
  box-sizing: border-box;
}
.company-rating-card .rating-header {
  background: #ddecf2;
  border-radius: 0.25rem 0.25rem 0 0;
  border: 0 0 0.5px 0 solid #e5e5e5;
  padding: 2.5rem 5.25rem;
  color: #636363;
  font-weight: 450;
  font-size: 1.125rem;
  line-height: 1.438rem;
}
.company-rating-title-card {
  padding: 4.688rem 3.125rem 2.813rem 3.125rem;
  border-right: 0.5px solid #e5e5e5;
  flex: 1 1;
}
.company-rating-title-card .page-title {
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 2.25rem;
  color: #003462;
}
.company-rating-title-card .page-description {
  margin-top: 1.25rem;
  font-size: 0.938rem;
  line-height: 1.188rem;
}
.company-rating-title-report {
  padding: 3.813rem 2.688rem 1.563rem 2.688rem;
}
.company-rating-title-report .company-title {
  font-weight: 600;
  font-size: 2.625rem;
  line-height: 3.375rem;
  color: #003462;
  padding-top: 0.476rem;
}
.company-rating-title-report .sub-title {
  font-weight: 450;
  font-size: 1.125rem;
  line-height: 1.438rem;
  color: #939393;
}
.company-rating-title-report .company-info {
  margin-bottom: 1.625rem;
}
.company-rating-title-report .company-rating img {
  width: 8.125rem;
  height: 8.125rem;
}
.company-rating-title-report .company-rating-award-container {
  grid-gap: 2.125rem;
  gap: 2.125rem;
  padding: 2.5rem 0;
  border-top: 1px solid #d9d9d9;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .company-rating-title-report .company-rating-award-container > *:not(:last-child) {
      margin: 0 2.125rem 2.125rem 0;
    }
  }
}
.company-rating-title-report .company-rating-row .btn-outline {
  border: 1px solid #003462 !important;
  border-radius: 0.25rem;
  color: #003462 !important;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
}
.company-rating-title-report .company-rating-row .btn-outline:hover {
  background-color: #003462 !important;
  color: #ffffff !important;
}
.company-rating-title-report .company-rating-award-badge img {
  width: 5rem;
  height: 5rem;
}
.company-dashboard-backdrop {
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 15.875rem;
  background: #c1d8e8;
  background: linear-gradient(0deg, #c1d8e8 0%, #e9f2f8 100%);
}
.company-dashboard-container {
  z-index: 1;
}
.company-dashboard-container .company-dashboard-header-wrapper {
  margin-top: 2.375rem;
  min-height: 11.25rem;
  padding-left: 0.5rem;
  border-radius: 4px;
  background: linear-gradient(269.2deg, #003462 20.92%, #034682 63.09%);
}
.company-dashboard-container .company-dashboard-header {
  min-height: 11.25rem;
  border-radius: 0 4px 4px 0;
  background-color: #ffffff;
  border: 0.5px solid #e5e5e5;
  box-sizing: border-box;
}
.company-dashboard-container .company-dashboard-header-title {
  padding-left: 1.875rem;
}
.company-dashboard-container .company-dashboard-header-company-title {
  margin-top: 3.313rem;
}
.company-dashboard-container .company-dashboard-header-company-title span {
  font-weight: 600;
  font-size: 2.625rem;
  line-height: 3.375rem;
  color: #003462;
}
.company-dashboard-container .company-dashboard-header-company-role span {
  font-size: 1.125rem;
  line-height: 1.438rem;
  font-weight: normal;
  color: #003462;
}
.company-dashboard-container .company-dashboard-header-stats-wrapper {
  margin-top: 1.875rem;
}
.company-dashboard-container .company-dashboard-header-stats .company-dashboard-header-score .score {
  font-style: normal;
  font-weight: bold;
  font-size: 3.438rem;
  line-height: 4.438rem;
  color: #003462;
}
.company-dashboard-container .company-dashboard-header-stats .company-dashboard-header-score .ordinal {
  padding-left: 0.125rem;
  color: #003462;
  font-size: 1.75rem;
}
.company-dashboard-container .company-dashboard-header-stats .company-dashboard-header-score .label {
  margin-top: 0.25rem;
}
.company-dashboard-container .company-dashboard-header-stats .company-dashboard-header-score .label span {
  font-style: normal;
  font-weight: 450;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #636363;
}
.company-dashboard-container .company-dashboard-emblem-wrapper {
  margin-right: 0.5rem;
  width: 10rem;
}
.company-dashboard-container .company-dashboard-emblem-wrapper .company-dashboard-emblem {
  width: 4.188rem;
  height: 4.188rem;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
}
.company-dashboard-container .company-dashboard-emblem-wrapper .company-dashboard-emblem span {
  font-style: normal;
  font-weight: 600;
  font-size: 2.688rem;
  line-height: 3.438rem;
  text-transform: uppercase;
  color: #c49f6d;
}
.company-dashboard-container .company-dashboard-emblem-wrapper .company-dashboard-emblem-ring {
  width: 5.5rem;
  height: 5.5rem;
  background: #bf9763;
  border-radius: 50%;
}
.company-dashboard-container .company-dashboard-emblem-wrapper .emblem-label {
  margin-top: 0.688rem;
}
.company-dashboard-container .company-dashboard-emblem-wrapper .emblem-label span {
  font-style: normal;
  font-weight: 450;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #636363;
}
.company-dashboard-container .company-dashboard-header-stats-title .line-spacer {
  width: 2.5rem;
  height: 0;
  border-bottom: 1px solid #e5e5e5;
}
.company-dashboard-container .company-dashboard-header-stats-title .text {
  padding: 0 0.438rem;
}
.company-dashboard-container .company-dashboard-header-stats-title .text span {
  font-style: normal;
  font-weight: 450;
  font-size: 0.813rem;
  line-height: 1.063remm;
  color: #636363;
}
.company-dashboard-container .company-dashboard-awards-container {
  margin-top: 1.438rem;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 4px;
}
.company-dashboard-container .company-dashboard-awards-label {
  width: 2.375rem;
  border-right: 1px solid #e5e5e5;
}
.company-dashboard-container .company-dashboard-awards-label span {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  font-style: normal;
  font-weight: 450;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #636363;
}
.company-dashboard-container .company-dashboard-awards-desc {
  background: #ddecf2;
  border: 0.5px solid #e5e5e5;
  border-radius: 0px 4px 4px 0px;
  min-width: 21.25rem;
}
.company-dashboard-container .company-dashboard-awards-desc .desc-text {
  margin: 0 auto;
  width: 60%;
}
.company-dashboard-container .company-dashboard-awards-desc .desc-text span {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.313rem;
  color: #636363;
}
.company-dashboard-container .company-dashboard-awards-desc .desc-button {
  margin-top: 1.25rem;
}
.company-dashboard-container .company-dashboard-awards {
  padding: 0 3.5rem 2.25rem 3.5rem;
}
.company-dashboard-container .company-dashboard-award-badge {
  margin: 2.25rem 1.625rem auto 0;
  max-width: 6.875rem;
  min-width: 6.875rem;
}
.company-dashboard-container .company-dashboard-award-badge img {
  width: 100%;
}
.company-dashboard-container .company-dashboard-button {
  border-color: #34b991 !important;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #34b991 !important;
  max-width: 10.5rem;
}
.company-dashboard-container .company-dashboard-button:hover {
  background-color: #34b991 !important;
  color: #ffffff !important;
}
.company-dashboard-container .company-cards {
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.company-dashboard-container .header-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22.25rem, 1fr));
  /* see notes below */
  grid-gap: 1em;
}
.alert {
  position: relative;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 0;
  color: #33373c;
  background: #e0e1e3;
  border-color: #d3d5d8;
}
.alert hr {
  border-top-color: #c6c8cc;
}
.alert .alert-link {
  color: inherit;
  text-decoration: underline;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: inherit;
}
.alert-dismissible {
  padding-right: 4.25rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem 1.5rem;
  color: inherit;
}
.alert.alert-success {
  color: #00664e;
  background: #ccf3ea;
  border-color: #b8efe2;
}
.alert.alert-success hr {
  border-top-color: #a3ead9;
}
.alert.alert-success .alert-link {
  color: inherit;
  text-decoration: underline;
}
.alert.alert-warning {
  color: #855626;
  background: #ffeddb;
  border-color: #ffe6cc;
}
.alert.alert-warning hr {
  border-top-color: #ffd9b3;
}
.alert.alert-warning .alert-link {
  color: inherit;
  text-decoration: underline;
}
.alert.alert-danger {
  color: #852e35;
  background: #ffdee0;
  border-color: #ffd0d4;
}
.alert.alert-danger hr {
  border-top-color: #ffb7bc;
}
.alert.alert-danger .alert-link {
  color: inherit;
  text-decoration: underline;
}
.alert.alert-white {
  color: #858585;
  background: #ffffff;
  border-color: #ffffff;
}
.alert.alert-white hr {
  border-top-color: #f2f2f2;
}
.alert.alert-white .alert-link {
  color: inherit;
  text-decoration: underline;
}
.alert.alert-blue {
  color: #013f54;
  background: #cce4ec;
  border-color: #b8dae5;
}
.alert.alert-blue hr {
  border-top-color: #a6d0de;
}
.alert.alert-blue .alert-link {
  color: inherit;
  text-decoration: underline;
}
.background-divider {
  background-color: white;
  background-image: linear-gradient(white, #F5F6F7);
}
@media print {
  .background-divider {
    background: transparent none;
  }
}
.background-divider.is-lighter-gray {
  background-color: rgba(199, 203, 207, 0);
  background-image: linear-gradient(to bottom, rgba(199, 203, 207, 0) 0%, #C7CBCF 100%);
  background-repeat: repeat-x;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
  color: #ffffff;
  background-color: #40424B;
}
.badge:empty {
  display: none;
}
.badge[href]:hover,
.badge[href]:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #292a2f;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge.badge-primary {
  color: #ffffff;
  background-color: #003463;
}
.badge.badge-primary[href]:hover,
.badge.badge-primary[href]:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #001930;
}
.badge.badge-secondary {
  color: #ffffff;
  background-color: #40424B;
}
.badge.badge-secondary[href]:hover,
.badge.badge-secondary[href]:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #292a2f;
}
.badge.badge-success {
  color: #ffffff;
  background-color: #00C596;
}
.badge.badge-success[href]:hover,
.badge.badge-success[href]:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #00926f;
}
.badge.badge-info {
  color: #ffffff;
  background-color: #027AA2;
}
.badge.badge-info[href]:hover,
.badge.badge-info[href]:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #015470;
}
.badge.badge-warning {
  color: #000000;
  background-color: #ffa64a;
}
.badge.badge-warning[href]:hover,
.badge.badge-warning[href]:focus {
  color: #000000;
  text-decoration: none;
  background-color: #ff8c17;
}
.badge.badge-danger {
  color: #ffffff;
  background-color: #FF5865;
}
.badge.badge-danger[href]:hover,
.badge.badge-danger[href]:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #ff2536;
}
.badge.badge-light {
  color: #000000;
  background-color: #F5F6F7;
}
.badge.badge-light[href]:hover,
.badge.badge-light[href]:focus {
  color: #000000;
  text-decoration: none;
  background-color: #d9dde0;
}
.badge.badge-dark {
  color: #ffffff;
  background-color: #28292F;
}
.badge.badge-dark[href]:hover,
.badge.badge-dark[href]:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #111113;
}
@media print {
  .badge {
    border: 1px solid #000;
  }
}
.bar-chart {
  height: 25rem;
  position: relative;
}
.bar-chart.is-sm {
  height: 11.875rem;
}
.bar-chart-line-set {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column-reverse;
}
.bar-chart-line {
  flex: 1 1 auto;
  position: relative;
}
.bar-chart-line:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  left: 2rem;
  border-top: 1px solid rgba(151, 158, 168, 0.3);
}
.bar-chart-line:after {
  content: attr(data-value);
  position: absolute;
  display: block;
  top: -0.75rem;
  right: 0;
  left: 0;
  font-size: 0.6875rem;
}
.bar-chart-data-set {
  position: absolute;
  top: 0;
  right: 5%;
  bottom: 0;
  left: calc(2rem + 5%);
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  text-align: center;
  transition: -webkit-transform 0.4s cubic-bezier(0.35, 0, 0.32, 1);
  transition: transform 0.4s cubic-bezier(0.35, 0, 0.32, 1);
  transition: transform 0.4s cubic-bezier(0.35, 0, 0.32, 1), -webkit-transform 0.4s cubic-bezier(0.35, 0, 0.32, 1);
  pointer-events: none;
}
.bar-chart-data-set.is-primary {
  z-index: 1;
}
.bar-chart-data {
  flex: 1 1 auto;
  position: relative;
  transition: height 0.4s 0.4s cubic-bezier(0.35, 0, 0.32, 1);
}
.bar-chart-data-set.is-hidden .bar-chart-data {
  transition-delay: 0s;
  height: 0 !important;
}
.bar-chart-data-set.is-hidden .bar-chart-data .bar-chart-data-label {
  opacity: 0;
  height: 0 !important;
  pointer-events: none;
}
.bar-chart-data-fill,
.bar-chart-data-label {
  position: absolute;
  right: 30%;
  left: 30%;
  opacity: 0.9;
  transition: opacity 0.2s, left 0.4s cubic-bezier(0.35, 0, 0.32, 1), right 0.4s cubic-bezier(0.35, 0, 0.32, 1);
}
.bar-chart.is-comparison.has-overlap .bar-chart-data-fill,
.bar-chart.is-comparison.has-overlap .bar-chart-data-label {
  right: 40%;
  left: 40%;
}
.bar-chart.is-comparison .bar-chart-data-fill,
.bar-chart.is-comparison .bar-chart-data-label {
  right: 42%;
  left: 42%;
}
.bar-chart.has-selection .bar-chart-data:not(.is-selected) .bar-chart-data-fill,
.bar-chart.has-selection .bar-chart-data:not(.is-selected) .bar-chart-data-label {
  opacity: 0.3;
}
.bar-chart.has-selection .bar-chart-data:not(.is-selected):hover .bar-chart-data-fill,
.bar-chart.has-selection .bar-chart-data:not(.is-selected):hover .bar-chart-data-label {
  opacity: 1;
}
.bar-chart.has-selection .bar-chart-data.is-selected .bar-chart-data-fill,
.bar-chart.has-selection .bar-chart-data.is-selected .bar-chart-data-label {
  right: 10%;
  left: 10%;
}
.bar-chart-data-fill {
  top: 0;
  bottom: 0;
  overflow: hidden;
  display: block;
}
.bar-chart-data-fill img {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 25rem;
}
.bar-chart.is-sm .bar-chart-data-fill img {
  height: 11.875rem;
}
.bar-chart-data-label {
  display: block;
  bottom: 0;
  top: -0.5rem;
  pointer-events: all;
  transition: opacity 0.4s cubic-bezier(0.35, 0, 0.32, 1);
  color: #003463;
  font-weight: bold;
  line-height: 2.5rem;
  text-decoration: none !important;
}
.bar-chart-data-label:hover {
  text-decoration: none;
}
.bar-chart-data-label:hover,
.bar-chart-data-label:hover + .bar-chart-data-fill {
  opacity: 0.9;
}
.bar-chart-data.has-label .bar-chart-data-label {
  top: -2.5rem;
}
.bar-chart.has-bar-labels .bar-chart-data-label {
  font-size: 0.625rem;
}
.bar-chart.is-sm .bar-chart-data-label {
  font-size: 0.4375rem;
}
.bar-chart-comparison-options {
  display: flex;
  justify-content: flex-end;
  transition: opacity 0.3s cubic-bezier(0.35, 0, 0.32, 1);
  position: relative;
  top: -3.25rem;
}
.bar-chart-comparison-options.has-selection {
  opacity: 0;
  pointer-events: none;
}
.bar-chart-comparison-options .custom-control-label {
  font-weight: 300;
}
.bar-chart-comparison-options .custom-control-label::before {
  opacity: 0.9;
}
.bar-chart-comparison-options .custom-control-input:disabled ~ .custom-control-label::after {
  display: none;
}
.bar-chart-legend {
  font-size: 1rem;
  text-align: center;
}
.bar-chart-legend-labels,
.bar-chart-legend-datasets {
  display: flex;
  padding: 0.5rem 0;
}
.bar-chart-legend-labels {
  font-weight: 500;
  padding-right: 5%;
  padding-left: 5%;
  margin-left: 2rem;
  border-top: 1px solid rgba(151, 158, 168, 0.3);
  justify-content: space-evenly;
}
.bar-chart-legend-label {
  flex: 1 1 auto;
}
.bar-chart-legend-datasets {
  justify-content: center;
  color: #636974;
}
.bar-chart-legend-dataset {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin: 0 1rem;
  overflow: hidden;
  white-space: nowrap;
  transition: opacity 0.4s 0.4s cubic-bezier(0.35, 0, 0.32, 1), max-width 0.4s cubic-bezier(0.35, 0, 0.32, 1), margin 0.4s cubic-bezier(0.35, 0, 0.32, 1);
}
.bar-chart-legend-dataset img {
  display: block;
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 50%;
  flex: 0 0 auto;
}
.bar-chart-legend-dataset.is-hidden {
  max-width: 0;
  margin: 0;
  opacity: 0;
  transition: max-width 0.4s 0.4s cubic-bezier(0.35, 0, 0.32, 1), margin 0.4s 0.4s cubic-bezier(0.35, 0, 0.32, 1), opacity 0.4s cubic-bezier(0.35, 0, 0.32, 1);
}
.bootstrap-table-controls {
  margin-bottom: 36px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1020px) {
  .bootstrap-table-controls {
    display: block;
  }
}
.search-only .bootstrap-table-search {
  width: 100%;
}
.search-only .bootstrap-table-search .form-control-and-icon {
  width: 100%;
}
.bootstrap-table-search {
  display: flex;
  width: 75%;
}
.bootstrap-table-search .form-control-and-icon {
  width: 75%;
  margin-right: 8px;
}
@media (max-width: 1020px) {
  .bootstrap-table-search {
    width: 100%;
    margin-bottom: 20px;
  }
  .bootstrap-table-search .form-control-and-icon {
    width: 100%;
  }
}
.bootstrap-table-filter {
  width: 25%;
  display: flex;
  margin-bottom: 0.25rem;
  min-height: 6.75rem;
}
.bootstrap-table-filter > * {
  flex-grow: 1;
}
@media (max-width: 1020px) {
  .bootstrap-table-filter {
    width: 50%;
  }
}
.bootstrap-table-filter label {
  width: 90px;
  min-width: 90px;
}
.breakdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.breakdown-list > * + * {
  border-top: 1px solid #E8E9EB;
}
.breakdown-list-item {
  padding: 0;
  margin: 0;
  display: flex;
  font-size: 1rem;
  line-height: 1rem;
  height: 3rem;
  align-items: center;
}
.breakdown-list-item > * {
  flex: 1 1 50%;
}
.breakdown-list-item.is-header {
  font-weight: 500;
  border-bottom: 1px solid #C7CBCF;
}
.boolean-comparison-doughnut {
  height: 160px;
  width: 160px;
  margin: 0 auto;
}
.boolean-legend {
  display: flex;
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  flex-flow: row wrap;
  min-height: 3.75rem;
  align-items: flex-end;
  justify-content: center;
}
@media print {
  .boolean-legend {
    font-size: 13px;
  }
}
.boolean-legend-item {
  flex: 0 0 auto;
  width: 50%;
}
.boolean-legend-label {
  color: #979EA8;
}
.boolean-legend-value {
  font-weight: bold;
}
.btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.625rem 1.625rem;
  font-size: 1rem;
  line-height: 1.25rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #ffffff;
  background: #027AA2;
  border-color: #027AA2;
}
@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover,
.btn:focus {
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(2, 122, 162, 0.25);
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  background-image: none;
}
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 122, 162, 0.25);
}
.btn:hover {
  color: #ffffff;
  background: #027AA2;
  border-color: #027AA2;
}
.btn:focus,
.btn.focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 122, 162, 0.5);
}
.btn.disabled,
.btn:disabled {
  color: #979EA8;
  background-color: #C7CBCF;
  border-color: #C7CBCF;
}
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus,
.show > .btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 122, 162, 0.5);
}
.btn.btn-outline {
  color: #027AA2;
  background-color: transparent;
  background-image: none;
  border-color: #027AA2;
}
.btn.btn-outline:hover {
  color: #ffffff;
  background-color: #027AA2;
}
.btn.btn-outline:focus,
.btn.btn-outline.focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 122, 162, 0.5);
}
.btn.btn-outline.disabled,
.btn.btn-outline:disabled {
  color: #979EA8;
  background-color: transparent;
  border-color: #979EA8;
}
.btn.btn-outline:not(:disabled):not(.disabled):active:focus,
.btn.btn-outline:not(:disabled):not(.disabled).active:focus,
.show > .btn.btn-outline.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 122, 162, 0.5);
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn.btn-white,
.btn.btn-light {
  color: #027AA2;
  background: white;
  border-color: white;
}
.btn.btn-white:hover,
.btn.btn-light:hover {
  color: #027AA2;
  background: white;
  border-color: white;
}
.btn.btn-white:focus,
.btn.btn-light:focus,
.btn.btn-white.focus,
.btn.btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn.btn-white.disabled,
.btn.btn-light.disabled,
.btn.btn-white:disabled,
.btn.btn-light:disabled {
  color: #979EA8;
  background-color: #C7CBCF;
  border-color: #C7CBCF;
}
.btn.btn-white:not(:disabled):not(.disabled):active:focus,
.btn.btn-light:not(:disabled):not(.disabled):active:focus,
.btn.btn-white:not(:disabled):not(.disabled).active:focus,
.btn.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn.btn-white.dropdown-toggle:focus,
.show > .btn.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn.btn-white.btn-outline,
.btn.btn-light.btn-outline {
  color: white;
  background-color: transparent;
  background-image: none;
  border-color: white;
}
.btn.btn-white.btn-outline:hover,
.btn.btn-light.btn-outline:hover {
  color: #027AA2;
  background-color: white;
}
.btn.btn-white.btn-outline:focus,
.btn.btn-light.btn-outline:focus,
.btn.btn-white.btn-outline.focus,
.btn.btn-light.btn-outline.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn.btn-white.btn-outline.disabled,
.btn.btn-light.btn-outline.disabled,
.btn.btn-white.btn-outline:disabled,
.btn.btn-light.btn-outline:disabled {
  color: #979EA8;
  background-color: transparent;
  border-color: #979EA8;
}
.btn.btn-white.btn-outline:not(:disabled):not(.disabled):active:focus,
.btn.btn-light.btn-outline:not(:disabled):not(.disabled):active:focus,
.btn.btn-white.btn-outline:not(:disabled):not(.disabled).active:focus,
.btn.btn-light.btn-outline:not(:disabled):not(.disabled).active:focus,
.show > .btn.btn-white.btn-outline.dropdown-toggle:focus,
.show > .btn.btn-light.btn-outline.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn.btn-gray,
.btn.btn-secondary {
  color: white;
  background: #979EA8;
  border-color: #979EA8;
}
.btn.btn-gray:hover,
.btn.btn-secondary:hover {
  color: white;
  background: #979EA8;
  border-color: #979EA8;
}
.btn.btn-gray:focus,
.btn.btn-secondary:focus,
.btn.btn-gray.focus,
.btn.btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(151, 158, 168, 0.5);
}
.btn.btn-gray.disabled,
.btn.btn-secondary.disabled,
.btn.btn-gray:disabled,
.btn.btn-secondary:disabled {
  color: #979EA8;
  background-color: #C7CBCF;
  border-color: #C7CBCF;
}
.btn.btn-gray:not(:disabled):not(.disabled):active:focus,
.btn.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn.btn-gray:not(:disabled):not(.disabled).active:focus,
.btn.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn.btn-gray.dropdown-toggle:focus,
.show > .btn.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(151, 158, 168, 0.5);
}
.btn.btn-gray.btn-outline,
.btn.btn-secondary.btn-outline {
  color: #979EA8;
  background-color: transparent;
  background-image: none;
  border-color: #979EA8;
}
.btn.btn-gray.btn-outline:hover,
.btn.btn-secondary.btn-outline:hover {
  color: white;
  background-color: #979EA8;
}
.btn.btn-gray.btn-outline:focus,
.btn.btn-secondary.btn-outline:focus,
.btn.btn-gray.btn-outline.focus,
.btn.btn-secondary.btn-outline.focus {
  box-shadow: 0 0 0 0.2rem rgba(151, 158, 168, 0.5);
}
.btn.btn-gray.btn-outline.disabled,
.btn.btn-secondary.btn-outline.disabled,
.btn.btn-gray.btn-outline:disabled,
.btn.btn-secondary.btn-outline:disabled {
  color: #979EA8;
  background-color: transparent;
  border-color: #979EA8;
}
.btn.btn-gray.btn-outline:not(:disabled):not(.disabled):active:focus,
.btn.btn-secondary.btn-outline:not(:disabled):not(.disabled):active:focus,
.btn.btn-gray.btn-outline:not(:disabled):not(.disabled).active:focus,
.btn.btn-secondary.btn-outline:not(:disabled):not(.disabled).active:focus,
.show > .btn.btn-gray.btn-outline.dropdown-toggle:focus,
.show > .btn.btn-secondary.btn-outline.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(151, 158, 168, 0.5);
}
.btn.btn-red {
  color: #ffffff;
  background: #FF5865;
  border-color: #FF5865;
}
.btn.btn-red:hover {
  color: #ffffff;
  background: #FF5865;
  border-color: #FF5865;
}
.btn.btn-red:focus,
.btn.btn-red.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 101, 0.5);
}
.btn.btn-red.disabled,
.btn.btn-red:disabled {
  color: #979EA8;
  background-color: #C7CBCF;
  border-color: #C7CBCF;
}
.btn.btn-red:not(:disabled):not(.disabled):active:focus,
.btn.btn-red:not(:disabled):not(.disabled).active:focus,
.show > .btn.btn-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 101, 0.5);
}
.btn.btn-outline.btn-red {
  color: #FF5865;
  background-color: transparent;
  background-image: none;
  border-color: #FF5865;
}
.btn.btn-outline.btn-red:hover {
  color: #ffffff;
  background-color: #FF5865;
}
.btn.btn-outline.btn-red:focus,
.btn.btn-outline.btn-red.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 101, 0.5);
}
.btn.btn-outline.btn-red.disabled,
.btn.btn-outline.btn-red:disabled {
  color: #979EA8;
  background-color: transparent;
  border-color: #979EA8;
}
.btn.btn-outline.btn-red:not(:disabled):not(.disabled):active:focus,
.btn.btn-outline.btn-red:not(:disabled):not(.disabled).active:focus,
.show > .btn.btn-outline.btn-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 101, 0.5);
}
.btn.btn-link {
  font-weight: 400;
  color: #027AA2;
  background-color: transparent;
  border-color: transparent;
}
.btn.btn-link:hover {
  color: #014156;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn.btn-link:focus,
.btn.btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn.btn-link:disabled,
.btn.btn-link.disabled {
  color: #40424B;
  pointer-events: none;
}
.btn.btn-link.btn-primary {
  color: #003463;
}
.btn.btn-lg {
  padding: 0.5rem 2rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  font-family: 'futura-pt', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}
.btn.btn-lg b,
.btn.btn-lg strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.btn.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
}
.btn.block {
  display: block;
  width: 100%;
}
.btn.block + .btn-block {
  margin-top: 0.5rem;
}
.btn-throttled {
  -webkit-animation: disable-clicks 1.5s;
          animation: disable-clicks 1.5s;
}
@-webkit-keyframes disable-clicks {
  from {
    pointer-events: none;
  }
  to {
    pointer-events: auto;
  }
}
@keyframes disable-clicks {
  from {
    pointer-events: none;
  }
  to {
    pointer-events: auto;
  }
}
input[type="submit"].btn.block,
input[type="reset"].btn.block,
input[type="button"].btn.block {
  width: 100%;
}
.btn-progress-fill {
  position: absolute;
  background-color: #027AA2;
  opacity: 0.2;
  top: 0;
  bottom: 0;
  left: 0;
}
.btn-icon-before {
  margin: 0 0.75em 0 -0.25em;
}
.btn-icon-after {
  margin: 0 -0.25em 0 0.75em;
}
.btn.toggle-off :not(:hover) {
  color: gray;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card.is-white {
  border-color: #fff;
  padding: 30px;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0 - 1px) calc(0 - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0 - 1px) calc(0 - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.card-img {
  width: 100%;
  border-radius: calc(0 - 1px);
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0 - 1px);
  border-top-right-radius: calc(0 - 1px);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0 - 1px);
  border-bottom-left-radius: calc(0 - 1px);
}
.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.close {
  float: right;
  font-size: 1.25rem;
  line-height: 2rem;
  color: #979EA8;
}
.close:hover,
.close:focus {
  color: #003463;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}
.comparison-doughnut {
  height: 125px;
  width: 125px;
  margin: 0 auto;
}
.compliance-status-bar {
  position: -webkit-sticky;
  position: sticky;
  border-bottom: 1px solid transparent;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 3.25rem;
  top: 0;
  z-index: 1;
  transition: background-color 0.2s cubic-bezier(0.35, 0, 0.32, 1), border-color 0.2s cubic-bezier(0.35, 0, 0.32, 1);
}
.compliance-status-bar-container {
  display: flex;
}
.compliance-status-bar-content {
  display: flex;
  background: #fff;
  padding: 0 1rem;
  transition: padding 0.2s cubic-bezier(0.35, 0, 0.32, 1);
}
.compliance-status-bar.is-stuck {
  background: #fff;
  border-bottom: 1px solid #E8E9EB;
}
.compliance-status-bar.is-stuck .compliance-status-bar-content {
  padding: 0;
}
.container {
  margin: 0 auto;
  max-width: 720px;
  width: 100%;
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
.company-rating-panel-header {
  background-image: linear-gradient(90deg, #004D77 10%, #107C89 100%);
  color: white;
  padding: 30px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.company-rating-panel-header .col + .col {
  margin-left: 2rem;
}
.company-rating-panel-body {
  background-color: white;
  padding: 40px 30px;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  flex-flow: row wrap;
}
.company-rating-panel-body > * {
  flex: 1 0 50%;
  min-width: 250px;
}
.company-rating-heading {
  display: block;
  font-size: 1.275rem;
  margin-bottom: 1rem;
}
.company-rating-name {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  display: block;
  font-size: 2rem;
  line-height: 1.2em;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.company-rating-name b,
.company-rating-name strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.organization-toggle-container .organization-option label {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-weight: normal;
}
.organization-toggle-container .organization-option label input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.organization-toggle-container .organization-option label .checkmark {
  position: absolute;
  top: 0.45rem;
  left: 0;
  height: 0.7rem;
  width: 0.7rem;
  border: 1px solid #000;
  background-color: #fff;
}
.organization-toggle-container .organization-option label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.organization-toggle-container .organization-option label input:checked ~ .checkmark {
  background-color: #65BE9E;
}
.portfolio-rating-medal-heading {
  display: block;
  font-size: 1.275rem;
  margin-bottom: 1rem;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background: #979EA8;
  box-shadow: none;
}
.custom-control-input:focus ~ .custom-control-label::before {
  border-color: #27c7fc;
  box-shadow: 0 0 0 0.2rem rgba(2, 122, 162, 0.25);
}
.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #E8E9EB;
  box-shadow: 0 0 0 0.2rem rgba(2, 122, 162, 0.25);
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #40424B;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #E8E9EB;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  font-weight: 500;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
          user-select: none;
  background-color: white;
  box-shadow: none;
  border: 1px solid #979EA8;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 75% 75%;
}
.custom-control-label a {
  color: inherit;
  text-decoration: underline;
}
.custom-control-label a:hover,
.custom-control-label a:focus,
.custom-control-label a:active {
  color: #027AA2;
  text-decoration: none;
}
.custom-checkbox .custom-control-label {
  min-height: 1.5rem;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.125rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: #979EA8;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23fff' d='M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background: #027AA2;
  box-shadow: none;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 52, 99, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 52, 99, 0.5);
}
.custom-checkbox.is-blue .custom-control-input ~ .custom-control-label::before {
  border-color: #027AA2;
}
.custom-checkbox.is-blue .custom-control-input:checked ~ .custom-control-label::before {
  background: #027AA2;
}
.custom-checkbox.is-navy .custom-control-input ~ .custom-control-label::before {
  border-color: #003463;
}
.custom-checkbox.is-navy .custom-control-input:checked ~ .custom-control-label::before {
  border: 0 none;
  background-image: linear-gradient(to bottom, #00a88c 0%, #003463 100%);
  background-repeat: repeat-x;
}
.custom-checkbox.is-btn {
  padding: 0;
  text-align: center;
  font-size: 1rem;
  display: inline-block;
}
.custom-checkbox.is-btn .custom-control-label {
  color: #027AA2;
  font-weight: 400;
  padding: 0.625rem 1.625rem;
  cursor: pointer;
  display: block;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-checkbox.is-btn .custom-control-label:before {
  border: 1px solid #027AA2;
  background-color: transparent;
  width: auto;
  height: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-checkbox.is-btn .custom-control-label:after {
  display: none;
}
.custom-checkbox.is-btn .custom-control-label:hover {
  color: white;
}
.custom-checkbox.is-btn .custom-control-label:hover:before {
  background-color: #027AA2;
}
.custom-checkbox.is-btn .custom-control-input:checked ~ .custom-control-label {
  color: #FF5865;
}
.custom-checkbox.is-btn .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #FF5865;
  background-color: transparent;
}
.custom-checkbox.is-btn .custom-control-input:checked ~ .custom-control-label:after {
  display: none;
}
.custom-checkbox.is-btn .custom-control-input:checked ~ .custom-control-label:hover {
  color: white;
}
.custom-checkbox.is-btn .custom-control-input:checked ~ .custom-control-label:hover:before {
  background-color: #FF5865;
}
.custom-radio .custom-control-label {
  min-height: 1.5rem;
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: #979EA8;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 52, 99, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.5rem + 2px);
  padding: 0 2rem 0 1rem;
  line-height: 1.25rem;
  color: #333a46;
  vertical-align: middle;
  background: white url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%2328292F' d='M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z'/%3E%3C/svg%3E") no-repeat right 1rem center;
  background-size: 12px 12px;
  border: 1px solid #C7CBCF;
  border-radius: 0;
  -webkit-appearance: none;
          appearance: none;
}
.custom-select:focus {
  border-color: #27c7fc;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(39, 199, 252, 0.5);
}
.custom-select:focus::-ms-value {
  color: #333a46;
  background-color: white;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}
.custom-select:disabled {
  color: #40424B;
  background-color: #E8E9EB;
}
.custom-select::-ms-expand {
  opacity: 0;
}
.custom-select-sm {
  height: calc(2rem + 2px);
  font-size: 75%;
  background-size: 10px 10px;
  padding-left: 0.75rem;
  padding-right: 1.95rem;
  background-position: right 0.75rem center;
}
.custom-select-lg {
  height: calc(2.875rem + 2px);
  font-size: 125%;
  padding-right: 2.65rem;
}
.custom-select-subtle {
  background-color: transparent;
  border-color: #E8E9EB;
  border-radius: 0.25rem;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.5rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.5rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #27c7fc;
  box-shadow: 0 0 0 0.2rem rgba(2, 122, 162, 0.25);
}
.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #27c7fc;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.5rem + 2px);
  padding: 0.625rem 1rem;
  line-height: 1.25rem;
  color: #333a46;
  background-color: white;
  border: 1px solid #C7CBCF;
  border-radius: 0;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.5rem;
  padding: 0.625rem 1rem;
  line-height: 1.25rem;
  color: #333a46;
  content: "Browse";
  background: #E8E9EB;
  border-left: 1px solid #C7CBCF;
  border-radius: 0 0 0 0;
}
.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background: #027AA2;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
          appearance: none;
}
.custom-range::-webkit-slider-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px white, 0 0 0 0.2rem rgba(2, 122, 162, 0.25);
}
.custom-range::-webkit-slider-thumb:active {
  background: #5ad4fd;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #C7CBCF;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background: #027AA2;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  appearance: none;
}
.custom-range::-moz-range-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px white, 0 0 0 0.2rem rgba(2, 122, 162, 0.25);
}
.custom-range::-moz-range-thumb:active {
  background: #5ad4fd;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #C7CBCF;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  background: #027AA2;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  appearance: none;
}
.custom-range::-ms-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px white, 0 0 0 0.2rem rgba(2, 122, 162, 0.25);
}
.custom-range::-ms-thumb:active {
  background: #5ad4fd;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-fill-lower {
  background-color: #C7CBCF;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #C7CBCF;
  border-radius: 1rem;
}
.doughnut-legend {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
}
.doughnut-legend-label {
  flex: 0 0 auto;
}
.doughnut-legend-label:before {
  content: "";
  display: inline-block;
  width: 21px;
  height: 21px;
  border: 5px solid #C7CBCF;
  margin-right: 0.375rem;
  vertical-align: middle;
  border-radius: 50%;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.15);
}
.doughnut-legend-label.is-green:before {
  border-color: #00C596;
}
.doughnut-legend-label.is-red:before {
  border-color: #FF5865;
}
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #333a46;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0;
  box-shadow: 0 0.25rem 1rem rgba(64, 66, 75, 0.1);
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #E8E9EB;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #636974;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #40424B;
  text-decoration: none;
  background: #F5F6F7;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background: #027AA2;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #C7CBCF;
  background-color: transparent;
  background-image: none;
}
.dropdown.open .dropdown-menu,
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #333a46;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #636974;
}
.esg-certification-counts {
  list-style: none;
  margin: 0;
  padding: 0;
}
.esg-certification-count {
  display: flex;
  align-items: center;
  margin: 0 0 1rem;
  padding: 0;
}
.esg-certification-count-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 4rem;
  margin-right: 2rem;
}
.esg-certification-count-image img {
  display: block;
  max-width: 6rem;
  max-height: 4rem;
  height: auto;
  width: auto;
}
.esg-certification-count-data {
  font-size: 1.125rem;
  color: #979EA8;
}
.empowering-change-title {
  font-family: 'futura-pt', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 1rem;
  line-height: 1.25rem;
}
.empowering-change-title b,
.empowering-change-title strong {
  font-family: 'futura-pt', sans-serif;
  font-weight: 600;
}
.empowering-change-plan-name {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.25rem;
}
.empowering-change-stats {
  list-style: none;
  margin: 0;
  padding: 0;
}
.empowering-change-stats.is-print .empowering-change-stat {
  display: flex;
  align-items: center;
  height: 2rem;
  margin-bottom: 1rem;
}
.empowering-change-stats.is-print .empowering-change-stat-value {
  font-size: 2rem;
  font-weight: 300;
  width: 8.5rem;
  flex: 0 0 auto;
}
.empowering-change-stats.is-print .empowering-change-stat-label {
  font-size: 0.875rem;
  line-height: 1.125;
}
.empowering-change-stats.is-screen {
  display: flex;
}
.empowering-change-stats.is-screen .empowering-change-stat {
  margin: 0;
  padding: 0 2rem 0 0;
  flex: 1 1 100%;
  max-width: 17.5rem;
}
@media (max-width: 1100px) {
  .empowering-change-stats.is-screen .empowering-change-stat {
    padding-right: 2rem;
  }
}
.empowering-change-stats.is-screen .empowering-change-stat-value {
  font-size: 1.625rem;
  line-height: 1;
  font-weight: bold;
}
@media (max-width: 1100px) {
  .empowering-change-stats.is-screen .empowering-change-stat-value {
    font-size: 1.5rem;
  }
}
.empowering-change-stats.is-screen .empowering-change-stat-value:after {
  content: "";
  display: block;
  width: 1.625rem;
  margin: 1.125rem 0 0.75rem;
  height: 0;
  border-bottom: 1px solid white;
}
.empowering-change-stats.is-screen .empowering-change-stat-label {
  font-size: 1rem;
  line-height: 2;
}
@media (max-width: 1100px) {
  .empowering-change-stats.is-screen .empowering-change-stat-label {
    font-size: 0.75rem;
  }
}
.exposure-list {
  margin: 0 20px 20px 0;
}
.exposure-list dt {
  margin: 0 10px 15px 0;
  font-weight: bold;
  color: #979EA8;
  display: block;
  float: left;
  width: 24px;
  overflow: hidden;
  clear: left;
}
.exposure-list dd {
  padding: 0 0 15px 30px;
  color: #027AA2;
}
.file-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
  line-height: 0.875rem;
}
.file-list-item {
  display: flex;
  background-color: white;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
}
.file-list-item:not(:last-child) {
  margin-bottom: 0.5rem;
}
.file-list-item > .btn {
  flex: 0 0 auto;
}
.file-list-item-name {
  flex: 1 1 60%;
  margin: 0 0.75rem;
}
.file-list-item-meta {
  flex: 1 1 40%;
  margin: 0 0.75rem;
}
.form-grid {
  display: flex;
  flex-flow: row wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.form-grid > * {
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex: 6 6 auto;
}
.form-grid > .col-1 {
  width: calc(100% * (1 / 6));
  flex: 1 1 auto;
}
.form-grid > .col-2 {
  width: calc(100% * (2 / 6));
  flex: 2 2 auto;
}
.form-grid > .col-3 {
  width: calc(100% * (3 / 6));
  flex: 3 3 auto;
}
.form-grid > .col-4 {
  width: calc(100% * (4 / 6));
  flex: 4 4 auto;
}
.form-grid > .col-5 {
  width: calc(100% * (5 / 6));
  flex: 5 5 auto;
}
.form-grid-button {
  padding-top: 2rem;
}
.form-grid-button .btn {
  display: block;
  width: 100%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.form-footer {
  display: flex;
  align-items: center;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: 2rem;
}
.form-footer.is-right {
  justify-content: flex-end;
}
.form-footer > * {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.form-control {
  display: block;
  height: calc(2.5rem + 2px);
  width: 100%;
  padding: 0.625rem 1rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25rem;
  color: #333a46;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #C7CBCF;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* autoprefixer: off */
}
@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #333a46;
  background-color: white;
  border-color: #27c7fc;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(2, 122, 162, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #979EA8;
}
.form-control::placeholder {
  color: #979EA8;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #E8E9EB;
  opacity: 1;
}
select.form-control:not([size]):not([multiple]) {
  height: calc(2.5rem + 2px);
}
select.form-control:focus::-ms-value {
  color: #333a46;
  background-color: white;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.label-hint-container {
  position: relative;
  width: 100%;
}
.label-hint-container .label-hint {
  margin-bottom: 0.5rem;
}
.label-hint {
  font-size: 1rem;
  font-weight: normal;
  font-weight: 400 !important;
  line-height: 1.375rem;
  position: absolute;
  right: 0;
  bottom: 0;
}
.label-hint b,
.label-hint strong {
  font-weight: bold;
}
.col-form-label {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.25rem;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 1rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-bottom: 0;
  line-height: 1.25rem;
  color: #333a46;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  height: calc(2rem + 2px);
}
select.form-control-sm:not([size]):not([multiple]) {
  height: calc(2rem + 2px);
}
.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
  height: calc(2.875rem + 2px);
}
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}
.form-group {
  margin-bottom: 0.25rem;
  min-height: 6.75rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
  font-size: 1rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-row > .col-1 {
  width: 100% / 6;
  flex: 1 1 auto;
}
.form-row > .col-2 {
  width: 200% / 6;
  flex: 2 2 auto;
}
.form-row > .col-3 {
  width: 300% / 6;
  flex: 3 3 auto;
}
.form-row > .col-4 {
  width: 400% / 6;
  flex: 4 4 auto;
}
.form-row > .col-5 {
  width: 500% / 6;
  flex: 5 5 auto;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #979EA8;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 1rem;
  color: #00C596;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(0, 197, 150, 0.8);
  border-radius: 0.2rem;
}
.was-validated .form-control:valid,
.was-validated .custom-select:valid,
.form-control.is-valid,
.custom-select.is-valid,
.rbt.is-valid,
.rbt.is-valid .form-control.rbt-input-multi {
  border-color: #00C596;
}
.was-validated .form-control:valid:focus,
.was-validated .custom-select:valid:focus,
.form-control.is-valid:focus,
.custom-select.is-valid:focus,
.rbt.is-valid:focus,
.rbt.is-valid .form-control.rbt-input-multi:focus {
  border-color: #00C596;
  box-shadow: 0 0 0 0.2rem rgba(0, 197, 150, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-feedback,
.rbt.is-valid ~ .valid-feedback,
.rbt.is-valid .form-control.rbt-input-multi ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-tooltip,
.rbt.is-valid ~ .valid-tooltip,
.rbt.is-valid .form-control.rbt-input-multi ~ .valid-tooltip {
  display: block;
}
.was-validated .form-control-file:valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #00C596;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #00C596;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #45ffd3;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background: #00f8bd;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px white, 0 0 0 0.2rem rgba(0, 197, 150, 0.25);
}
.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00C596;
}
.was-validated .custom-file-input:valid ~ .custom-file-label::before,
.custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(0, 197, 150, 0.25);
}
.is-invalid > .label-hint-container.uploader label {
  color: #00C596;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 1rem;
  color: #FF5865;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(255, 88, 101, 0.8);
  border-radius: 0.2rem;
}
.was-validated .form-control:invalid,
.was-validated .custom-select:invalid,
.form-control.is-invalid,
.custom-select.is-invalid,
.rbt.is-invalid,
.rbt.is-invalid .form-control.rbt-input-multi {
  border-color: #FF5865;
}
.was-validated .form-control:invalid:focus,
.was-validated .custom-select:invalid:focus,
.form-control.is-invalid:focus,
.custom-select.is-invalid:focus,
.rbt.is-invalid:focus,
.rbt.is-invalid .form-control.rbt-input-multi:focus {
  border-color: #FF5865;
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 101, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-feedback,
.rbt.is-invalid ~ .invalid-feedback,
.rbt.is-invalid .form-control.rbt-input-multi ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-tooltip,
.rbt.is-invalid ~ .invalid-tooltip,
.rbt.is-invalid .form-control.rbt-input-multi ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #FF5865;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #FF5865;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #ffd8db;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background: #ff8b94;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px white, 0 0 0 0.2rem rgba(255, 88, 101, 0.25);
}
.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #FF5865;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label::before,
.custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 101, 0.25);
}
.is-invalid > .label-hint-container.uploader label {
  color: #FF5865;
}
.form-feedback-summary {
  min-height: 3rem;
  color: white;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  text-align: left;
  display: flex;
  flex-flow: row;
  transition: opacity 0.2s, background-color 0.2s;
  background-color: rgba(255, 88, 101, 0.8);
}
.form-feedback-summary > * {
  display: flex;
  align-items: center;
}
.form-feedback-summary a {
  color: inherit;
  text-decoration: underline;
}
.form-feedback-summary.is-warning {
  background-color: rgba(255, 166, 74, 0.8);
}
.form-feedback-summary.is-success {
  background-color: rgba(0, 197, 150, 0.8);
}
.form-feedback-summary.is-gray {
  background-color: #E8E9EB;
  color: #333a46;
}
.form-feedback-summary.is-blue {
  background-color: rgba(2, 122, 162, 0.8);
}
.form-feedback-summary-icon {
  flex: 0 0 auto;
  justify-content: center;
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
}
.form-feedback-summary-message {
  flex: 1 1 auto;
  line-height: 1.25rem;
  padding: 0.75rem 1rem 0.75rem 0;
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  height: calc(2.5rem + 2px);
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 0) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.form-control-and-icon {
  color: #979EA8;
  position: relative;
}
.form-control-and-icon .form-control {
  padding-left: 3rem;
}
.form-control-and-icon .form-control.is-subtle {
  padding-left: 2rem;
  transition: border-color 0.2s cubic-bezier(0.35, 0, 0.32, 1), padding 0.2s cubic-bezier(0.35, 0, 0.32, 1);
}
.form-control-and-icon .form-control.is-subtle + .icon {
  padding-left: 0;
}
.form-control-and-icon .form-control.is-subtle:focus {
  padding-left: 3rem;
}
.form-control-and-icon .form-control.is-subtle:focus + .icon {
  padding-left: 1rem;
}
.form-control-and-icon .icon {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.625rem 1rem;
  border: 1px solid transparent;
  pointer-events: none;
  transition: padding 0.2s cubic-bezier(0.35, 0, 0.32, 1);
}
.form-control.is-subtle {
  border-color: transparent;
  border-bottom-color: #979EA8;
  transition: border-color 0.2s cubic-bezier(0.35, 0, 0.32, 1);
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
}
.form-control.is-subtle:focus {
  outline: none;
  box-shadow: none;
  border-color: #C7CBCF;
  padding-left: 1rem;
  padding-right: 1rem;
}
.description textarea {
  min-height: 100px;
}
.footer {
  font-family: 'futura-pt', sans-serif;
  font-weight: 300;
  color: #979EA8;
  border-top: 1px solid #E8E9EB;
  display: flex;
  height: 106px;
}
.footer b,
.footer strong {
  font-family: 'futura-pt', sans-serif;
  font-weight: 600;
}
.footer-logo {
  display: flex;
  justify-content: center;
  padding: 0 2rem;
}
.footer-logo .logo-glyph {
  width: 45px;
}
.footer-menu {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  list-style: none;
  margin: 0;
  padding: 0 2rem;
}
.footer-menu > li {
  margin-right: 2rem;
}
.footer-menu a {
  color: #979EA8;
}
@media print {
  .footer-menu a {
    display: none;
  }
}
.svg-icon {
  height: 1.125em;
}
.row {
  display: flex;
}
.row > * {
  flex-grow: 1;
}
.header {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  background-color: white;
  border-bottom: 1px solid #E8E9EB;
  font-size: 1.125rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-width: 748px;
  z-index: 1020;
}
.header b,
.header strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
@media (min-width: 768px) {
  .header {
    position: fixed;
  }
  body.has-absolute-header .header {
    position: absolute;
  }
}
.header a,
.header button {
  color: #333a46;
  text-decoration: none;
}
.header a:hover,
.header button:hover,
.header a:focus,
.header button:focus,
.header a:active,
.header button:active {
  color: #003463;
}
.header-primary {
  position: relative;
  display: flex;
  height: 70px;
  padding-right: 0.75rem;
}
.header-primary.has-search {
  padding-right: 70px;
}
.header-primary .header-logo {
  color: #003463;
  display: flex;
  justify-content: center;
  padding: 0 32px;
}
.header-primary .header-logo .logo-glyph {
  width: 35px;
}
.header-primary.has-secondary {
  height: 120px;
}
.header-logo {
  color: #003463;
  display: flex;
  justify-content: center;
  padding: 0 32px;
}
.header-logo .logo-glyph {
  width: 35px;
}
.pt-header {
  padding-top: 70px;
}
.pt-header-with-secondary {
  padding-top: 120px;
}
.header-menu {
  display: flex;
  align-items: stretch;
  list-style: none;
  margin: 0;
  padding: 0;
}
@media print {
  .header-menu {
    display: none;
  }
}
.header-menu.is-primary {
  flex: 1 0 auto;
}
.header-menu > li {
  display: flex;
  align-items: stretch;
  margin-left: 0.75rem;
  margin-right: 1.25rem;
  position: relative;
}
.header-menu > li > a {
  display: flex;
  align-items: center;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  white-space: nowrap;
}
.header-menu > li > a.active {
  color: #003463;
  border-bottom-color: #003463;
}
.header-menu > li > ul {
  list-style: none;
  position: absolute;
  background-color: white;
  top: 100%;
  padding: 0;
  width: 12.5rem;
  transition: max-height 0.3s 0.3s cubic-bezier(0.35, 0, 0.32, 1), opacity 0.3s 0.3s cubic-bezier(0.35, 0, 0.32, 1);
  overflow: hidden;
  box-shadow: 0 5px 20px rgba(23, 23, 27, 0.05);
  opacity: 0;
  max-height: 0;
}
.header-menu > li > ul.is-left {
  left: -1.25rem;
}
.header-menu > li > ul.is-right {
  right: -1.25rem;
}
.header-menu > li > ul > li > a {
  display: block;
  padding: 1.25rem;
  border-top: 1px solid #E8E9EB;
}
.header-menu > li.header-plan-selector > ul {
  width: 18.75rem;
}
.is-mouse-first .header-menu > li:hover > ul {
  opacity: 1;
  transition-delay: 0s;
  max-height: 8.125rem;
}
.is-mouse-first .header-menu > li:hover > ul.has-1 {
  max-height: 4.0625rem;
}
.is-mouse-first .header-menu > li:hover > ul.has-2 {
  max-height: 8.125rem;
}
.is-mouse-first .header-menu > li:hover > ul.has-3 {
  max-height: 12.1875rem;
}
.is-mouse-first .header-menu > li:hover > ul.has-4 {
  max-height: 16.25rem;
}
.is-mouse-first .header-menu > li:hover > ul.has-5 {
  max-height: 20.3125rem;
}
.is-mouse-first .header-menu > li:hover > ul.has-6 {
  max-height: 24.375rem;
}
.is-mouse-first .header-menu > li:hover > ul.has-7 {
  max-height: 28.4375rem;
}
.is-mouse-first .header-menu > li:hover > ul.has-8 {
  max-height: 32.5rem;
}
.is-mouse-first .header-menu > li:hover > ul:hover {
  overflow-y: auto;
}
.header-plan-selector-active {
  display: flex;
  align-items: center;
}
.header-search {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: white;
  border-left: 1px solid #E8E9EB;
  display: flex;
  transition: width 0.7s cubic-bezier(0.35, 0, 0.32, 1);
  padding: 0;
  overflow: hidden;
  width: 70px;
}
.header-search input {
  min-width: 0;
  font-family: 'roboto', sans-sans-serif;
  /* autoprefixer: off */
  font-weight: 300;
  border: 0 none;
  padding: 0 0 0 70px;
  flex: 1 1 auto;
  cursor: pointer;
}
.header-search input::-webkit-input-placeholder {
  color: #C7CBCF;
}
.header-search input::placeholder {
  color: #C7CBCF;
}
.header-search input:focus {
  outline: none;
}
.header-search-close {
  border: 0 none;
  background-color: transparent;
  width: 70px;
  transition: opacity 0.5s, color 0.2s;
  padding: 0;
  position: absolute;
  height: 100%;
  right: 0;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
}
.header-search-close:focus {
  outline: none;
}
.header-search-open {
  pointer-events: none;
  height: 100%;
  width: 70px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0 none;
}
.header-search:not(.is-open):hover .header-search-open {
  color: #003463;
}
.header-search.is-open {
  width: calc(100% - 99px);
}
.header-search.is-open input {
  cursor: auto;
}
.header-search.is-open .header-search-close {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.5s 0.2s, color 0.2s;
}
.header-secondary {
  height: 50px;
  padding-left: calc(32px - 0.75rem);
  padding-right: calc(70px + 0.5rem);
  display: flex;
  border-top: 1px solid #E8E9EB;
  white-space: nowrap;
}
.header-secondary .header-menu > li > ul > li > a {
  padding: 0.75rem 1rem;
}
.header-plan-selector {
  padding-right: 1.25rem;
  margin-right: 1rem;
  border-right: 1px solid #E8E9EB;
  white-space: normal;
}
.header-plan-selector > a {
  color: #636974;
}
.dashboard-toggle {
  position: relative;
  right: 35px;
  top: -600px;
  display: flex;
  justify-content: flex-end;
  margin: 1.2rem 0 -1.2rem 0;
  z-index: 5;
}
.dashboard-toggle .toggle-label {
  font-size: 0.9rem;
}
.dashboard-toggle .switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}
.dashboard-toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.dashboard-toggle .switch input:checked + .slider {
  background-color: #003463;
}
.dashboard-toggle .switch input:focus + .slider {
  box-shadow: 0 0 1px #003463;
}
.dashboard-toggle .switch input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  transform: translateX(13px);
}
.dashboard-toggle .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 17px;
  background-color: #ccc;
  transition: 0.4s;
}
.dashboard-toggle .switch .slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  border-radius: 50%;
  background-color: white;
  transition: 0.4s;
}
.icon {
  display: inline-block;
  position: relative;
}
.icon.has-badge {
  padding-right: 1rem;
}
.icon svg {
  height: 1.2em;
}
.icon-badge {
  font-family: 'roboto', sans-sans-serif;
  background-color: #FF5865;
  color: white;
  font-weight: 500;
  font-size: 0.5rem;
  min-width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.4375rem;
  line-height: 0.8125rem;
  text-align: center;
  padding: 0 0.25rem;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(-0.125rem, -1.125rem);
          transform: translate(-0.125rem, -1.125rem);
  transition: opacity 0.3s;
}
.iconic-badge {
  width: 85px;
  height: 85px;
  color: white;
  font-size: 2.5rem;
  background-color: rgba(151, 158, 168, 0.4);
  border-radius: 50%;
  margin: 0 auto;
}
.iconic-badge.is-green {
  background-color: rgba(0, 197, 150, 0.4);
}
.iconic-badge.is-green .iconic-badge-inner {
  background-color: #00C596;
}
@media print {
  .iconic-badge.is-green .iconic-badge-inner {
    border-color: #00C596;
    background-color: white;
  }
}
@media print {
  .iconic-badge.is-green {
    color: #00C596;
  }
}
.iconic-badge.is-red {
  background-color: rgba(255, 88, 101, 0.4);
}
.iconic-badge.is-red .iconic-badge-inner {
  background-color: #FF5865;
}
@media print {
  .iconic-badge.is-red .iconic-badge-inner {
    border-color: #FF5865;
    background-color: white;
  }
}
@media print {
  .iconic-badge.is-red {
    color: #FF5865;
  }
}
.iconic-badge-inner {
  background-color: #979EA8;
  border-radius: 50%;
  height: 75px;
  width: 75px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media print {
  .iconic-badge-inner {
    width: 85px;
    height: 85px;
    margin: 0;
    border: 3px solid;
  }
}
.inline-rating {
  color: #027AA2;
  line-height: 28px;
  width: 30px;
  border: 1px solid #027AA2;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin: -15px 5px;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file:focus {
  z-index: 3;
}
.input-group > .form-control + .form-control,
.input-group > .custom-select + .form-control,
.input-group > .custom-file + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .custom-select + .custom-select,
.input-group > .custom-file + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn + .btn,
.input-group-append .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-append .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.625rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #333a46;
  text-align: center;
  white-space: nowrap;
  background-color: #E8E9EB;
  border: 1px solid #C7CBCF;
  border-radius: 0;
}
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.issue-card-grid {
  margin: -1.5rem;
}
@media (max-width: 991px) {
  .issue-card-grid {
    margin: -0.5rem;
  }
}
.issue-card {
  display: inline-block;
  margin: 1.5rem;
  height: 19.875rem;
  width: 22.625rem;
  position: relative;
}
.issue-card.is-tall {
  height: 22.625rem;
}
.issue-card.is-tall.has-activator {
  height: 26.875rem;
}
@media screen {
  .issue-card {
    -webkit-perspective: 800px;
            perspective: 800px;
  }
}
@media (max-width: 991px) {
  .issue-card {
    width: 344px;
    margin: 0.5rem;
  }
}
@media print {
  .issue-card {
    width: 316px;
  }
}
.issue-card-front-container,
.issue-card-back-container {
  transition: all 0.8s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
@media print {
  .issue-card-front-container,
  .issue-card-back-container {
    border: 1px solid #E8E9EB;
  }
}
.issue-card-front-container {
  background-color: #fff;
  z-index: 2;
  -webkit-transform: rotateY(0) translateZ(1px);
          transform: rotateY(0) translateZ(1px);
}
.issue-card-back-container {
  background-image: url(/static/media/card-back.589c7466.svg), linear-gradient(to bottom, #003463 0%, #009987 100%);
  background-repeat: repeat-x;
  background-size: cover;
  background-position: bottom center;
  color: #fff;
  z-index: 1;
  -webkit-transform: rotateY(-180deg) translateZ(0);
          transform: rotateY(-180deg) translateZ(0);
  pointer-events: none;
}
.issue-card.is-flipped .issue-card-front-container {
  z-index: 1;
  -webkit-transform: rotateY(180deg) translateZ(0);
          transform: rotateY(180deg) translateZ(0);
  pointer-events: none;
}
.issue-card.is-flipped .issue-card-back-container {
  z-index: 2;
  -webkit-transform: rotateY(0) translateZ(1px);
          transform: rotateY(0) translateZ(1px);
  pointer-events: all;
}
.issue-card-front,
.issue-card-back {
  padding: 1.625rem 1.875rem;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.issue-card-toggle {
  background: transparent none;
  border: 0 none;
  position: absolute;
  font-size: 23px;
  width: 34px;
  line-height: 34px;
  top: 24px;
  right: 24px;
  text-align: center;
  padding: 0;
}
.issue-card-toggle:focus {
  outline: 0 none;
}
@media print {
  .issue-card-toggle {
    display: none;
  }
}
.issue-card-toggle.is-ringed {
  transition: color 0.2s;
  color: #027AA2;
  font-family: Georgia, serif;
  font-size: 14px;
}
@media screen and (prefers-reduced-motion: reduce) {
  .issue-card-toggle.is-ringed {
    transition: none;
  }
}
.issue-card-toggle.is-ringed:before,
.issue-card-toggle.is-ringed:after {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -1;
  width: 24px;
  height: 24px;
}
.issue-card-toggle.is-ringed:before {
  transition: width 0.3s, height 0.3s;
  background-color: #027AA2;
  opacity: 0.35;
}
.issue-card-toggle.is-ringed:after {
  transition: background-color 0.1s;
  background-color: #fff;
  border: 1px solid #027AA2;
}
@media screen and (prefers-reduced-motion: reduce) {
  .issue-card-toggle.is-ringed:after {
    transition: none;
  }
}
.issue-card-toggle.is-ringed:hover {
  color: #fff;
}
.issue-card-toggle.is-ringed:hover:before {
  width: 32px;
  height: 32px;
}
.issue-card-toggle.is-ringed:hover:after {
  background-color: #027AA2;
}
.issue-card-header,
.issue-card-footer {
  font-size: 0.875rem;
  line-height: 1.25rem;
  min-height: 2.5rem;
}
.issue-card-header {
  padding-right: 2.5rem;
}
.issue-card-description {
  display: flex;
  flex-flow: column;
  justify-content: center;
  line-height: 1.25rem;
}
.issue-card-footer {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}
.issue-card-activator {
  padding: 0 1.875rem 1.625rem;
}
.issue-card-activator-status {
  display: flex;
  justify-content: space-between;
  height: calc(2.5rem + 2px);
  align-items: center;
  font-size: 0.875rem;
}
.issue-card-activator-label {
  color: #636974;
}
.issue-card-activator-button {
  color: #FF5865;
}
.issue-card-activator-button:active,
.issue-card-activator-button:focus,
.issue-card-activator-button:hover {
  color: #FF5865;
}
.issue-card.is-esg-focus .issue-card-front .issue-card-header {
  padding-left: 2.5rem;
}
.issue-card.is-esg-focus .issue-card-front .issue-card-header-code {
  color: white;
  position: absolute;
  z-index: 1;
  left: 1.4375rem;
  top: -0.25rem;
  width: 2.375rem;
  height: 4.1875rem;
  display: block;
  text-align: center;
  cursor: pointer;
  padding-top: 1.875rem;
}
.issue-card.is-esg-focus .issue-card-front .issue-card-header-code:before,
.issue-card.is-esg-focus .issue-card-front .issue-card-header-code:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  border: 0 solid #003463;
  border-bottom: 1.25rem solid transparent;
  z-index: -1;
}
.issue-card.is-esg-focus .issue-card-front .issue-card-header-code:before {
  border-left-width: 2.375rem;
}
.issue-card.is-esg-focus .issue-card-front .issue-card-header-code:after {
  border-right-width: 2.375rem;
}
.issue-data-group {
  font-family: 'futura-pt', sans-serif;
  margin-bottom: 1rem;
}
.issue-data-group table th.title-col {
  width: 27%;
}
.issue-data-group table th.restate-col {
  width: 8%;
}
.issue-data-group table th.data-col {
  width: 13%;
}
.issue-data-group table tbody tr {
  height: 52px;
}
.issue-data-group .issue-data-group-header {
  padding: 1rem 1.5rem;
  color: white;
  text-transform: uppercase;
}
.issue-data-group .issue-data-group-header .header-icon {
  font-size: 2rem;
  margin-right: 1rem;
}
.issue-data-group .issue-data-group-header .issue-data-group-title {
  font-weight: bold;
}
.issue-data-group .issue-data-group-header table {
  width: 100%;
  font-weight: normal;
}
.issue-data-group .issue-data-group-header table th * {
  display: inline-block;
  vertical-align: middle;
}
.issue-data-group .btn-restate {
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  color: #34b991;
  border-color: #34b991;
  text-decoration: none !important;
  box-shadow: none !important;
}
.issue-data-group .btn-restate:hover {
  background-color: #34b991;
  color: white;
  text-decoration: none;
}
.issue-data-group .btn-restate.no-border {
  border: none;
}
.issue-data-group .issue-data-table-body {
  padding: 1rem 1.5rem;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.issue-data-group .issue-data-table-body table {
  width: 100%;
}
.issue-data-group .issue-data-table-body table tr:not(:last-child) {
  border-bottom: 1.5px solid #e5e5e5;
}
.issue-data-group .issue-data-table-body table td {
  padding: 0.5rem 0;
}
.issue-data-group .issue-data-card-table-container {
  height: calc(100% - 42px);
}
.issue-data-group .issue-data-card {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background: white;
  box-sizing: border-box;
  padding-left: 2rem;
  padding-right: 2rem;
}
.issue-data-group .issue-data-card .issue-data-card-header {
  height: 5rem;
  border-bottom: 1px solid #e5e5e5;
  font-size: 1.25rem;
}
.issue-data-group .issue-data-card .issue-data-card-header .issue-data-card-header-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.issue-data-group .issue-data-card .issue-data-card-header th {
  font-weight: normal;
  color: #636363;
  word-wrap: break-word;
}
.issue-data-group .issue-data-card .issue-data-card-table {
  box-sizing: border-box;
}
.issue-data-group .issue-data-card .issue-data-card-table tr:not(:first-child, :last-child) {
  border-bottom: 1px solid #e5e5e5;
}
.issue-progress.is-print {
  display: flex;
}
.issue-progress-icon {
  flex: 0 0 auto;
  height: 4.6875rem;
  width: 4.6875rem;
  border-radius: 50%;
  transition: background-color 0.2s;
}
.issue-progress-data {
  list-style: none;
  margin: 0;
  padding: 0;
}
.issue-progress-data-point {
  display: flex;
}
.issue-progress.is-card .issue-progress-icon {
  margin: 0 auto;
}
.issue-progress.is-card .issue-progress-change {
  text-align: center;
  padding: 0.75rem 0;
}
.issue-progress.is-card .issue-progress-data-point {
  justify-content: space-between;
  padding: 0.5rem 0;
}
.issue-progress.is-card .issue-progress-data-point:nth-child(n + 2) {
  border-top: 1px solid #E8E9EB;
}
.issue-progress.is-print {
  min-height: 7rem;
}
.issue-progress.is-print .issue-progress-icon {
  margin-right: 1rem;
}
.issue-progress.is-print .issue-progress-name {
  font-size: 1.125rem;
  line-height: 1.25rem;
}
.issue-progress.is-print .issue-progress-data {
  margin-top: 0.5rem;
  color: #979EA8;
  font-size: 0.75rem;
  line-height: 1rem;
}
.link-list-header {
  font-size: 1.125rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
.link-list-header .link-list-toggle {
  color: #979EA8;
  flex: 0 0 auto;
  margin-right: 0.5rem;
}
.link-list-header .link-list-title {
  flex: 1 1 auto;
}
.link-list-badge {
  flex: 0 0 auto;
  font-weight: 300;
  width: 2.25rem;
  padding-left: 0;
  padding-right: 0;
}
.link-list {
  list-style: none;
  margin: 0;
  padding: 0 0 2rem;
}
.link-list-item {
  font-size: 1rem;
  line-height: 2.5rem;
  border-top: 1px solid rgba(151, 158, 168, 0.3);
  transition: background-color 0.3s, border-left 0.3s, color 0.3s;
  border-left: 0.125rem solid transparent;
}
.link-list-item > a {
  color: #333a46;
  display: block;
  padding: 0 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link-list-item > a:focus,
.link-list-item > a:hover,
.link-list-item > a:active {
  color: #027AA2;
  text-decoration: none;
}
.link-list-item.is-active {
  background-color: rgba(151, 158, 168, 0.1);
  border-left-color: #027AA2;
  font-weight: bold;
}
.link-list-item.is-active > a {
  color: #027AA2;
}
.link-list-item.is-unread {
  font-weight: bold;
}
.loading-spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  opacity: 0 !important;
  transition: opacity 0.2s;
}
.loading-spinner.is-active {
  pointer-events: all;
  opacity: 1 !important;
}
.loading-spinner-graphic {
  position: absolute;
  top: calc(50% - 3rem / 2);
  left: calc(50% - 3rem / 2);
  width: 3rem;
  height: 3rem;
  border: 0.125rem solid #003463;
  border-radius: 50%;
  border-top-color: transparent;
  -webkit-animation: loading-spinner 1.2s linear infinite;
          animation: loading-spinner 1.2s linear infinite;
}
@-webkit-keyframes loading-spinner {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359.999deg);
            transform: rotate(359.999deg);
  }
}
@keyframes loading-spinner {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359.999deg);
            transform: rotate(359.999deg);
  }
}
.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem auto;
  min-width: calc(320px - 0.5rem * 2);
  max-width: 560px;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -2rem);
          transform: translate(0, -2rem);
}
@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.modal-padded .modal-content {
  padding: 30px;
}
.modal-padded .modal-body {
  margin-top: 10px;
  margin-bottom: 10px;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.modal-header .close {
  padding: 1rem 1.25rem;
  margin: -1rem -1.25rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5rem;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0 1.25rem 1rem;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1.25rem 1rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
#vanillatoasts-container {
  position: fixed;
  top: 70px;
  right: 0;
  width: 374px;
  z-index: 2147483647;
}
@media print {
  #vanillatoasts-container {
    display: none;
  }
}
.vanillatoasts-toast {
  position: relative;
  padding: 23px 27px 15px;
  margin: 17px;
  cursor: pointer;
  color: white;
  background-color: rgba(0, 52, 99, 0.9);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.25);
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: VanillaToasts;
          animation-name: VanillaToasts;
  -webkit-animation-timing-function: cubic-bezier(0.35, 0, 0.32, 1);
          animation-timing-function: cubic-bezier(0.35, 0, 0.32, 1);
}
.vanillatoasts-toast.has-icon {
  padding-left: 78px;
}
.vanillatoasts-fadeOut {
  -webkit-animation-name: VanillaToastsFadeOut;
          animation-name: VanillaToastsFadeOut;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.35, 0, 0.32, 1);
          animation-timing-function: cubic-bezier(0.35, 0, 0.32, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
#vanillatoasts-container p,
#vanillatoasts-container h4 {
  margin: 0 0 0.5rem !important;
}
.vanillatoasts-title {
  font-size: 1rem;
  line-height: 1.125rem;
}
.vanillatoasts-text {
  font-size: 0.875rem;
  line-height: 1rem;
  color: #fff;
}
.vanillatoasts-icon {
  position: absolute;
  top: 50%;
  left: 27px;
  width: 22px;
  height: 22px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.vanillatoasts-toast a,
.vanillatoasts-toast a:hover {
  color: white !important;
  text-decoration: underline !important;
}
/** toast types */
.vanillatoasts-success {
  background-color: rgba(0, 197, 150, 0.9);
}
.vanillatoasts-warning {
  background-color: rgba(255, 166, 74, 0.9);
}
.vanillatoasts-danger,
.vanillatoasts-error {
  background-color: rgba(255, 88, 101, 0.9);
}
@-webkit-keyframes VanillaToasts {
  from {
    -webkit-transform: translate3d(400px, 0, 0);
            transform: translate3d(400px, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes VanillaToasts {
  from {
    -webkit-transform: translate3d(400px, 0, 0);
            transform: translate3d(400px, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes VanillaToastsFadeOut {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(400px, 0, 0);
            transform: translate3d(400px, 0, 0);
    opacity: 0;
  }
}
@keyframes VanillaToastsFadeOut {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(400px, 0, 0);
            transform: translate3d(400px, 0, 0);
    opacity: 0;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #40424B;
}
.nav-tabs {
  border-bottom: 1px solid #C7CBCF;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #E8E9EB #E8E9EB #C7CBCF;
}
.nav-tabs .nav-link.disabled {
  color: #40424B;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #333a46;
  background-color: white;
  border-color: #C7CBCF #C7CBCF white;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #027AA2;
}
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.nav.is-compact .nav-link {
  padding: 0 0.75rem;
}
.nav.is-flush .nav-item:first-child .nav-link {
  padding-left: 0;
}
.nav.is-flush .nav-item:last-child .nav-link {
  padding-right: 0;
}
.organization-stamp {
  display: flex;
  align-items: center;
  height: 3.75rem;
  position: relative;
}
.organization-stamp-logo {
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.organization-stamp-logo img {
  max-width: 5rem;
  height: auto;
  max-height: 3.75rem;
  display: block;
}
.organization-stamp-details {
  padding-left: 1rem;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0;
  font-size: 1rem;
  font-weight: 400;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.625rem 0.625rem;
  margin-left: -1px;
  line-height: 1.25rem;
  color: #027AA2;
  background-color: transparent;
  border-bottom: 1px solid #027AA2;
  min-width: 2rem;
  text-align: center;
  transition: color 0.2s, border-color 0.2s;
}
.page-link:hover {
  z-index: 2;
  color: #014156;
  text-decoration: none;
  background-color: transparent;
  border-color: #014156;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(2, 122, 162, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-item {
  padding: 0 0.125rem;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.page-item.active .page-link {
  z-index: 1;
  color: #333a46;
  background-color: transparent;
  border-color: #333a46;
}
.page-item.disabled .page-link {
  color: #C7CBCF;
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  border-color: #C7CBCF;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.panels {
  display: flex;
  overflow: hidden;
}
.panels > * {
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  transition: padding 0.3s cubic-bezier(0.35, 0, 0.32, 1), width 0.3s cubic-bezier(0.35, 0, 0.32, 1), opacity 0.3s 0.3s cubic-bezier(0.35, 0, 0.32, 1);
}
.panels > * > * {
  transition: opacity 0.3s cubic-bezier(0.35, 0, 0.32, 1);
}
.panels > *.is-loading > * {
  opacity: 0.2;
}
.panels > * .loading-spinner,
.panels > * .panel-close {
  transition: left 0.3s cubic-bezier(0.35, 0, 0.32, 1), right 0.3s cubic-bezier(0.35, 0, 0.32, 1);
}
.panels > *.is-closed {
  opacity: 0;
  pointer-events: none;
}
.panels.is-closing-a-panel > * {
  transition: opacity 0.3s cubic-bezier(0.35, 0, 0.32, 1), width 0.3s 0.3s cubic-bezier(0.35, 0, 0.32, 1), padding 0.3s 0.3s cubic-bezier(0.35, 0, 0.32, 1);
}
.panels.is-closing-a-panel > * .loading-spinner,
.panels.is-closing-a-panel > * .panel-close {
  transition: left 0.3s 0.3s cubic-bezier(0.35, 0, 0.32, 1), right 0.3s 0.3s cubic-bezier(0.35, 0, 0.32, 1);
}
.panels.is-panel-2-open > * {
  width: 50%;
}
.panels.is-panel-3-open > * {
  width: 33.333%;
}
.panels.is-panel-4-open > * {
  width: 25%;
}
.panels.is-panel-5-open > * {
  width: 20%;
}
.panel-scroll-list {
  flex: 1 1;
}
.panel-scroll-list-content {
  padding-right: 1rem;
  padding-bottom: 2rem;
}
.panel-close {
  padding: 0;
  background: none;
  border: 0 none;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1em;
  font-size: 20px;
}
.panel-close:focus {
  outline: 0 none;
  color: #027AA2;
}
.portfolio-page-section {
  margin: 0 auto;
  max-width: 1190px;
}
.portfolio-page-section .loading-spinner-graphic {
  top: 15.2rem;
}
.progress-indicator {
  display: block;
}
.progress-indicator-inner {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
  counter-reset: progress-indicator;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  display: inline-flex;
  flex-flow: row;
}
.progress-indicator-inner b,
.progress-indicator-inner strong {
  font-weight: bold;
}
.progress-indicator-icon-step {
  counter-increment: progress-indicator;
  display: flex;
  flex: 0 0 auto;
  width: 6.5rem;
}
.progress-indicator-icon-step > * {
  flex: 1 1 auto;
  width: 100%;
  padding: 3.25rem 0.5rem 0.25rem;
  position: relative;
  z-index: 1;
  color: #C7CBCF;
}
.progress-indicator-icon-step > *:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  border: 0.125rem solid #C7CBCF;
  background-color: transparent;
  transition: border-color 0.2s, background-color 0.2s;
}
.progress-indicator-icon-step > a:hover {
  color: #027AA2;
  text-decoration: none;
}
.progress-indicator-icon-step > a:hover:before {
  border-color: #027AA2;
}
.progress-indicator-icon-step > a:hover:after {
  background-color: #027AA2;
}
.progress-indicator-icon-step:nth-child(n+2) > *:after {
  content: "";
  display: block;
  height: 0.125rem;
  position: absolute;
  background-color: #C7CBCF;
  top: calc((2.5rem - 0.125rem) / 2);
  width: calc(100% - 2.5rem);
  right: calc(50% + 2.5rem / 2);
  transition: background-color 0.2s;
}
.progress-indicator-icon-step.is-complete:nth-child(n+2) > *:after,
.progress-indicator-icon-step.is-current:nth-child(n+2) > *:after {
  background-color: #00C596;
}
.progress-indicator-icon-step.is-complete > * {
  color: #979EA8;
}
.progress-indicator-icon-step.is-complete > *:before {
  background-color: #00C596;
  border-color: transparent;
}
.progress-indicator-icon-step.is-complete > * .progress-indicator-step-icon {
  color: white;
}
.progress-indicator-icon-step.is-complete > a:hover:before {
  background-color: #027AA2;
}
.progress-indicator-icon-step.is-current > * {
  color: #00C596;
}
.progress-indicator-icon-step.is-current > *:before {
  background-color: transparent;
  border-color: #00C596;
}
.progress-indicator-icon-step.is-current > * .progress-indicator-step-icon {
  color: #00C596;
}
.progress-indicator-step-icon {
  position: absolute;
  font-size: 1.25rem;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 2.5rem;
  display: block;
  line-height: 2.5rem;
  color: #C7CBCF;
}
.progress-indicator.is-small .progress-indicator-inner {
  font-size: 0.75rem;
  line-height: 0.875rem;
}
.progress-indicator.is-small .progress-indicator-icon-step {
  width: 5rem;
}
.progress-indicator.is-small .progress-indicator-icon-step > * {
  padding: 2.5rem 0.25rem 0.25rem;
}
.progress-indicator.is-small .progress-indicator-icon-step > *:before {
  width: 1.75rem;
  height: 1.75rem;
}
.progress-indicator.is-small .progress-indicator-icon-step:nth-child(n+2) > *:after {
  top: 1.625rem / 2;
  width: calc(100% - 1.75rem);
  right: calc(50% + 1.75rem / 2);
}
.progress-indicator.is-small .progress-indicator-step-icon {
  font-size: 1rem;
  line-height: 1.75rem;
}
.quartile-set,
.quartile-legend {
  color: #979EA8;
  font-weight: 400;
  font-size: 11px;
}
.quartile-set {
  position: relative;
  border-bottom: 1px solid #E8E9EB;
  line-height: 44px;
}
.quartile {
  border-top: 1px solid #E8E9EB;
}
.quartile-legend {
  display: flex;
  text-align: center;
  justify-content: center;
  line-height: 1rem;
  padding-left: 60px;
  margin-top: 1rem;
}
.quartile-legend-label {
  flex: 0 0 auto;
  width: 96px;
}
.radio-dot {
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
}
.radio-dot:before {
  content: "";
  display: block;
  -webkit-transform-origin: center;
          transform-origin: center;
  opacity: 0.6;
}
.radio-dot.is-floating {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.radio-dot.is-light .radio-dot-inner,
.radio-dot.is-light .radio-dot-outer,
.radio-dot.is-light:before {
  background-color: #C7CBCF;
}
@media print {
  .radio-dot.is-light .radio-dot-outer {
    border-color: #C7CBCF;
  }
}
.radio-dot.is-red .radio-dot-inner,
.radio-dot.is-red .radio-dot-outer,
.radio-dot.is-red:before {
  background-color: #FF5865;
}
@media print {
  .radio-dot.is-red .radio-dot-outer {
    border-color: #FF5865;
  }
}
.radio-dot.is-green .radio-dot-inner,
.radio-dot.is-green .radio-dot-outer,
.radio-dot.is-green:before {
  background-color: #00C596;
}
@media print {
  .radio-dot.is-green .radio-dot-outer {
    border-color: #00C596;
  }
}
.radio-dot.is-blue .radio-dot-inner,
.radio-dot.is-blue .radio-dot-outer,
.radio-dot.is-blue:before {
  background-color: #027AA2;
}
@media print {
  .radio-dot.is-blue .radio-dot-outer {
    border-color: #027AA2;
  }
}
.radio-dot.has-shadow .radio-dot-outer {
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.6);
}
.radio-dot:hover .radio-dot-inner {
  background-color: white;
}
.radio-dot:hover .radio-dot-outer {
  opacity: 1;
}
.radio-dot:hover:before {
  width: 50px;
  height: 50px;
  opacity: 0;
  transition: width 0.5s cubic-bezier(0.35, 0, 0.32, 1), height 0.5s cubic-bezier(0.35, 0, 0.32, 1), opacity 0.5s cubic-bezier(0.35, 0, 0.32, 1);
}
.radio-dot:hover.has-shadow .radio-dot-outer {
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.15);
}
.radio-dot:before,
.radio-dot-inner,
.radio-dot-outer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #636974;
}
@media print {
  .radio-dot:before,
  .radio-dot-inner,
  .radio-dot-outer {
    background: transparent none !important;
  }
}
.radio-dot-inner,
.radio-dot-outer {
  transition: background-color 0.2s cubic-bezier(0.35, 0, 0.32, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.32, 1);
  transition: background-color 0.2s cubic-bezier(0.35, 0, 0.32, 1), transform 0.2s cubic-bezier(0.35, 0, 0.32, 1);
  transition: background-color 0.2s cubic-bezier(0.35, 0, 0.32, 1), transform 0.2s cubic-bezier(0.35, 0, 0.32, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.32, 1);
}
.radio-dot:before,
.radio-dot-inner {
  width: 8px;
  height: 8px;
}
.radio-dot-outer {
  opacity: 0.35;
  width: 16px;
  height: 16px;
}
@media print {
  .radio-dot-outer {
    border: 0.25rem solid black;
    opacity: 1;
  }
}
.radio-dot-legend {
  display: flex;
  flex-flow: row;
  align-items: center;
  font-size: 0.75rem;
}
.radio-dot-legend .radio-dot {
  flex: 0 0 auto;
  margin: -0.5rem -0.25rem -0.5rem -0.5rem;
}
.radio-dot-legend-label {
  flex: 1 1 auto;
}
.radio-dot-legend-label:last-child {
  flex: 0 0 auto;
}
.radio-line {
  display: flex;
  flex-flow: row;
  text-align: center;
  font-size: 17px;
  line-height: 12px;
  position: relative;
  padding: 0 5%;
}
.radio-line:before {
  display: block;
  content: "";
  background-color: #C7CBCF;
  opacity: 0.5;
  position: absolute;
  top: 40px / 2;
  left: 0;
  height: 1px;
  width: 100%;
}
.radio-line input {
  visibility: hidden;
  height: 0;
  width: 0;
}
.radio-line-item.radio-dot {
  padding-top: 52px;
  padding-bottom: 12px;
  font-weight: normal;
  color: #636974;
  flex: 2 0 40px;
}
.radio-line-item.radio-dot:before,
.radio-line-item.radio-dot .radio-dot-outer,
.radio-line-item.radio-dot .radio-dot-inner {
  top: 40px / 2;
}
.rating-circle {
  border: 20px solid transparent;
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
  background-color: white;
  font-family: 'futura-pt', sans-serif;
  font-weight: 600;
  font-size: 4.75rem;
  line-height: 120px;
  display: block;
  text-align: center;
  color: #979EA8;
  border-color: #979EA8;
}
.rating-circle b,
.rating-circle strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.rating-circle.is-A {
  color: #B4B1AC;
  border-color: #B4B1AC;
}
.rating-circle.is-B {
  color: #BF9763;
  border-color: #BF9763;
}
.rating-circle.is-C {
  color: #C9C7C3;
  border-color: #C9C7C3;
}
.rating-circle.is-D {
  color: #967766;
  border-color: #967766;
}
.rating-circle.is-E {
  color: #333A46;
  border-color: #333A46;
}
.small-rating-circle {
  background-color: transparent;
  font-family: "roboto", sans-serif;
  text-align: center;
  justify-content: center;
  margin: 0 0 0 5px;
  display: inline-block;
}
.small-rating-circle span {
  border: 4px solid transparent;
  border-radius: 50%;
  display: block;
  height: 29px;
  width: 29px;
}
.small-rating-circle.is-A span {
  border-color: #B4B1AC;
}
.small-rating-circle.is-B span {
  border-color: #BF9763;
}
.small-rating-circle.is-C span {
  border-color: #C9C7C3;
}
.small-rating-circle.is-D span {
  border-color: #967766;
}
.small-rating-circle.is-E span {
  border-color: #333A46;
}
.responsive-wrapper {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}
.responsive-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.scroll-to-top {
  display: flex;
  color: #027AA2;
  position: fixed;
  height: 2.25rem;
  width: 2.25rem;
  bottom: 2.125rem;
  right: 2.25rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.5s;
  z-index: 5;
  align-items: center;
  justify-content: center;
  border: 0 none;
  font-size: 0.875rem;
}
.scroll-to-top:focus {
  outline: 0 none;
}
.side-drawer {
  position: fixed;
  background-color: white;
  top: 0;
  bottom: 0;
  z-index: 1030;
  width: 32rem;
  box-shadow: 0 0 4rem rgba(64, 66, 75, 0.15);
  transition: -webkit-transform 0.3s cubic-bezier(0.35, 0, 0.32, 1);
  transition: transform 0.3s cubic-bezier(0.35, 0, 0.32, 1);
  transition: transform 0.3s cubic-bezier(0.35, 0, 0.32, 1), -webkit-transform 0.3s cubic-bezier(0.35, 0, 0.32, 1);
  display: flex;
  flex-flow: column;
}
.side-drawer.is-left {
  left: 0;
  -webkit-transform: translateX(-113%);
          transform: translateX(-113%);
}
.side-drawer.is-right {
  right: 0;
  -webkit-transform: translateX(113%);
          transform: translateX(113%);
}
.side-drawer.is-open {
  -webkit-transform: none;
          transform: none;
}
.side-drawer-header {
  display: flex;
  align-items: flex-start;
  padding: 4.875rem 2.625rem 2.625rem;
}
.side-drawer-header,
.side-drawer-footer {
  flex-grow: 0;
  flex-shrink: 0;
}
.side-drawer-title,
.side-drawer-close {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 1.25rem;
  line-height: 1.5rem;
}
.side-drawer-title {
  color: #003463;
  flex-grow: 1;
}
.side-drawer-close {
  font-size: 1rem;
  color: #979EA8;
}
.side-drawer-close:hover,
.side-drawer-close:focus,
.side-drawer-close:active {
  color: #636974;
  text-decoration: none;
}
.side-drawer-close .fa-times {
  font-size: 1.2rem;
  top: 0.1rem;
  position: relative;
}
.side-drawer-content {
  flex-shrink: 1;
  padding: 0 2.625rem 2.625rem;
  overflow-y: auto;
}
.side-drawer-footer {
  padding: 2.625rem;
}
.side-tabs {
  position: fixed;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 990;
  font-size: 0.875rem;
  font-weight: 400;
}
.side-tab {
  background: #003463;
  color: white;
  width: 4rem;
  height: 4rem;
  margin: 1rem 0;
  box-shadow: 0 5px 20px rgba(23, 23, 27, 0.05);
  transition: background-color 0.2s;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.side-tab:active,
.side-tab:focus,
.side-tab:hover {
  background-color: #014673;
  color: white;
  text-decoration: none;
}
.side-tab svg {
  font-size: 1rem;
  margin-top: 0.25rem;
}
.spinner {
  background: rgba(255, 255, 255, 0);
  overflow: hidden;
  position: fixed;
  transition: background-color 1s 0.3s;
  z-index: 2147483646;
  height: auto;
  width: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
.spinner.is-active {
  background: rgba(255, 255, 255, 0.8);
  pointer-events: all;
}
.spinner-graphic {
  height: 320px;
  width: 320px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: opacity 1s 0.3s;
}
.spinner.is-active .spinner-graphic {
  opacity: 1;
}
.spinner-graphic.is-spinning {
  -webkit-animation-name: spin;
          animation-name: spin;
  -webkit-animation-duration: 1000ms;
          animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.strategy-card-grid {
  margin: 1.2rem 1.65rem;
}
.strategy-card {
  display: inline-block;
  margin: 1.2rem 1.65rem;
  height: 392px;
  width: 362px;
  position: relative;
  -webkit-perspective: 800px;
          perspective: 800px;
  box-sizing: border-box;
}
.strategy-card-alert {
  display: block;
  height: 50px;
  min-height: 50px;
  width: 100%;
}
.strategy-card-front,
.strategy-card-back {
  transition: all 0.8s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  display: flex;
  flex-direction: column;
}
.strategy-card-front {
  z-index: 2;
  -webkit-transform: rotateY(0) translateZ(1px);
          transform: rotateY(0) translateZ(1px);
}
.strategy-card-back {
  background-color: #003463;
  background-image: url(/static/media/card-back.589c7466.svg), linear-gradient(to bottom, #003463 0%, #009987 100%);
  background-repeat: repeat-x;
  background-size: contain;
  background-position: bottom center;
  color: #fff;
  z-index: 1;
  -webkit-transform: rotateY(-180deg) translateZ(0);
          transform: rotateY(-180deg) translateZ(0);
  pointer-events: none;
}
.strategy-card.is-flipped .strategy-card-front {
  z-index: 1;
  -webkit-transform: rotateY(180deg) translateZ(0);
          transform: rotateY(180deg) translateZ(0);
  pointer-events: none;
}
.strategy-card.is-flipped .strategy-card-back {
  z-index: 2;
  -webkit-transform: rotateY(0) translateZ(1px);
          transform: rotateY(0) translateZ(1px);
  pointer-events: all;
}
.strategy-card-header,
.strategy-card-footer {
  min-height: 3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.strategy-card-header {
  padding-right: 2.5rem;
  min-height: 120px;
}
.strategy-card-plan-name {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  color: #003463;
  font-size: 1.8125rem;
  line-height: 2.625rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 21px;
  line-height: 1.2;
  letter-spacing: normal;
}
.strategy-card-plan-name b,
.strategy-card-plan-name strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.strategy-card-strategy-name {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  color: #003463;
  font-size: 1.8125rem;
  line-height: 2.625rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 18px;
  line-height: 1.2;
}
.strategy-card-strategy-name b,
.strategy-card-strategy-name strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.strategy-card-body {
  min-height: 115px;
}
.strategy-card-body p {
  margin-bottom: 0;
}
.strategy-upload-dates {
  margin-bottom: 0;
}
.strategy-upload-dates dt {
  display: inline-block;
  width: 50%;
}
.strategy-upload-dates dd {
  display: inline-block;
  width: 50%;
  text-align: right;
}
.strategy-card-time {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.67px;
  text-align: right;
}
.strategy-card-time.danger {
  color: #FF5865 !important;
}
a.strategy-card-time.danger:hover,
a.strategy-card-time.danger:focus {
  color: #ff2536 !important;
}
.strategy-card-footer {
  display: flex;
  justify-content: space-between;
}
.strategy-card-footer .btn {
  padding: 0.625rem 1.1rem;
  line-height: 1.75;
}
.strategy-card-footer .btn-onboarding {
  width: 100%;
}
.strategy-card-alert-box {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  height: 100%;
}
.strategy-card-alert-box .card-alert-message {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 48px;
}
.issue-indicator {
  background: transparent none;
  border: 0 none;
  font-size: 16px;
  line-height: 16px;
  width: 16px;
  color: #fff;
  margin-top: 1rem;
}
.issue-indicator:focus {
  outline: 0 none;
}
.strategy-card-back .card-alert-message {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  color: #003463;
  font-size: 1.8125rem;
  line-height: 2.625rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 20px;
  text-transform: none;
  color: #fff;
  letter-spacing: 0;
  margin-top: 10px;
}
.strategy-card-back .card-alert-message b,
.strategy-card-back .card-alert-message strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.strategy-card-back .issue-indicator {
  font-size: 12px;
  text-transform: uppercase;
  width: 48px;
  margin-top: 25px;
}
.strategy-card-toggle:hover {
  text-decoration: none;
}
.strategy-card-front .strategy-card-content {
  border: 2px solid #fff;
  background-color: #fff;
}
.strategy-card-front.warning .strategy-card-alert-box {
  background-color: #ffa64a;
}
.strategy-card-front.warning .strategy-card-content {
  border: 2px solid #ffa64a;
}
.strategy-card-front.danger .strategy-card-alert-box {
  background-color: #FF5865;
}
.strategy-card-front.danger .strategy-card-content {
  border: 2px solid #FF5865;
}
.strategy-card-description {
  color: #fff;
}
.strategy-card-back h4 {
  margin: 0 0 10px;
}
.strategy-card-content {
  padding: 30px;
}
.strategy-stats {
  display: flex;
  font-size: 1rem;
  line-height: 1.25rem;
  flex-flow: row wrap;
}
.strategy-stats > * {
  flex: 0 0 auto;
  padding-right: 2rem;
  padding-bottom: 2rem;
}
.strategy-stats > * > * {
  margin-bottom: 0.75rem;
}
.strategy-stats > * > b,
.strategy-stats > * > strong {
  display: block;
}
.strategy-stats.is-3-col > * {
  width: 33%;
}
.strategy-stats.is-4-col > * {
  width: 25%;
}
@media (max-width: 1429px) {
  .strategy-stats.is-sm-2-col > * {
    width: 50%;
  }
}
.bootstrap-table-card {
  padding: 1rem 0;
}
.table-card-title {
  font-weight: 700;
}
caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #979EA8;
}
.table {
  font-size: 1rem;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table th,
.table td {
  padding: 0.5rem;
  vertical-align: middle;
}
.table th:first-child,
.table td:first-child {
  padding-left: 0;
}
.table th:last-child,
.table td:last-child {
  padding-right: 0;
}
.table tbody th,
.table td {
  border-top: 1px solid rgba(151, 158, 168, 0.3);
}
.table thead th {
  vertical-align: bottom;
}
.table tbody + tbody {
  border-top: 2px solid rgba(151, 158, 168, 0.3);
}
.table .table {
  background-color: white;
}
.table-sm th,
.table-sm td {
  padding: 0.25rem;
}
.table-bordered {
  border: 1px solid rgba(151, 158, 168, 0.3);
}
.table-bordered th,
.table-bordered td {
  border: 1px solid rgba(151, 158, 168, 0.3);
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr {
  cursor: pointer;
}
.table-hover tbody tr:hover {
  color: #027AA2;
}
.table-hover tbody tr a:hover {
  color: #003463;
  text-decoration: none;
}
.table-compact th,
.table-compact td {
  line-height: 1.25rem;
}
.table.table-primary,
.table.table-primary > th,
.table.table-primary > td {
  background-color: #b8c6d3;
}
.table-hover .table.table-primary:hover {
  background-color: #a8baca;
}
.table-hover .table.table-primary:hover > td,
.table-hover .table.table-primary:hover > th {
  background-color: #a8baca;
}
.table.table-secondary,
.table.table-secondary > th,
.table.table-secondary > td {
  background-color: #cacacd;
}
.table-hover .table.table-secondary:hover {
  background-color: #bcbdc0;
}
.table-hover .table.table-secondary:hover > td,
.table-hover .table.table-secondary:hover > th {
  background-color: #bcbdc0;
}
.table.table-success,
.table.table-success > th,
.table.table-success > td {
  background-color: #b8efe2;
}
.table-hover .table.table-success:hover {
  background-color: #a3ead9;
}
.table-hover .table.table-success:hover > td,
.table-hover .table.table-success:hover > th {
  background-color: #a3ead9;
}
.table.table-info,
.table.table-info > th,
.table.table-info > td {
  background-color: #b8dae5;
}
.table-hover .table.table-info:hover {
  background-color: #a6d0de;
}
.table-hover .table.table-info:hover > td,
.table-hover .table.table-info:hover > th {
  background-color: #a6d0de;
}
.table.table-warning,
.table.table-warning > th,
.table.table-warning > td {
  background-color: #ffe6cc;
}
.table-hover .table.table-warning:hover {
  background-color: #ffd9b3;
}
.table-hover .table.table-warning:hover > td,
.table-hover .table.table-warning:hover > th {
  background-color: #ffd9b3;
}
.table.table-danger,
.table.table-danger > th,
.table.table-danger > td {
  background-color: #ffd0d4;
}
.table-hover .table.table-danger:hover {
  background-color: #ffb7bc;
}
.table-hover .table.table-danger:hover > td,
.table-hover .table.table-danger:hover > th {
  background-color: #ffb7bc;
}
.table.table-light,
.table.table-light > th,
.table.table-light > td {
  background-color: #fcfcfd;
}
.table-hover .table.table-light:hover {
  background-color: #eef0f1;
}
.table-hover .table.table-light:hover > td,
.table-hover .table.table-light:hover > th {
  background-color: #eef0f1;
}
.table.table-dark,
.table.table-dark > th,
.table.table-dark > td {
  background-color: #c3c3c5;
}
.table-hover .table.table-dark:hover {
  background-color: #b6b6b8;
}
.table-hover .table.table-dark:hover > td,
.table-hover .table.table-dark:hover > th {
  background-color: #b6b6b8;
}
.table.table-active,
.table.table-active > th,
.table.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table.table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table.table-active:hover > td,
.table-hover .table.table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: white;
  background-color: #17171B;
  border-color: #292930;
}
.table .thead-light th {
  color: #333a46;
  background-color: #E8E9EB;
  border-color: rgba(151, 158, 168, 0.3);
}
.table-dark {
  color: white;
  background-color: #17171B;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #292930;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575px) {
  .table-responsive-xs {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xs > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media print {
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #C7CBCF !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: rgba(151, 158, 168, 0.3);
  }
  .table .thead-dark th {
    color: inherit;
    border-color: rgba(151, 158, 168, 0.3);
  }
}
.table-overflow-ellipsis {
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.table th.table-status-block,
.table td.table-status-block {
  width: 0.375rem;
  padding: 0;
  position: relative;
}
.table th.table-status-block > img,
.table td.table-status-block > img {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.admin-settings-toggle {
  position: relative;
  display: flex;
  justify-content: flex-end;
  top: 3px;
  z-index: 5;
}
.admin-settings-toggle .toggle-label {
  font-size: 1rem;
}
.admin-settings-toggle .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 23px;
}
.admin-settings-toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.admin-settings-toggle .switch input:checked + .slider {
  background-color: #003463;
}
.admin-settings-toggle .switch input:focus + .slider {
  box-shadow: 0 0 1px #003463;
}
.admin-settings-toggle .switch input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  transform: translateX(17px);
}
.admin-settings-toggle .switch input:disabled + .slider {
  background-color: #333333;
  cursor: not-allowed;
}
.admin-settings-toggle .switch input:disabled + .slider {
  box-shadow: 0 0 1px #333333;
}
.admin-settings-toggle .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 22.67px;
  background-color: #ccc;
  transition: 0.4s;
}
.admin-settings-toggle .switch .slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 3px;
  bottom: 3px;
  border-radius: 50%;
  background-color: white;
  transition: 0.4s;
}
.trend-arrow .is-Up {
  color: #00C596;
  border-color: #00C596;
}
.trend-arrow .is-Down {
  color: #FF5865;
  border-color: #FF5865;
}
.trend-arrow .is-Same {
  color: #ffa64a;
  border-color: #ffa64a;
}
.rbt {
  outline: none;
}
.rbt-menu {
  margin-bottom: 2px;
}
.rbt-menu > li a {
  overflow: hidden;
  text-overflow: ellipsis;
}
.rbt-menu > li a:focus {
  outline: none;
}
.rbt-menu-pagination-option {
  text-align: center;
}
.rbt .rbt-input-hint {
  background-position: -50px -50px !important;
  height: calc(2.5rem + 2px);
  width: 100%;
}
.rbt .rbt-input-multi .rbt-input-hint {
  height: 1.25rem;
  width: auto;
}
.rbt .rbt-menu .dropdown-item {
  color: rgba(51, 58, 70, 0.5);
  padding-left: 1rem;
  padding-right: 1rem;
}
.rbt .rbt-input-main::-ms-clear {
  display: none;
}
.rbt-input-multi {
  cursor: text;
  overflow: hidden;
  position: relative;
  height: auto;
}
.rbt-input-multi.focus {
  box-shadow: 0 0 8px rgba(102, 175, 233, 0.6), inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-color: #66afe9;
  outline: 0;
}
.rbt-input-multi.form-control[disabled] {
  background-color: #e9ecef;
  opacity: 1;
}
.rbt-input-multi input {
  /* autoprefixer: off */
}
.rbt-input-multi input::-webkit-input-placeholder {
  color: #999;
}
.rbt-input-multi input::placeholder {
  color: #999;
}
.rbt-input-multi .rbt-input-wrapper {
  margin-bottom: -4px;
  margin-top: -1px;
  overflow: hidden;
}
.rbt-input-multi .rbt-input-main {
  height: 20px;
  margin: 1px 0 4px;
}
.rbt-input-multi .rbt-input-hint-container {
  display: inline-block;
}
.rbt-input-multi.input-lg .rbt-input-main,
.rbt-input-multi.form-control-lg .rbt-input-main {
  height: 24px;
}
.rbt-input-multi.input-sm .rbt-input-main,
.rbt-input-multi.form-control-sm .rbt-input-main {
  height: 18px;
}
.rbt-close {
  z-index: 1;
}
.rbt-close-lg {
  font-size: 24px;
}
.rbt-token {
  background-color: #e7f4ff;
  border: 0;
  border-radius: 2px;
  color: #1f8dd6;
  display: inline-block;
  line-height: 1em;
  margin: 0 3px 3px 0;
  padding: 4px 7px;
  position: relative;
}
.rbt-token-disabled {
  background-color: #ddd;
  color: #888;
  pointer-events: none;
}
.rbt-token-removeable {
  cursor: pointer;
  padding-right: 21px;
}
.rbt-token-active {
  background-color: #1f8dd6;
  color: #fff;
  outline: none;
  text-decoration: none;
}
.rbt-token .rbt-token-remove-button {
  bottom: 0;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  opacity: 1;
  outline: none;
  padding: 3px 7px;
  position: absolute;
  right: 0;
  text-shadow: none;
  top: -2px;
  line-height: inherit;
}
.rbt-loader {
  -webkit-animation: loader-animation 600ms infinite linear;
          animation: loader-animation 600ms infinite linear;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  border-top-color: #1f8dd6;
  display: block;
  height: 16px;
  width: 16px;
}
.rbt-loader-lg {
  height: 20px;
  width: 20px;
}
.rbt-aux {
  display: flex;
  align-items: center;
  bottom: 0;
  justify-content: center;
  pointer-events: none;
  /* Don't block clicks on the input */
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
}
.rbt-aux-lg {
  width: 46px;
}
.rbt-aux .rbt-close {
  margin-top: -4px;
  pointer-events: auto;
  /* Override pointer-events: none; above */
}
.has-aux .rbt-input {
  padding-right: 34px;
}
.rbt-highlight-text {
  background-color: inherit;
  color: #333a46;
  padding: 0;
}
/* Input Groups */
.input-group > .rbt {
  flex: 1 1;
}
.input-group > .rbt .rbt-input-hint-container {
  display: flex;
}
.input-group > .rbt .rbt-input-hint {
  z-index: 5;
}
.input-group > .rbt:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .rbt:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
/* Validation States */
.has-error .rbt-input-multi.focus {
  border-color: #843534;
  box-shadow: 0 0 6px #ce8483, inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-warning .rbt-input-multi.focus {
  border-color: #66512c;
  box-shadow: 0 0 6px #c0a16b, inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-success .rbt-input-multi.focus {
  border-color: #2b542c;
  box-shadow: 0 0 6px #67b168, inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
@-webkit-keyframes loader-animation {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
@keyframes loader-animation {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
.rbt-input-multi.focus {
  background-color: #fff;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  color: #495057;
  outline: 0;
}
.rbt-input-multi.is-invalid.focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.rbt-input-multi.is-valid.focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.rbt-input-multi input {
  /* autoprefixer: off */
}
.rbt-input-multi input::-webkit-input-placeholder {
  color: #6c757d;
}
.rbt-input-multi input::placeholder {
  color: #6c757d;
}
.rbt-input-multi .rbt-input-main {
  height: auto;
}
.rbt-input-multi.form-control-lg .rbt-input-main {
  height: auto;
}
.rbt-input-multi.form-control-sm .rbt-input-main {
  height: auto;
}
.input-group > .rbt .rbt-input-hint-container {
  flex: 1 1;
  height: 100%;
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  word-wrap: break-word;
  pointer-events: none;
  transition: opacity 0.2s;
}
.is-touch-first .tooltip {
  display: none;
}
.tooltip,
.tooltip.show.is-hiding {
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 14px;
  height: 7px;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 7px 0;
}
.bs-tooltip-top .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 7px 7px 0;
  border-top-color: rgba(64, 66, 75, 0.94);
}
.bs-tooltip-top,
.bs-tooltip-top.show.is-hiding,
.bs-tooltip-auto[x-placement^="top"] {
  -webkit-animation: tooltip-slide-down-out 0.2s;
          animation: tooltip-slide-down-out 0.2s;
}
@-webkit-keyframes tooltip-slide-up-in {
  from {
    top: 0.25rem;
  }
  to {
    top: 0;
  }
}
@keyframes tooltip-slide-up-in {
  from {
    top: 0.25rem;
  }
  to {
    top: 0;
  }
}
.bs-tooltip-top.show {
  -webkit-animation: tooltip-slide-up-in 0.2s;
          animation: tooltip-slide-up-in 0.2s;
}
@-webkit-keyframes tooltip-slide-down-out {
  from {
    top: 0;
  }
  to {
    top: 0.25rem;
  }
}
@keyframes tooltip-slide-down-out {
  from {
    top: 0;
  }
  to {
    top: 0.25rem;
  }
}
.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 7px;
}
.bs-tooltip-right .arrow {
  left: 0;
  width: 7px;
  height: 14px;
}
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 7px 7px 7px 0;
  border-right-color: rgba(64, 66, 75, 0.94);
}
.bs-tooltip-right,
.bs-tooltip-right.show.is-hiding,
.bs-tooltip-auto[x-placement^="right"] {
  -webkit-animation: tooltip-slide-left-out 0.2s;
          animation: tooltip-slide-left-out 0.2s;
}
@-webkit-keyframes tooltip-slide-left-out {
  from {
    left: 0;
  }
  to {
    left: -0.25rem;
  }
}
@keyframes tooltip-slide-left-out {
  from {
    left: 0;
  }
  to {
    left: -0.25rem;
  }
}
.bs-tooltip-right.show {
  -webkit-animation: tooltip-slide-right-in 0.2s;
          animation: tooltip-slide-right-in 0.2s;
}
@-webkit-keyframes tooltip-slide-right-in {
  from {
    left: -0.25rem;
  }
  to {
    left: 0;
  }
}
@keyframes tooltip-slide-right-in {
  from {
    left: -0.25rem;
  }
  to {
    left: 0;
  }
}
.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 7px 0;
}
.bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 7px 7px;
  border-bottom-color: rgba(64, 66, 75, 0.94);
}
.bs-tooltip-bottom,
.bs-tooltip-bottom.show.is-hiding,
.bs-tooltip-auto[x-placement^="bottom"] {
  -webkit-animation: tooltip-slide-up-out 0.2s;
          animation: tooltip-slide-up-out 0.2s;
}
@-webkit-keyframes tooltip-slide-up-out {
  from {
    top: 0;
  }
  to {
    top: -0.25rem;
  }
}
@keyframes tooltip-slide-up-out {
  from {
    top: 0;
  }
  to {
    top: -0.25rem;
  }
}
.bs-tooltip-bottom.show {
  -webkit-animation: tooltip-slide-down-in 0.2s;
          animation: tooltip-slide-down-in 0.2s;
}
@-webkit-keyframes tooltip-slide-down-in {
  from {
    top: -0.25rem;
  }
  to {
    top: 0;
  }
}
@keyframes tooltip-slide-down-in {
  from {
    top: -0.25rem;
  }
  to {
    top: 0;
  }
}
.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 7px;
}
.bs-tooltip-left .arrow {
  right: 0;
  width: 7px;
  height: 14px;
}
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 7px 0 7px 7px;
  border-left-color: rgba(64, 66, 75, 0.94);
}
.bs-tooltip-left,
.bs-tooltip-left.show.is-hiding,
.bs-tooltip-auto[x-placement^="left"] {
  -webkit-animation: tooltip-slide-right-out 0.2s;
          animation: tooltip-slide-right-out 0.2s;
}
@-webkit-keyframes tooltip-slide-right-out {
  from {
    left: 0;
  }
  to {
    left: 0.25rem;
  }
}
@keyframes tooltip-slide-right-out {
  from {
    left: 0;
  }
  to {
    left: 0.25rem;
  }
}
.bs-tooltip-left.show {
  -webkit-animation: tooltip-slide-left-in 0.2s;
          animation: tooltip-slide-left-in 0.2s;
}
@-webkit-keyframes tooltip-slide-left-in {
  from {
    left: 0.25rem;
  }
  to {
    left: 0;
  }
}
@keyframes tooltip-slide-left-in {
  from {
    left: 0.25rem;
  }
  to {
    left: 0;
  }
}
.tooltip-inner {
  max-width: 280px;
  padding: 0.5rem 1rem;
  color: #C7CBCF;
  text-align: center;
  background-color: rgba(64, 66, 75, 0.94);
  border-radius: 5px;
}
.tooltip-header {
  color: white;
  font-size: 0.875rem;
}
.tooltip-info {
  color: #E5E5E5;
  cursor: help;
}
.value-list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 16px;
}
.value-list-item-inner {
  border-bottom: 1px solid rgba(64, 66, 75, 0.1);
  display: flex;
  position: relative;
  padding: 10px 0;
}
.value-list-item-inner > * {
  transition: color 0.2s;
}
@media screen and (prefers-reduced-motion: reduce) {
  .value-list-item-inner > * {
    transition: none;
  }
}
.value-list-item-color {
  width: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transition: width 0.2s;
}
@media screen and (prefers-reduced-motion: reduce) {
  .value-list-item-color {
    transition: none;
  }
}
.value-list-item-label {
  display: block;
  flex: 1 1 auto;
  padding-right: 18px;
  color: #636974;
  font-weight: 400;
}
.value-list.has-colors .value-list-item-label {
  padding-left: 18px;
}
.value-list-item-value {
  display: block;
  flex: 0 0 auto;
  color: #333a46;
  font-weight: 700;
}
.value-list-item-inner:hover,
.value-list-item-inner.is-hovered {
  text-decoration: none;
}
.value-list-item-inner:hover .value-list-item-color,
.value-list-item-inner.is-hovered .value-list-item-color {
  width: 7px;
}
.value-list-item-inner:hover .value-list-item-label,
.value-list-item-inner.is-hovered .value-list-item-label {
  font-weight: 500;
}
.value-list-item-inner.is-link:hover > *,
.value-list-item-inner.is-link.is-hovered > * {
  color: #027AA2;
}
.value-list-item.is-selected .value-list-item-color {
  width: 7px;
}
.value-list-item.is-selected .value-list-item-label {
  color: #333a46;
  font-weight: 500;
}
@media (max-width: 991px) {
  .value-list.is-responsive {
    font-size: 12px;
    line-height: 12px;
  }
  .value-list.is-responsive .value-list-item-inner {
    padding: 8px 0;
  }
  .value-list.is-responsive .value-list-item-label {
    padding-right: 14px;
  }
  .value-list.is-responsive.has-colors .value-list-item-label {
    padding-left: 14px;
  }
}
.well {
  background-color: white;
  padding: 1.25rem 1.5rem;
}
.well.is-form-like {
  background-color: #E8E9EB;
  border: 1px solid #C7CBCF;
}
/*
* Copied from react-bootstrap-table-next/dist/react-bootstrap-table2.css
*/
.react-bootstrap-table table {
  table-layout: fixed;
  margin-bottom: 2rem;
}
.react-bootstrap-table th[data-row-selection] {
  width: 30px;
}
.react-bootstrap-table td.react-bs-table-no-data {
  text-align: center;
}
.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.bounceIn,
.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.bounceOut {
  -webkit-animation-duration: 0.75s;
          animation-duration: 0.75s;
}
.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.shake {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
@-webkit-keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  10%,
  50%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }
  30%,
  70% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
}
@keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  10%,
  50%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }
  30%,
  70% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
}
.react-bootstrap-table td.react-bootstrap-table-editing-cell .shake {
  -webkit-animation-name: shake;
          animation-name: shake;
}
@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
            transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
            transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
.react-bootstrap-table td.react-bootstrap-table-editing-cell .bounceIn {
  -webkit-animation-name: bounceIn;
          animation-name: bounceIn;
}
@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
}
@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
}
.react-bootstrap-table td.react-bootstrap-table-editing-cell .bounceOut {
  -webkit-animation-name: bounceOut;
          animation-name: bounceOut;
}
/*
* Copied from react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.css
*/
.react-bootstrap-table-page-btns-ul {
  float: right;
  margin-top: 0px;
}
.react-bootstrap-table-pagination-list-hidden {
  display: none;
}
.react-bootstrap-table thead th.sortable {
  cursor: pointer;
}
.react-bootstrap-table thead th.sortable .order,
.react-bootstrap-table thead th.sortable .react-bootstrap-table-sort-order,
.react-bootstrap-table thead th.sortable .caret,
.react-bootstrap-table thead th.sortable .dropdown,
.react-bootstrap-table thead th.sortable .dropup {
  vertical-align: baseline;
}
.react-bootstrap-table thead th.sortable .order,
.react-bootstrap-table thead th.sortable .react-bootstrap-table-sort-order {
  margin-left: 0.35em;
}
.react-bootstrap-table thead th.sortable .caret {
  background: 0 0;
  background-size: contain;
  background-image: url(/static/media/angle-down-light.39b15a48.svg);
  opacity: 0.3;
  margin-bottom: -0.125em;
}
.react-bootstrap-table thead th.sortable .dropup .caret {
  background-image: url(/static/media/angle-up-light.63ac809a.svg);
}
.react-bootstrap-table thead th.sortable .react-bootstrap-table-sort-order .caret {
  opacity: 1;
}
.react-bootstrap-table,
.react-bootstrap-table-pagination {
  margin-bottom: 4rem;
}
.react-bs-table-sizePerPage-dropdown {
  display: inline-block;
}
.react-bs-table-sizePerPage-dropdown .dropdown-menu {
  min-width: 0;
  width: 100%;
}
.react-bs-table-sizePerPage-dropdown .dropdown-item {
  padding: 0;
}
.react-bs-table-sizePerPage-dropdown .dropdown-item a {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #636974;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.react-bs-table-sizePerPage-dropdown .dropdown-item a:hover,
.react-bs-table-sizePerPage-dropdown .dropdown-item a:focus {
  color: #40424B;
  text-decoration: none;
  background: #F5F6F7;
}
.react-bs-table-sizePerPage-dropdown .dropdown-item a.active,
.react-bs-table-sizePerPage-dropdown .dropdown-item a:active {
  color: #fff;
  text-decoration: none;
  background: #027AA2;
}
.react-bs-table-sizePerPage-dropdown .dropdown-item a.disabled,
.react-bs-table-sizePerPage-dropdown .dropdown-item a:disabled {
  color: #C7CBCF;
  background-color: transparent;
  background-image: none;
}
.react-bootstrap-table-pagination-total {
  color: #636974;
  font-size: 0.875rem;
  margin-left: 1rem;
  display: inline-block;
}
.react-bootstrap-table-page-btns-ul [title="last page"] {
  display: none;
}
.react-bootstrap-per-page-list {
  border: none;
  background: #fff;
  font-size: 0.875rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
  text-align: left;
  color: #636974;
}
.react-bootstrap-per-page {
  display: flex;
  flex-direction: column;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.caret {
  position: relative;
  top: -1px;
  left: 4px;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  top: -5px;
  left: 6px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  content: "";
}
.dashboard-header-container {
  margin-top: 1.875rem;
  height: 5rem;
  border-radius: 4px 4px 0px 0px;
  color: #ffffff;
}
.dashboard-header-container .icon-container {
  margin-left: 2.125rem;
}
.dashboard-header-container .icon-container .icon {
  font-size: 1.75rem;
}
.dashboard-header-container .header-title {
  margin-left: 2.25rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.55rem;
  line-height: 1.625rem;
}
.dashboard-header-container .stats-container .label {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.625rem;
  margin-right: 0.688rem;
}
.dashboard-header-container .stats-container .score-container {
  width: 10rem;
}
.dashboard-header-container .stats-container .score {
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 3.188rem;
}
.dashboard-header-container .stats-container .ordinal {
  margin: 0.938rem 0.188rem 0 0;
  font-size: 1.125rem;
}
.dashboard-header-container .toggle-icon-container .toggle-icon {
  font-size: 1.5rem;
  color: #ffffff;
}
.medal-description-container {
  grid-gap: 2.188rem;
  gap: 2.188rem;
  padding: 0 3.75rem 3.75rem 3.75rem;
}
@supports (-webkit-appearance: none) {
  .medal-description-container > *:not(:last-child) {
    margin-right: 2.188rem;
  }
}
.medal-description {
  max-width: 11.25rem;
}
.medal-description .medal-description-image {
  margin: 3.438rem auto 1.5rem auto;
}
.medal-description .medal-description-image img {
  width: 6.438rem;
  height: 6.438rem;
}
.medal-description .medal-description-title {
  margin-bottom: 0.875rem;
}
.medal-description .medal-description-title span {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.438rem;
  color: #636363;
  margin: 0;
}
.medal-description .medal-description-summary {
  font-size: 0.875rem;
  line-height: 1.125rem;
  padding: 0 0.278rem;
}
.badge-display-container {
  margin-bottom: 3.438rem;
}
.badge-display-container .badge-display-container-title {
  margin: 3.5rem 6rem 0 6rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #d9d9d9;
}
.badge-display-container .badge-display-container-title span {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.438rem;
  text-transform: uppercase;
}
.badge-display-container .badge-header-icon-container {
  padding: 0 1.5rem 0 0;
}
.badge-display-container .badge-header-icon {
  font-size: 1.25rem;
}
.badge-display-container .badge-header-text {
  line-height: 1.5rem;
}
.badge-display-container .badge-display-image-container {
  margin: 2.125rem 2.125rem 0 0;
}
.badge-display-container .badge-display-image-container .badge-image {
  width: 6.438rem;
  height: 6.438rem;
}
.badge-display-container .badge-row {
  margin-left: 6rem;
  max-width: 43.75rem;
}
.boolean-card-container {
  min-width: 22.25rem;
  height: 14rem;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1.25rem 1.875rem;
}
.boolean-card-container .boolean-card-icon-wrapper {
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  background: #34b991;
}
.boolean-card-container .boolean-card-icon-container .icon {
  color: #fff;
  font-size: 1.625rem;
}
.boolean-card-container .boolean-card-header-icon {
  height: 2.75rem;
  width: 2.75rem;
  border-radius: 50%;
}
.boolean-card-container .boolean-card-title {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.625rem;
  max-width: 14.813rem;
  height: 2.75rem;
  padding-left: 1.5rem;
}
.boolean-card-container .boolean-card-value-container {
  margin-bottom: 0.813rem;
}
.boolean-card-container .boolean-card-h-border {
  height: 0;
  margin: 1.125rem 0 1rem 0;
  border-bottom: 1px solid #e5e5e5;
}
.boolean-card-container .boolean-card-v-border {
  width: 0;
  margin: 0 1.688rem;
  border-right: 1px solid #e5e5e5;
}
.boolean-card-container .boolean-card-stats .stat-section {
  padding-bottom: 0.438rem;
}
.boolean-card-container .boolean-card-stats .stats-percentage {
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.625rem;
  text-transform: uppercase;
}
.boolean-card-container .boolean-card-stats .stats-label {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #636363;
}
.boolean-card-container .boolean-card-stats .stats-value {
  font-style: normal;
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 2.25rem;
  color: #636363;
}
.boolean-card-container .boolean-card-stats .stats-footer {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #b1b1b1;
}
.numerical-card-container {
  min-width: 22.25rem;
  height: 14rem;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1.25rem 1.875rem;
}
.numerical-card-container .numerical-card-icon-wrapper {
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  background: #34b991;
}
.numerical-card-container .numerical-card-icon-container {
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  background: #34b991;
}
.numerical-card-container .numerical-card-icon-container .icon {
  color: #fff;
  font-size: 1.625rem;
}
.numerical-card-container .numerical-card-header-icon {
  height: 2.75rem;
  width: 2.75rem;
  border-radius: 50%;
}
.numerical-card-container .numerical-card-title {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.625rem;
  max-width: 14.813rem;
  padding-left: 1.5rem;
}
.numerical-card-container .numerical-card-border {
  height: 0;
  margin: 1.125rem 0 2.5rem 0;
  border-bottom: 1px solid #e5e5e5;
}
.numerical-card-container .numerical-card-footer {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #b1b1b1;
}
.numerical-card-container .numerical-card-percentage {
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  color: #636363;
}
.numerical-card-container .numerical-card-value-text {
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  color: #636363;
}
.numerical-card-container .numerical-increase-icon {
  margin-right: 0.625rem;
  font-size: 1.4rem;
}
.managers-table-container {
  border: 1px solid #C7CBCF;
}
.gender-text {
  text-align: center;
  color: #027AA2;
}
.year-text {
  text-align: center;
  color: #027AA2;
  font-weight: bold;
}
.table-header {
  text-align: center;
  padding: 10px;
}
.h-80px {
  height: 80px;
}
.h-30px {
  height: 30px;
}
.managers-profile-input {
  width: 40px;
  height: 30px;
  border-color: #C7CBCF;
  border-radius: 4px;
  text-align: center;
}
.transparent-border {
  border: 1px solid transparent;
}
.pdf-render-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 8.5in;
  height: 11in;
  background-color: white;
  border: 3px solid black;
  opacity: 0;
}
.permission-card:nth-of-type(1) {
  margin-top: 2rem;
  border-top: 1px solid #E8E9EB;
}
.permission-card {
  border-bottom: 1px solid #E8E9EB;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.permission-card .permission-card-content {
  margin: 1rem 0 1.4rem;
}
.permission-card .permission-card-header {
  min-height: 3rem;
  font-size: 0.875rem;
}
.permission-card .permission-card-name {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  color: #003463;
  font-size: 1.8125rem;
  line-height: 2.625rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 1.1rem;
  letter-spacing: normal;
  padding-right: 1rem;
}
.permission-card .permission-card-name b,
.permission-card .permission-card-name strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.permission-card .permission-card-body p {
  margin-bottom: 0;
}
.bar-chart-panel {
  position: relative;
  border: 2px solid #F5F6F7;
}
.bar-chart-panel .title-box {
  height: 60px;
  line-height: 30px;
  width: 100%;
  background-color: #F5F6F7;
  font-size: 18px !important;
}
.bar-chart-panel .content {
  position: absolute;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
}
.bar-chart-panel .content img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.holdings-performance {
  margin-top: 50px;
}
.holdings-performance h3 {
  font-size: 16px;
}
.canvas-container {
  width: 950px;
  height: 250px;
}
.marker {
  display: inline-block;
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 8px;
}
.cover-page-container {
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
}
.cover-page-title-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 130px;
  height: 100%;
  width: 500px;
  padding-bottom: 100px;
}
.cover-page-company-name-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: #fff;
  max-width: 100%;
  margin-bottom: 60px;
  font-family: futura-pt;
  font-size: 40px;
  font-weight: bold;
}
.cover-page-progress-text {
  color: #fff;
  font-family: futura-pt;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 0;
}
.cover-page-title-block {
  height: 100%;
  width: 625px;
  background: rgba(3, 70, 130);
  margin-left: 64px;
  opacity: 0.9;
}
.cover-page-quarter-text {
  color: #34B991;
  font-family: futura-pt;
  font-weight: bold;
  font-size: 35px;
  margin-top: 0;
}
.cover-page-logo-img-style {
  top: 140px;
  left: 180px;
  width: 350px;
  height: 100px;
  position: absolute;
}
.cover-page-watermark-img {
  top: 300px;
  left: 50px;
  width: 900px;
  height: 900px;
  position: absolute;
}
.back-page-container {
  height: 650px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.back-page-logo-styles {
  margin-top: 600px;
  width: 350px;
  height: 100px;
}
.back-page-footer-text {
  margin-top: 655px;
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
}
.back-page-footer-text b,
.back-page-footer-text strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.back-page-bg-container {
  background: #034682;
}
.report-page-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.report-page-container .report-page-header,
.report-page-container .report-page-header-2 {
  padding: 25mm 21mm 0;
}
.report-page-container .report-page-header .report-page-title,
.report-page-container .report-page-header-2 .report-page-title {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  font-weight: 450 !important;
  font-style: normal;
  font-size: 16px;
  line-height: 17px;
  text-transform: uppercase;
}
.report-page-container .report-page-header .report-page-title b,
.report-page-container .report-page-header-2 .report-page-title b,
.report-page-container .report-page-header .report-page-title strong,
.report-page-container .report-page-header-2 .report-page-title strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.report-page-container .report-page-header .report-page-quarter,
.report-page-container .report-page-header-2 .report-page-quarter {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  color: #00C596;
}
.report-page-container .report-page-header .report-page-header-blue,
.report-page-container .report-page-header-2 .report-page-header-blue {
  color: #003463;
}
.report-page-container .report-page-header-2 {
  padding: 25mm 21mm 21mm;
}
.report-page-container .report-page-header-2 span::before {
  content: "";
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-right: 10px;
  background-color: #003463;
}
.report-page-container .text-overflow-ellipsis-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.report-page-container .text-overflow-ellipsis-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.report-page-container .text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 14;
  -webkit-box-orient: vertical;
}
.report-page-container .report-page-content {
  padding: 0 21mm;
}
.report-page-container .report-page-content h2 {
  font-family: 'futura-pt', sans-serif;
  font-weight: 600;
  font-size: 24px !important;
  color: #003463;
}
.report-page-container .report-page-content h2 b,
.report-page-container .report-page-content h2 strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.report-page-container .report-page-content h3 {
  font-family: 'futura-pt', sans-serif;
}
.report-page-container .report-page-content span {
  font-family: 'futura-pt', sans-serif;
}
.report-page-container .report-page-footer {
  padding: 0 21mm 21mm;
  font-size: 14px;
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
}
.report-page-container .report-page-footer b,
.report-page-container .report-page-footer strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.report-page-container .report-page-footer .report-page-quarter {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
  color: #00C596;
}
.report-page-container .report-page-footer .icon-container {
  width: 120px;
}
.report-page-container .report-page-footer .icon-container .icon {
  height: 22px;
  width: auto;
}
.report-page-container .report-page-issue-matrix .issue-grid-group {
  border: none;
}
.report-page-container .report-page-issue-matrix .issue-grid-group .col {
  border-left: none;
  border-right: none;
  font-size: 16px;
  font-style: normal;
  flex: 1 0 10%;
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
}
.report-page-container .report-page-issue-matrix .issue-grid-group .col b,
.report-page-container .report-page-issue-matrix .issue-grid-group .col strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.report-page-container .report-page-issue-matrix .issue-grid-group > div .col-title {
  border-left: none;
  flex: 1 0 50%;
}
.report-page-container .report-page-issue-matrix .col-group.table-heading .col {
  background-color: #F5F6F7;
  border-bottom: none;
  border-right: 5px solid #fff;
}
.report-page-container .report-page-issue-matrix .col-group.table-heading .col-title {
  font-weight: 600 !important;
}
.company-engagement-page table {
  border-collapse: separate;
  border-spacing: 10px 7px;
}
.company-engagement-page td {
  font-family: 'futura-pt', sans-serif;
}
.company-engagement-page td:not(:first-child) {
  text-align: center;
}
.company-engagement-page thead {
  font-family: 'futura-pt', sans-serif;
  font-weight: 600;
}
.company-engagement-page thead b,
.company-engagement-page thead strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.company-engagement-page thead td {
  height: 0.7in !important;
}
.company-engagement-page thead td:not(:first-child) {
  text-transform: uppercase !important;
  font-size: 14px;
}
.company-engagement-page tr.data {
  height: 0.4in;
}
.company-engagement-page tr.data td {
  background-color: #F5F6F7 !important;
}
.company-engagement-page tr.total {
  font-family: 'futura-pt', sans-serif;
  font-weight: 600;
  height: 0.4in;
}
.company-engagement-page tr.total b,
.company-engagement-page tr.total strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.company-engagement-page tr.total td {
  background-color: #CCCCCC !important;
}
.summary-page .stat-column {
  width: 2.5in;
}
.summary-page .stat-column .stat-block {
  height: 1.5in;
}
.summary-page .stat-column .stat-block h3 {
  font-weight: 400 !important;
  font-size: 28px;
  margin-bottom: 10px;
}
.summary-page .stat-column .stat-block span {
  font-size: 20px;
}
.report-holdings-table-container table.table thead tr.headers {
  text-transform: uppercase;
  border-bottom: 1px solid #000;
  border-left: 10px solid #fff;
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
}
.report-holdings-table-container table.table thead tr.headers b,
.report-holdings-table-container table.table thead tr.headers strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.report-holdings-table-container table.table thead tr.spacing {
  height: 20px;
}
.report-holdings-table-container table.table tbody tr td {
  border-top: none;
  font-weight: lighter !important;
  color: #000 !important;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
}
.report-holdings-table-container table.table tbody tr td b,
.report-holdings-table-container table.table tbody tr td strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.report-holdings-table-container table.table tbody tr td:first-of-type {
  padding-left: 20px;
}
.report-holdings-table-container table.table tbody tr td:last-of-type {
  padding-right: 20px;
}
.report-holdings-table-container table.table tbody tr:nth-of-type(odd) td {
  background-color: #F5F6F7 !important;
}
.report-holdings-table-container .company-name-container {
  display: block;
  width: 13rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.bar-graph-container-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.esg-measures-page th,
.esg-measures-page td {
  font-family: 'futura-pt', sans-serif;
}
.esg-measure-table-header {
  padding-bottom: 15px;
  padding-left: 10px;
  font-weight: 600;
  text-transform: uppercase;
}
.esg-measure-table-header span {
  text-transform: lowercase;
}
.esg-measure-row {
  padding: 10px;
}
.esg-measure-row.color {
  background: #F5F6F7;
}
.report-page-container .report-page-esg-rating-header {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  font-weight: 600 !important;
  font-style: normal;
}
.report-page-container .report-page-esg-rating-header b,
.report-page-container .report-page-esg-rating-header strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.report-page-container .report-page-esg-rating-header.green {
  border-bottom-color: #00C596;
}
.report-page-container .report-page-esg-rating-header.blue {
  border-bottom-color: #00A7BF;
}
.report-page-container .report-page-esg-rating-header.navy {
  border-bottom-color: #003463;
}
.report-page-container .report-page-esg-issue-header {
  display: block;
  border-bottom: 1px solid #000;
}
.report-page-container .report-page-esg-rating-summary .report-page-esg-rating-score {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  color: #434343;
  font-size: 22px;
  font-weight: 600;
  background-color: #eee;
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
}
.report-page-container .report-page-esg-rating-summary .report-page-esg-rating-score b,
.report-page-container .report-page-esg-rating-summary .report-page-esg-rating-score strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.report-page-container .report-page-esg-rating-summary .report-page-esg-rating-score.green {
  background-color: rgba(52, 185, 145, 0.2);
}
.report-page-container .report-page-esg-rating-summary .report-page-esg-rating-score.red {
  background-color: rgba(232, 0, 0, 0.2);
}
.report-page-container .report-page-esg-rating-summary .strong {
  font-size: 18px;
  font-weight: 400;
}
.report-page-container .report-page-esg-rating-summary .report-page-esg-rating-desc {
  flex: 0 1 65%;
}
.report-page-container .report-page-esg-rating-summary .report-page-esg-rating-bullets {
  flex: 0 1 28%;
}
.manager-page-chart-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 100px;
}
.manager-page-chart-card-container {
  display: flex;
  flex-direction: row;
  background: #F5F6F7;
  padding: 15px;
  border-radius: 10px;
  width: 310px;
}
.manager-page-chart-card-container .manager-page-chart-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.manager-page-circle {
  width: 80px;
  height: 80px;
  background: #00446C;
  border-radius: 40px;
  margin-right: 20px;
}
.manager-page-chart-text {
  margin: 0;
  font-size: 16px;
  font-family: futura-pt;
}
.manager-page-table-header {
  padding-bottom: 15px;
  font-size: 14px;
  font-family: futura-pt;
  font-weight: 600;
  font-style: normal;
}
.manager-page-cell {
  padding: 10px;
  font-size: 12px;
  font-family: futura-pt;
}
.manager-page-row:nth-child(2n) {
  background: #F5F6F7;
}
.table-contents-report-styles {
  margin-bottom: 0;
  font-family: futura-pt;
}
.table-contents-list-items-styles {
  font-size: 20px;
  color: rgba(67, 67, 67, 0.9);
  font-family: futura-pt;
  font-style: normal;
  margin-bottom: 20px;
  font-weight: 400px;
}
.table-contents-number-items-styles {
  font-size: 25px;
  color: rgba(67, 67, 67, 0.9);
  font-family: futura-pt;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.5;
}
.table-contents-title-items-styles {
  font-size: 20px;
  color: rgba(67, 67, 67, 0.9);
  font-family: futura-pt;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 600;
}
.table-contents-company-name-styles {
  font-size: 45px;
  margin-bottom: 0;
  font-family: futura-pt;
  font-weight: bold;
}
.table-contents-section-font-styles {
  font-size: 40px;
  font-family: futura-pt;
  color: rgba(67, 67, 67, 0.9);
  font-style: normal;
  font-weight: 300;
}
.table-contents-body-section {
  padding-top: 250px;
  padding-bottom: 75px;
}
.main-text-font-styles1 {
  font-family: futura-pt;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  line-height: 12.66px;
  margin-bottom: 5;
  color: rgba(67, 67, 67, 0.9);
}
.main-text-font-styles2 {
  font-family: futura-pt;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  line-height: 15.19px;
  color: rgba(67, 67, 67, 0.9);
}
.top-section-container {
  display: flex;
}
.top-section-container .grid-container {
  width: 100% !important;
  display: grid;
  grid-template-columns: 1.25fr 1fr 1fr 1.5fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-columns: auto;
  grid-gap: 0 2rem;
  gap: 0 2rem;
  grid-template-areas: "product product product product" "portfolio-value effective-date . score" "strategy benchmark . vs-benchmark";
}
.top-section-container .grid-container .grid-col {
  height: 5rem;
  overflow: visible;
}
.top-section-container .col-overflow {
  height: 1rem;
  overflow-y: visible;
}
.ratings-style {
  width: 45px;
  height: 45px;
  line-height: 12px;
  border-radius: 50%;
  border: 2.5px solid;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  display: flex;
  padding-right: 0.5px;
}
.bench-mark {
  margin-left: 6px;
  display: inline-block;
}
.esg-headers {
  color: #434343;
  font-weight: 600;
  margin-bottom: 20px;
}
.esg-header-no-margin {
  color: #434343;
  font-weight: 600;
}
.line-styles {
  margin-left: 20px;
  margin-right: 20px;
}
.forty-seven-percent {
  width: 47%;
}
.negative-margin-twenty-five {
  margin-bottom: -25px;
}
.margin-t-thirty {
  margin-top: 30px;
}
.margin-b-twenty {
  margin-bottom: 20px;
}
.margin-b-thirty {
  margin-bottom: 30;
}
.good-color {
  color: #34B991;
}
.bad-color {
  color: #FD0000;
}
.neutral-color {
  color: #434343;
}
.bg-black {
  background-color: #000 !important;
}
a.bg-black:hover,
button.bg-black:hover,
a.bg-black:focus,
button.bg-black:focus {
  background-color: #000000 !important;
}
.bg-almost-black {
  background-color: #17171B !important;
}
a.bg-almost-black:hover,
button.bg-almost-black:hover,
a.bg-almost-black:focus,
button.bg-almost-black:focus {
  background-color: #000000 !important;
}
.bg-darkest-gray {
  background-color: #28292F !important;
}
a.bg-darkest-gray:hover,
button.bg-darkest-gray:hover,
a.bg-darkest-gray:focus,
button.bg-darkest-gray:focus {
  background-color: #111113 !important;
}
.bg-darker-gray {
  background-color: #333a46 !important;
}
a.bg-darker-gray:hover,
button.bg-darker-gray:hover,
a.bg-darker-gray:focus,
button.bg-darker-gray:focus {
  background-color: #1e2228 !important;
}
.bg-dark-gray {
  background-color: #40424B !important;
}
a.bg-dark-gray:hover,
button.bg-dark-gray:hover,
a.bg-dark-gray:focus,
button.bg-dark-gray:focus {
  background-color: #292a2f !important;
}
.bg-gray {
  background-color: #636974 !important;
}
a.bg-gray:hover,
button.bg-gray:hover,
a.bg-gray:focus,
button.bg-gray:focus {
  background-color: #4c5058 !important;
}
.bg-light-gray {
  background-color: #979EA8 !important;
}
a.bg-light-gray:hover,
button.bg-light-gray:hover,
a.bg-light-gray:focus,
button.bg-light-gray:focus {
  background-color: #7b8491 !important;
}
.bg-lighter-gray {
  background-color: #C7CBCF !important;
}
a.bg-lighter-gray:hover,
button.bg-lighter-gray:hover,
a.bg-lighter-gray:focus,
button.bg-lighter-gray:focus {
  background-color: #acb2b7 !important;
}
.bg-lightest-gray {
  background-color: #E8E9EB !important;
}
a.bg-lightest-gray:hover,
button.bg-lightest-gray:hover,
a.bg-lightest-gray:focus,
button.bg-lightest-gray:focus {
  background-color: #cdcfd3 !important;
}
.bg-almost-white {
  background-color: #F5F6F7 !important;
}
a.bg-almost-white:hover,
button.bg-almost-white:hover,
a.bg-almost-white:focus,
button.bg-almost-white:focus {
  background-color: #d9dde0 !important;
}
.bg-white {
  background-color: #fff !important;
}
a.bg-white:hover,
button.bg-white:hover,
a.bg-white:focus,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}
.bg-red {
  background-color: #FF5865 !important;
}
a.bg-red:hover,
button.bg-red:hover,
a.bg-red:focus,
button.bg-red:focus {
  background-color: #ff2536 !important;
}
.bg-yellow {
  background-color: #FFF32F !important;
}
a.bg-yellow:hover,
button.bg-yellow:hover,
a.bg-yellow:focus,
button.bg-yellow:focus {
  background-color: #fbed00 !important;
}
.bg-green {
  background-color: #00C596 !important;
}
a.bg-green:hover,
button.bg-green:hover,
a.bg-green:focus,
button.bg-green:focus {
  background-color: #00926f !important;
}
.bg-blue {
  background-color: #027AA2 !important;
}
a.bg-blue:hover,
button.bg-blue:hover,
a.bg-blue:focus,
button.bg-blue:focus {
  background-color: #015470 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-off-white {
  background-color: #eff0f1;
}
.bg-light-blue {
  background-color: rgba(2, 122, 162, 0.15);
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-navy-green-gradient {
  background-image: linear-gradient(to bottom, #003463 0%, #007d7d 100%);
  background-repeat: repeat-x;
}
.bg-light-blue-pulse {
  -webkit-animation: bg-light-blue-pulse 2s 1s forwards;
          animation: bg-light-blue-pulse 2s 1s forwards;
}
@-webkit-keyframes bg-light-blue-pulse {
  10% {
    background-color: rgba(2, 122, 162, 0.3);
  }
  100% {
    background-color: rgba(2, 122, 162, 0.1);
  }
}
@keyframes bg-light-blue-pulse {
  10% {
    background-color: rgba(2, 122, 162, 0.3);
  }
  100% {
    background-color: rgba(2, 122, 162, 0.1);
  }
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm\-none {
    display: none !important;
  }
  .d-sm\-inline {
    display: inline !important;
  }
  .d-sm\-inline-block {
    display: inline-block !important;
  }
  .d-sm\-block {
    display: block !important;
  }
  .d-sm\-table {
    display: table !important;
  }
  .d-sm\-table-row {
    display: table-row !important;
  }
  .d-sm\-table-cell {
    display: table-cell !important;
  }
  .d-sm\-flex {
    display: flex !important;
  }
  .d-sm\-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md\-none {
    display: none !important;
  }
  .d-md\-inline {
    display: inline !important;
  }
  .d-md\-inline-block {
    display: inline-block !important;
  }
  .d-md\-block {
    display: block !important;
  }
  .d-md\-table {
    display: table !important;
  }
  .d-md\-table-row {
    display: table-row !important;
  }
  .d-md\-table-cell {
    display: table-cell !important;
  }
  .d-md\-flex {
    display: flex !important;
  }
  .d-md\-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg\-none {
    display: none !important;
  }
  .d-lg\-inline {
    display: inline !important;
  }
  .d-lg\-inline-block {
    display: inline-block !important;
  }
  .d-lg\-block {
    display: block !important;
  }
  .d-lg\-table {
    display: table !important;
  }
  .d-lg\-table-row {
    display: table-row !important;
  }
  .d-lg\-table-cell {
    display: table-cell !important;
  }
  .d-lg\-flex {
    display: flex !important;
  }
  .d-lg\-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl\-none {
    display: none !important;
  }
  .d-xl\-inline {
    display: inline !important;
  }
  .d-xl\-inline-block {
    display: inline-block !important;
  }
  .d-xl\-block {
    display: block !important;
  }
  .d-xl\-table {
    display: table !important;
  }
  .d-xl\-table-row {
    display: table-row !important;
  }
  .d-xl\-table-cell {
    display: table-cell !important;
  }
  .d-xl\-flex {
    display: flex !important;
  }
  .d-xl\-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-1 {
  flex: 1 1;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-fixed {
  flex: 0 0 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-grow-100 {
  flex-grow: 100 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .sm\:flex-row {
    flex-direction: row !important;
  }
  .sm\:flex-column {
    flex-direction: column !important;
  }
  .sm\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .sm\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  .sm\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .sm\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .sm\:flex-fill {
    flex: 1 1 auto !important;
  }
  .sm\:flex-fixed {
    flex: 0 0 auto !important;
  }
  .sm\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .sm\:flex-grow-1 {
    flex-grow: 1 !important;
  }
  .sm\:flex-grow-100 {
    flex-grow: 100 !important;
  }
  .sm\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .sm\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  .sm\:justify-content-start {
    justify-content: flex-start !important;
  }
  .sm\:justify-content-end {
    justify-content: flex-end !important;
  }
  .sm\:justify-content-center {
    justify-content: center !important;
  }
  .sm\:justify-content-between {
    justify-content: space-between !important;
  }
  .sm\:justify-content-around {
    justify-content: space-around !important;
  }
  .sm\:align-items-start {
    align-items: flex-start !important;
  }
  .sm\:align-items-end {
    align-items: flex-end !important;
  }
  .sm\:align-items-center {
    align-items: center !important;
  }
  .sm\:align-items-baseline {
    align-items: baseline !important;
  }
  .sm\:align-items-stretch {
    align-items: stretch !important;
  }
  .sm\:align-content-start {
    align-content: flex-start !important;
  }
  .sm\:align-content-end {
    align-content: flex-end !important;
  }
  .sm\:align-content-center {
    align-content: center !important;
  }
  .sm\:align-content-between {
    align-content: space-between !important;
  }
  .sm\:align-content-around {
    align-content: space-around !important;
  }
  .sm\:align-content-stretch {
    align-content: stretch !important;
  }
  .sm\:align-self-auto {
    align-self: auto !important;
  }
  .sm\:align-self-start {
    align-self: flex-start !important;
  }
  .sm\:align-self-end {
    align-self: flex-end !important;
  }
  .sm\:align-self-center {
    align-self: center !important;
  }
  .sm\:align-self-baseline {
    align-self: baseline !important;
  }
  .sm\:align-self-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .md\:flex-row {
    flex-direction: row !important;
  }
  .md\:flex-column {
    flex-direction: column !important;
  }
  .md\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .md\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  .md\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .md\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .md\:flex-fill {
    flex: 1 1 auto !important;
  }
  .md\:flex-fixed {
    flex: 0 0 auto !important;
  }
  .md\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .md\:flex-grow-1 {
    flex-grow: 1 !important;
  }
  .md\:flex-grow-100 {
    flex-grow: 100 !important;
  }
  .md\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .md\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  .md\:justify-content-start {
    justify-content: flex-start !important;
  }
  .md\:justify-content-end {
    justify-content: flex-end !important;
  }
  .md\:justify-content-center {
    justify-content: center !important;
  }
  .md\:justify-content-between {
    justify-content: space-between !important;
  }
  .md\:justify-content-around {
    justify-content: space-around !important;
  }
  .md\:align-items-start {
    align-items: flex-start !important;
  }
  .md\:align-items-end {
    align-items: flex-end !important;
  }
  .md\:align-items-center {
    align-items: center !important;
  }
  .md\:align-items-baseline {
    align-items: baseline !important;
  }
  .md\:align-items-stretch {
    align-items: stretch !important;
  }
  .md\:align-content-start {
    align-content: flex-start !important;
  }
  .md\:align-content-end {
    align-content: flex-end !important;
  }
  .md\:align-content-center {
    align-content: center !important;
  }
  .md\:align-content-between {
    align-content: space-between !important;
  }
  .md\:align-content-around {
    align-content: space-around !important;
  }
  .md\:align-content-stretch {
    align-content: stretch !important;
  }
  .md\:align-self-auto {
    align-self: auto !important;
  }
  .md\:align-self-start {
    align-self: flex-start !important;
  }
  .md\:align-self-end {
    align-self: flex-end !important;
  }
  .md\:align-self-center {
    align-self: center !important;
  }
  .md\:align-self-baseline {
    align-self: baseline !important;
  }
  .md\:align-self-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .lg\:flex-row {
    flex-direction: row !important;
  }
  .lg\:flex-column {
    flex-direction: column !important;
  }
  .lg\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .lg\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  .lg\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .lg\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .lg\:flex-fill {
    flex: 1 1 auto !important;
  }
  .lg\:flex-fixed {
    flex: 0 0 auto !important;
  }
  .lg\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .lg\:flex-grow-1 {
    flex-grow: 1 !important;
  }
  .lg\:flex-grow-100 {
    flex-grow: 100 !important;
  }
  .lg\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .lg\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  .lg\:justify-content-start {
    justify-content: flex-start !important;
  }
  .lg\:justify-content-end {
    justify-content: flex-end !important;
  }
  .lg\:justify-content-center {
    justify-content: center !important;
  }
  .lg\:justify-content-between {
    justify-content: space-between !important;
  }
  .lg\:justify-content-around {
    justify-content: space-around !important;
  }
  .lg\:align-items-start {
    align-items: flex-start !important;
  }
  .lg\:align-items-end {
    align-items: flex-end !important;
  }
  .lg\:align-items-center {
    align-items: center !important;
  }
  .lg\:align-items-baseline {
    align-items: baseline !important;
  }
  .lg\:align-items-stretch {
    align-items: stretch !important;
  }
  .lg\:align-content-start {
    align-content: flex-start !important;
  }
  .lg\:align-content-end {
    align-content: flex-end !important;
  }
  .lg\:align-content-center {
    align-content: center !important;
  }
  .lg\:align-content-between {
    align-content: space-between !important;
  }
  .lg\:align-content-around {
    align-content: space-around !important;
  }
  .lg\:align-content-stretch {
    align-content: stretch !important;
  }
  .lg\:align-self-auto {
    align-self: auto !important;
  }
  .lg\:align-self-start {
    align-self: flex-start !important;
  }
  .lg\:align-self-end {
    align-self: flex-end !important;
  }
  .lg\:align-self-center {
    align-self: center !important;
  }
  .lg\:align-self-baseline {
    align-self: baseline !important;
  }
  .lg\:align-self-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .xl\:flex-row {
    flex-direction: row !important;
  }
  .xl\:flex-column {
    flex-direction: column !important;
  }
  .xl\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .xl\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  .xl\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .xl\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .xl\:flex-fill {
    flex: 1 1 auto !important;
  }
  .xl\:flex-fixed {
    flex: 0 0 auto !important;
  }
  .xl\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .xl\:flex-grow-1 {
    flex-grow: 1 !important;
  }
  .xl\:flex-grow-100 {
    flex-grow: 100 !important;
  }
  .xl\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .xl\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  .xl\:justify-content-start {
    justify-content: flex-start !important;
  }
  .xl\:justify-content-end {
    justify-content: flex-end !important;
  }
  .xl\:justify-content-center {
    justify-content: center !important;
  }
  .xl\:justify-content-between {
    justify-content: space-between !important;
  }
  .xl\:justify-content-around {
    justify-content: space-around !important;
  }
  .xl\:align-items-start {
    align-items: flex-start !important;
  }
  .xl\:align-items-end {
    align-items: flex-end !important;
  }
  .xl\:align-items-center {
    align-items: center !important;
  }
  .xl\:align-items-baseline {
    align-items: baseline !important;
  }
  .xl\:align-items-stretch {
    align-items: stretch !important;
  }
  .xl\:align-content-start {
    align-content: flex-start !important;
  }
  .xl\:align-content-end {
    align-content: flex-end !important;
  }
  .xl\:align-content-center {
    align-content: center !important;
  }
  .xl\:align-content-between {
    align-content: space-between !important;
  }
  .xl\:align-content-around {
    align-content: space-around !important;
  }
  .xl\:align-content-stretch {
    align-content: stretch !important;
  }
  .xl\:align-self-auto {
    align-self: auto !important;
  }
  .xl\:align-self-start {
    align-self: flex-start !important;
  }
  .xl\:align-self-end {
    align-self: flex-end !important;
  }
  .xl\:align-self-center {
    align-self: center !important;
  }
  .xl\:align-self-baseline {
    align-self: baseline !important;
  }
  .xl\:align-self-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 576px) {
  .sm\:float-left {
    float: left !important;
  }
  .sm\:float-right {
    float: right !important;
  }
  .sm\:float-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .md\:float-left {
    float: left !important;
  }
  .md\:float-right {
    float: right !important;
  }
  .md\:float-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .lg\:float-left {
    float: left !important;
  }
  .lg\:float-right {
    float: right !important;
  }
  .lg\:float-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .xl\:float-left {
    float: left !important;
  }
  .xl\:float-right {
    float: right !important;
  }
  .xl\:float-none {
    float: none !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
.sans-serif {
  font-family: 'roboto', sans-sans-serif;
}
.font-weight-light {
  font-weight: 300;
}
.font-weight-light b,
.font-weight-light strong {
  font-weight: 500;
}
.font-weight-book,
.font-weight-normal,
.font-weight-regular {
  font-weight: normal;
}
.font-weight-book b,
.font-weight-normal b,
.font-weight-regular b,
.font-weight-book strong,
.font-weight-normal strong,
.font-weight-regular strong {
  font-weight: bold;
}
.font-weight-medium {
  font-weight: 500;
}
.font-weight-medium b,
.font-weight-medium strong {
  font-weight: bold;
}
.font-weight-bold {
  font-weight: bold;
}
.font-weight-bold b,
.font-weight-bold strong {
  font-weight: bold;
}
.futura {
  font-family: 'futura-pt', sans-serif;
}
.futura-light {
  font-family: 'futura-pt', sans-serif;
  font-weight: 300;
}
.futura-light b,
.futura-light strong {
  font-family: 'futura-pt', sans-serif;
  font-weight: 600;
}
.futura-book,
.futura-normal,
.futura-regular {
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
}
.futura-book b,
.futura-normal b,
.futura-regular b,
.futura-book strong,
.futura-normal strong,
.futura-regular strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.futura-demi {
  font-family: 'futura-pt', sans-serif;
  font-weight: 600;
}
.futura-demi b,
.futura-demi strong {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
.futura-bold {
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: normal;
}
*:hover > .not-hover,
*:not(:hover) > .hover {
  display: none;
}
.opacity-0,
.opacity-none {
  opacity: 0;
}
.opacity-100,
.opacity-full {
  opacity: 1;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-visible {
  overflow: visible;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.pointer-events-none {
  pointer-events: none;
}
.position-absolute {
  position: absolute !important;
}
.position-relative {
  position: relative !important;
}
.position-static {
  position: static !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.absolute-fill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.fade {
  transition: opacity 0.15s linear;
}
@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.transition {
  transition: all 0.2s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .transition {
    transition: none;
  }
}
.mw-0 {
  min-width: 0 !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.max-w-100 {
  max-width: 100% !important;
}
.max-h-100 {
  max-height: 100% !important;
}
.min-w-0 {
  min-width: 0 !important;
}
.min-h-0 {
  min-height: 0 !important;
}
.min-w-1r {
  min-width: 1rem !important;
}
.min-w-2r {
  min-width: 2rem !important;
}
.min-w-3r {
  min-width: 3rem !important;
}
.min-w-4r {
  min-width: 4rem !important;
}
.min-w-5r {
  min-width: 5rem !important;
}
.min-w-6r {
  min-width: 6rem !important;
}
.min-w-7r {
  min-width: 7rem !important;
}
.min-w-8r {
  min-width: 8rem !important;
}
.min-w-9r {
  min-width: 9rem !important;
}
.min-w-10r {
  min-width: 10rem !important;
}
.w-1r {
  width: 1rem !important;
}
.w-2r {
  width: 2rem !important;
}
.w-3r {
  width: 3rem !important;
}
.w-4r {
  width: 4rem !important;
}
.w-5r {
  width: 5rem !important;
}
.w-6r {
  width: 6rem !important;
}
.w-7r {
  width: 7rem !important;
}
.w-8r {
  width: 8rem !important;
}
.w-9r {
  width: 9rem !important;
}
.w-10r {
  width: 10rem !important;
}
.w-11r {
  width: 11rem !important;
}
.w-12r {
  width: 12rem !important;
}
.min-h-1r {
  min-height: 1rem !important;
}
.min-h-2r {
  min-height: 2rem !important;
}
.min-h-3r {
  min-height: 3rem !important;
}
.min-h-4r {
  min-height: 4rem !important;
}
.min-h-5r {
  min-height: 5rem !important;
}
.min-h-6r {
  min-height: 6rem !important;
}
.min-h-7r {
  min-height: 7rem !important;
}
.min-h-8r {
  min-height: 8rem !important;
}
.min-h-9r {
  min-height: 9rem !important;
}
.min-h-10r {
  min-height: 10rem !important;
}
@media print {
  .print-h-auto {
    height: auto !important;
  }
  .print-h-100 {
    height: 100% !important;
  }
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 0.75rem !important;
}
.mt-3,
.my-3 {
  margin-top: 0.75rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 0.75rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 0.75rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 0.75rem !important;
}
.m-4 {
  margin: 1rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1rem !important;
}
.m-5 {
  margin: 1.25rem !important;
}
.mt-5,
.my-5 {
  margin-top: 1.25rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 1.25rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 1.25rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 1.25rem !important;
}
.m-6 {
  margin: 1.5rem !important;
}
.mt-6,
.my-6 {
  margin-top: 1.5rem !important;
}
.mr-6,
.mx-6 {
  margin-right: 1.5rem !important;
}
.mb-6,
.my-6 {
  margin-bottom: 1.5rem !important;
}
.ml-6,
.mx-6 {
  margin-left: 1.5rem !important;
}
.m-7 {
  margin: 1.75rem !important;
}
.mt-7,
.my-7 {
  margin-top: 1.75rem !important;
}
.mr-7,
.mx-7 {
  margin-right: 1.75rem !important;
}
.mb-7,
.my-7 {
  margin-bottom: 1.75rem !important;
}
.ml-7,
.mx-7 {
  margin-left: 1.75rem !important;
}
.m-8 {
  margin: 2rem !important;
}
.mt-8,
.my-8 {
  margin-top: 2rem !important;
}
.mr-8,
.mx-8 {
  margin-right: 2rem !important;
}
.mb-8,
.my-8 {
  margin-bottom: 2rem !important;
}
.ml-8,
.mx-8 {
  margin-left: 2rem !important;
}
.m-9 {
  margin: 2.25rem !important;
}
.mt-9,
.my-9 {
  margin-top: 2.25rem !important;
}
.mr-9,
.mx-9 {
  margin-right: 2.25rem !important;
}
.mb-9,
.my-9 {
  margin-bottom: 2.25rem !important;
}
.ml-9,
.mx-9 {
  margin-left: 2.25rem !important;
}
.m-10 {
  margin: 2.5rem !important;
}
.mt-10,
.my-10 {
  margin-top: 2.5rem !important;
}
.mr-10,
.mx-10 {
  margin-right: 2.5rem !important;
}
.mb-10,
.my-10 {
  margin-bottom: 2.5rem !important;
}
.ml-10,
.mx-10 {
  margin-left: 2.5rem !important;
}
.m-11 {
  margin: 2.75rem !important;
}
.mt-11,
.my-11 {
  margin-top: 2.75rem !important;
}
.mr-11,
.mx-11 {
  margin-right: 2.75rem !important;
}
.mb-11,
.my-11 {
  margin-bottom: 2.75rem !important;
}
.ml-11,
.mx-11 {
  margin-left: 2.75rem !important;
}
.m-12 {
  margin: 3rem !important;
}
.mt-12,
.my-12 {
  margin-top: 3rem !important;
}
.mr-12,
.mx-12 {
  margin-right: 3rem !important;
}
.mb-12,
.my-12 {
  margin-bottom: 3rem !important;
}
.ml-12,
.mx-12 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 0.75rem !important;
}
.pt-3,
.py-3 {
  padding-top: 0.75rem !important;
}
.pr-3,
.px-3 {
  padding-right: 0.75rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 0.75rem !important;
}
.pl-3,
.px-3 {
  padding-left: 0.75rem !important;
}
.p-4 {
  padding: 1rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1rem !important;
}
.p-5 {
  padding: 1.25rem !important;
}
.pt-5,
.py-5 {
  padding-top: 1.25rem !important;
}
.pr-5,
.px-5 {
  padding-right: 1.25rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 1.25rem !important;
}
.pl-5,
.px-5 {
  padding-left: 1.25rem !important;
}
.p-6 {
  padding: 1.5rem !important;
}
.pt-6,
.py-6 {
  padding-top: 1.5rem !important;
}
.pr-6,
.px-6 {
  padding-right: 1.5rem !important;
}
.pb-6,
.py-6 {
  padding-bottom: 1.5rem !important;
}
.pl-6,
.px-6 {
  padding-left: 1.5rem !important;
}
.p-7 {
  padding: 1.75rem !important;
}
.pt-7,
.py-7 {
  padding-top: 1.75rem !important;
}
.pr-7,
.px-7 {
  padding-right: 1.75rem !important;
}
.pb-7,
.py-7 {
  padding-bottom: 1.75rem !important;
}
.pl-7,
.px-7 {
  padding-left: 1.75rem !important;
}
.p-8 {
  padding: 2rem !important;
}
.pt-8,
.py-8 {
  padding-top: 2rem !important;
}
.pr-8,
.px-8 {
  padding-right: 2rem !important;
}
.pb-8,
.py-8 {
  padding-bottom: 2rem !important;
}
.pl-8,
.px-8 {
  padding-left: 2rem !important;
}
.p-9 {
  padding: 2.25rem !important;
}
.pt-9,
.py-9 {
  padding-top: 2.25rem !important;
}
.pr-9,
.px-9 {
  padding-right: 2.25rem !important;
}
.pb-9,
.py-9 {
  padding-bottom: 2.25rem !important;
}
.pl-9,
.px-9 {
  padding-left: 2.25rem !important;
}
.p-10 {
  padding: 2.5rem !important;
}
.pt-10,
.py-10 {
  padding-top: 2.5rem !important;
}
.pr-10,
.px-10 {
  padding-right: 2.5rem !important;
}
.pb-10,
.py-10 {
  padding-bottom: 2.5rem !important;
}
.pl-10,
.px-10 {
  padding-left: 2.5rem !important;
}
.p-11 {
  padding: 2.75rem !important;
}
.pt-11,
.py-11 {
  padding-top: 2.75rem !important;
}
.pr-11,
.px-11 {
  padding-right: 2.75rem !important;
}
.pb-11,
.py-11 {
  padding-bottom: 2.75rem !important;
}
.pl-11,
.px-11 {
  padding-left: 2.75rem !important;
}
.p-12 {
  padding: 3rem !important;
}
.pt-12,
.py-12 {
  padding-top: 3rem !important;
}
.pr-12,
.px-12 {
  padding-right: 3rem !important;
}
.pb-12,
.py-12 {
  padding-bottom: 3rem !important;
}
.pl-12,
.px-12 {
  padding-left: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .sm\:m-0 {
    margin: 0 !important;
  }
  .sm\:mt-0,
  .sm\:my-0 {
    margin-top: 0 !important;
  }
  .sm\:mr-0,
  .sm\:mx-0 {
    margin-right: 0 !important;
  }
  .sm\:mb-0,
  .sm\:my-0 {
    margin-bottom: 0 !important;
  }
  .sm\:ml-0,
  .sm\:mx-0 {
    margin-left: 0 !important;
  }
  .sm\:m-1 {
    margin: 0.25rem !important;
  }
  .sm\:mt-1,
  .sm\:my-1 {
    margin-top: 0.25rem !important;
  }
  .sm\:mr-1,
  .sm\:mx-1 {
    margin-right: 0.25rem !important;
  }
  .sm\:mb-1,
  .sm\:my-1 {
    margin-bottom: 0.25rem !important;
  }
  .sm\:ml-1,
  .sm\:mx-1 {
    margin-left: 0.25rem !important;
  }
  .sm\:m-2 {
    margin: 0.5rem !important;
  }
  .sm\:mt-2,
  .sm\:my-2 {
    margin-top: 0.5rem !important;
  }
  .sm\:mr-2,
  .sm\:mx-2 {
    margin-right: 0.5rem !important;
  }
  .sm\:mb-2,
  .sm\:my-2 {
    margin-bottom: 0.5rem !important;
  }
  .sm\:ml-2,
  .sm\:mx-2 {
    margin-left: 0.5rem !important;
  }
  .sm\:m-3 {
    margin: 0.75rem !important;
  }
  .sm\:mt-3,
  .sm\:my-3 {
    margin-top: 0.75rem !important;
  }
  .sm\:mr-3,
  .sm\:mx-3 {
    margin-right: 0.75rem !important;
  }
  .sm\:mb-3,
  .sm\:my-3 {
    margin-bottom: 0.75rem !important;
  }
  .sm\:ml-3,
  .sm\:mx-3 {
    margin-left: 0.75rem !important;
  }
  .sm\:m-4 {
    margin: 1rem !important;
  }
  .sm\:mt-4,
  .sm\:my-4 {
    margin-top: 1rem !important;
  }
  .sm\:mr-4,
  .sm\:mx-4 {
    margin-right: 1rem !important;
  }
  .sm\:mb-4,
  .sm\:my-4 {
    margin-bottom: 1rem !important;
  }
  .sm\:ml-4,
  .sm\:mx-4 {
    margin-left: 1rem !important;
  }
  .sm\:m-5 {
    margin: 1.25rem !important;
  }
  .sm\:mt-5,
  .sm\:my-5 {
    margin-top: 1.25rem !important;
  }
  .sm\:mr-5,
  .sm\:mx-5 {
    margin-right: 1.25rem !important;
  }
  .sm\:mb-5,
  .sm\:my-5 {
    margin-bottom: 1.25rem !important;
  }
  .sm\:ml-5,
  .sm\:mx-5 {
    margin-left: 1.25rem !important;
  }
  .sm\:m-6 {
    margin: 1.5rem !important;
  }
  .sm\:mt-6,
  .sm\:my-6 {
    margin-top: 1.5rem !important;
  }
  .sm\:mr-6,
  .sm\:mx-6 {
    margin-right: 1.5rem !important;
  }
  .sm\:mb-6,
  .sm\:my-6 {
    margin-bottom: 1.5rem !important;
  }
  .sm\:ml-6,
  .sm\:mx-6 {
    margin-left: 1.5rem !important;
  }
  .sm\:m-7 {
    margin: 1.75rem !important;
  }
  .sm\:mt-7,
  .sm\:my-7 {
    margin-top: 1.75rem !important;
  }
  .sm\:mr-7,
  .sm\:mx-7 {
    margin-right: 1.75rem !important;
  }
  .sm\:mb-7,
  .sm\:my-7 {
    margin-bottom: 1.75rem !important;
  }
  .sm\:ml-7,
  .sm\:mx-7 {
    margin-left: 1.75rem !important;
  }
  .sm\:m-8 {
    margin: 2rem !important;
  }
  .sm\:mt-8,
  .sm\:my-8 {
    margin-top: 2rem !important;
  }
  .sm\:mr-8,
  .sm\:mx-8 {
    margin-right: 2rem !important;
  }
  .sm\:mb-8,
  .sm\:my-8 {
    margin-bottom: 2rem !important;
  }
  .sm\:ml-8,
  .sm\:mx-8 {
    margin-left: 2rem !important;
  }
  .sm\:m-9 {
    margin: 2.25rem !important;
  }
  .sm\:mt-9,
  .sm\:my-9 {
    margin-top: 2.25rem !important;
  }
  .sm\:mr-9,
  .sm\:mx-9 {
    margin-right: 2.25rem !important;
  }
  .sm\:mb-9,
  .sm\:my-9 {
    margin-bottom: 2.25rem !important;
  }
  .sm\:ml-9,
  .sm\:mx-9 {
    margin-left: 2.25rem !important;
  }
  .sm\:m-10 {
    margin: 2.5rem !important;
  }
  .sm\:mt-10,
  .sm\:my-10 {
    margin-top: 2.5rem !important;
  }
  .sm\:mr-10,
  .sm\:mx-10 {
    margin-right: 2.5rem !important;
  }
  .sm\:mb-10,
  .sm\:my-10 {
    margin-bottom: 2.5rem !important;
  }
  .sm\:ml-10,
  .sm\:mx-10 {
    margin-left: 2.5rem !important;
  }
  .sm\:m-11 {
    margin: 2.75rem !important;
  }
  .sm\:mt-11,
  .sm\:my-11 {
    margin-top: 2.75rem !important;
  }
  .sm\:mr-11,
  .sm\:mx-11 {
    margin-right: 2.75rem !important;
  }
  .sm\:mb-11,
  .sm\:my-11 {
    margin-bottom: 2.75rem !important;
  }
  .sm\:ml-11,
  .sm\:mx-11 {
    margin-left: 2.75rem !important;
  }
  .sm\:m-12 {
    margin: 3rem !important;
  }
  .sm\:mt-12,
  .sm\:my-12 {
    margin-top: 3rem !important;
  }
  .sm\:mr-12,
  .sm\:mx-12 {
    margin-right: 3rem !important;
  }
  .sm\:mb-12,
  .sm\:my-12 {
    margin-bottom: 3rem !important;
  }
  .sm\:ml-12,
  .sm\:mx-12 {
    margin-left: 3rem !important;
  }
  .sm\:p-0 {
    padding: 0 !important;
  }
  .sm\:pt-0,
  .sm\:py-0 {
    padding-top: 0 !important;
  }
  .sm\:pr-0,
  .sm\:px-0 {
    padding-right: 0 !important;
  }
  .sm\:pb-0,
  .sm\:py-0 {
    padding-bottom: 0 !important;
  }
  .sm\:pl-0,
  .sm\:px-0 {
    padding-left: 0 !important;
  }
  .sm\:p-1 {
    padding: 0.25rem !important;
  }
  .sm\:pt-1,
  .sm\:py-1 {
    padding-top: 0.25rem !important;
  }
  .sm\:pr-1,
  .sm\:px-1 {
    padding-right: 0.25rem !important;
  }
  .sm\:pb-1,
  .sm\:py-1 {
    padding-bottom: 0.25rem !important;
  }
  .sm\:pl-1,
  .sm\:px-1 {
    padding-left: 0.25rem !important;
  }
  .sm\:p-2 {
    padding: 0.5rem !important;
  }
  .sm\:pt-2,
  .sm\:py-2 {
    padding-top: 0.5rem !important;
  }
  .sm\:pr-2,
  .sm\:px-2 {
    padding-right: 0.5rem !important;
  }
  .sm\:pb-2,
  .sm\:py-2 {
    padding-bottom: 0.5rem !important;
  }
  .sm\:pl-2,
  .sm\:px-2 {
    padding-left: 0.5rem !important;
  }
  .sm\:p-3 {
    padding: 0.75rem !important;
  }
  .sm\:pt-3,
  .sm\:py-3 {
    padding-top: 0.75rem !important;
  }
  .sm\:pr-3,
  .sm\:px-3 {
    padding-right: 0.75rem !important;
  }
  .sm\:pb-3,
  .sm\:py-3 {
    padding-bottom: 0.75rem !important;
  }
  .sm\:pl-3,
  .sm\:px-3 {
    padding-left: 0.75rem !important;
  }
  .sm\:p-4 {
    padding: 1rem !important;
  }
  .sm\:pt-4,
  .sm\:py-4 {
    padding-top: 1rem !important;
  }
  .sm\:pr-4,
  .sm\:px-4 {
    padding-right: 1rem !important;
  }
  .sm\:pb-4,
  .sm\:py-4 {
    padding-bottom: 1rem !important;
  }
  .sm\:pl-4,
  .sm\:px-4 {
    padding-left: 1rem !important;
  }
  .sm\:p-5 {
    padding: 1.25rem !important;
  }
  .sm\:pt-5,
  .sm\:py-5 {
    padding-top: 1.25rem !important;
  }
  .sm\:pr-5,
  .sm\:px-5 {
    padding-right: 1.25rem !important;
  }
  .sm\:pb-5,
  .sm\:py-5 {
    padding-bottom: 1.25rem !important;
  }
  .sm\:pl-5,
  .sm\:px-5 {
    padding-left: 1.25rem !important;
  }
  .sm\:p-6 {
    padding: 1.5rem !important;
  }
  .sm\:pt-6,
  .sm\:py-6 {
    padding-top: 1.5rem !important;
  }
  .sm\:pr-6,
  .sm\:px-6 {
    padding-right: 1.5rem !important;
  }
  .sm\:pb-6,
  .sm\:py-6 {
    padding-bottom: 1.5rem !important;
  }
  .sm\:pl-6,
  .sm\:px-6 {
    padding-left: 1.5rem !important;
  }
  .sm\:p-7 {
    padding: 1.75rem !important;
  }
  .sm\:pt-7,
  .sm\:py-7 {
    padding-top: 1.75rem !important;
  }
  .sm\:pr-7,
  .sm\:px-7 {
    padding-right: 1.75rem !important;
  }
  .sm\:pb-7,
  .sm\:py-7 {
    padding-bottom: 1.75rem !important;
  }
  .sm\:pl-7,
  .sm\:px-7 {
    padding-left: 1.75rem !important;
  }
  .sm\:p-8 {
    padding: 2rem !important;
  }
  .sm\:pt-8,
  .sm\:py-8 {
    padding-top: 2rem !important;
  }
  .sm\:pr-8,
  .sm\:px-8 {
    padding-right: 2rem !important;
  }
  .sm\:pb-8,
  .sm\:py-8 {
    padding-bottom: 2rem !important;
  }
  .sm\:pl-8,
  .sm\:px-8 {
    padding-left: 2rem !important;
  }
  .sm\:p-9 {
    padding: 2.25rem !important;
  }
  .sm\:pt-9,
  .sm\:py-9 {
    padding-top: 2.25rem !important;
  }
  .sm\:pr-9,
  .sm\:px-9 {
    padding-right: 2.25rem !important;
  }
  .sm\:pb-9,
  .sm\:py-9 {
    padding-bottom: 2.25rem !important;
  }
  .sm\:pl-9,
  .sm\:px-9 {
    padding-left: 2.25rem !important;
  }
  .sm\:p-10 {
    padding: 2.5rem !important;
  }
  .sm\:pt-10,
  .sm\:py-10 {
    padding-top: 2.5rem !important;
  }
  .sm\:pr-10,
  .sm\:px-10 {
    padding-right: 2.5rem !important;
  }
  .sm\:pb-10,
  .sm\:py-10 {
    padding-bottom: 2.5rem !important;
  }
  .sm\:pl-10,
  .sm\:px-10 {
    padding-left: 2.5rem !important;
  }
  .sm\:p-11 {
    padding: 2.75rem !important;
  }
  .sm\:pt-11,
  .sm\:py-11 {
    padding-top: 2.75rem !important;
  }
  .sm\:pr-11,
  .sm\:px-11 {
    padding-right: 2.75rem !important;
  }
  .sm\:pb-11,
  .sm\:py-11 {
    padding-bottom: 2.75rem !important;
  }
  .sm\:pl-11,
  .sm\:px-11 {
    padding-left: 2.75rem !important;
  }
  .sm\:p-12 {
    padding: 3rem !important;
  }
  .sm\:pt-12,
  .sm\:py-12 {
    padding-top: 3rem !important;
  }
  .sm\:pr-12,
  .sm\:px-12 {
    padding-right: 3rem !important;
  }
  .sm\:pb-12,
  .sm\:py-12 {
    padding-bottom: 3rem !important;
  }
  .sm\:pl-12,
  .sm\:px-12 {
    padding-left: 3rem !important;
  }
  .sm\:m-auto {
    margin: auto !important;
  }
  .sm\:mt-auto,
  .sm\:my-auto {
    margin-top: auto !important;
  }
  .sm\:mr-auto,
  .sm\:mx-auto {
    margin-right: auto !important;
  }
  .sm\:mb-auto,
  .sm\:my-auto {
    margin-bottom: auto !important;
  }
  .sm\:ml-auto,
  .sm\:mx-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .md\:m-0 {
    margin: 0 !important;
  }
  .md\:mt-0,
  .md\:my-0 {
    margin-top: 0 !important;
  }
  .md\:mr-0,
  .md\:mx-0 {
    margin-right: 0 !important;
  }
  .md\:mb-0,
  .md\:my-0 {
    margin-bottom: 0 !important;
  }
  .md\:ml-0,
  .md\:mx-0 {
    margin-left: 0 !important;
  }
  .md\:m-1 {
    margin: 0.25rem !important;
  }
  .md\:mt-1,
  .md\:my-1 {
    margin-top: 0.25rem !important;
  }
  .md\:mr-1,
  .md\:mx-1 {
    margin-right: 0.25rem !important;
  }
  .md\:mb-1,
  .md\:my-1 {
    margin-bottom: 0.25rem !important;
  }
  .md\:ml-1,
  .md\:mx-1 {
    margin-left: 0.25rem !important;
  }
  .md\:m-2 {
    margin: 0.5rem !important;
  }
  .md\:mt-2,
  .md\:my-2 {
    margin-top: 0.5rem !important;
  }
  .md\:mr-2,
  .md\:mx-2 {
    margin-right: 0.5rem !important;
  }
  .md\:mb-2,
  .md\:my-2 {
    margin-bottom: 0.5rem !important;
  }
  .md\:ml-2,
  .md\:mx-2 {
    margin-left: 0.5rem !important;
  }
  .md\:m-3 {
    margin: 0.75rem !important;
  }
  .md\:mt-3,
  .md\:my-3 {
    margin-top: 0.75rem !important;
  }
  .md\:mr-3,
  .md\:mx-3 {
    margin-right: 0.75rem !important;
  }
  .md\:mb-3,
  .md\:my-3 {
    margin-bottom: 0.75rem !important;
  }
  .md\:ml-3,
  .md\:mx-3 {
    margin-left: 0.75rem !important;
  }
  .md\:m-4 {
    margin: 1rem !important;
  }
  .md\:mt-4,
  .md\:my-4 {
    margin-top: 1rem !important;
  }
  .md\:mr-4,
  .md\:mx-4 {
    margin-right: 1rem !important;
  }
  .md\:mb-4,
  .md\:my-4 {
    margin-bottom: 1rem !important;
  }
  .md\:ml-4,
  .md\:mx-4 {
    margin-left: 1rem !important;
  }
  .md\:m-5 {
    margin: 1.25rem !important;
  }
  .md\:mt-5,
  .md\:my-5 {
    margin-top: 1.25rem !important;
  }
  .md\:mr-5,
  .md\:mx-5 {
    margin-right: 1.25rem !important;
  }
  .md\:mb-5,
  .md\:my-5 {
    margin-bottom: 1.25rem !important;
  }
  .md\:ml-5,
  .md\:mx-5 {
    margin-left: 1.25rem !important;
  }
  .md\:m-6 {
    margin: 1.5rem !important;
  }
  .md\:mt-6,
  .md\:my-6 {
    margin-top: 1.5rem !important;
  }
  .md\:mr-6,
  .md\:mx-6 {
    margin-right: 1.5rem !important;
  }
  .md\:mb-6,
  .md\:my-6 {
    margin-bottom: 1.5rem !important;
  }
  .md\:ml-6,
  .md\:mx-6 {
    margin-left: 1.5rem !important;
  }
  .md\:m-7 {
    margin: 1.75rem !important;
  }
  .md\:mt-7,
  .md\:my-7 {
    margin-top: 1.75rem !important;
  }
  .md\:mr-7,
  .md\:mx-7 {
    margin-right: 1.75rem !important;
  }
  .md\:mb-7,
  .md\:my-7 {
    margin-bottom: 1.75rem !important;
  }
  .md\:ml-7,
  .md\:mx-7 {
    margin-left: 1.75rem !important;
  }
  .md\:m-8 {
    margin: 2rem !important;
  }
  .md\:mt-8,
  .md\:my-8 {
    margin-top: 2rem !important;
  }
  .md\:mr-8,
  .md\:mx-8 {
    margin-right: 2rem !important;
  }
  .md\:mb-8,
  .md\:my-8 {
    margin-bottom: 2rem !important;
  }
  .md\:ml-8,
  .md\:mx-8 {
    margin-left: 2rem !important;
  }
  .md\:m-9 {
    margin: 2.25rem !important;
  }
  .md\:mt-9,
  .md\:my-9 {
    margin-top: 2.25rem !important;
  }
  .md\:mr-9,
  .md\:mx-9 {
    margin-right: 2.25rem !important;
  }
  .md\:mb-9,
  .md\:my-9 {
    margin-bottom: 2.25rem !important;
  }
  .md\:ml-9,
  .md\:mx-9 {
    margin-left: 2.25rem !important;
  }
  .md\:m-10 {
    margin: 2.5rem !important;
  }
  .md\:mt-10,
  .md\:my-10 {
    margin-top: 2.5rem !important;
  }
  .md\:mr-10,
  .md\:mx-10 {
    margin-right: 2.5rem !important;
  }
  .md\:mb-10,
  .md\:my-10 {
    margin-bottom: 2.5rem !important;
  }
  .md\:ml-10,
  .md\:mx-10 {
    margin-left: 2.5rem !important;
  }
  .md\:m-11 {
    margin: 2.75rem !important;
  }
  .md\:mt-11,
  .md\:my-11 {
    margin-top: 2.75rem !important;
  }
  .md\:mr-11,
  .md\:mx-11 {
    margin-right: 2.75rem !important;
  }
  .md\:mb-11,
  .md\:my-11 {
    margin-bottom: 2.75rem !important;
  }
  .md\:ml-11,
  .md\:mx-11 {
    margin-left: 2.75rem !important;
  }
  .md\:m-12 {
    margin: 3rem !important;
  }
  .md\:mt-12,
  .md\:my-12 {
    margin-top: 3rem !important;
  }
  .md\:mr-12,
  .md\:mx-12 {
    margin-right: 3rem !important;
  }
  .md\:mb-12,
  .md\:my-12 {
    margin-bottom: 3rem !important;
  }
  .md\:ml-12,
  .md\:mx-12 {
    margin-left: 3rem !important;
  }
  .md\:p-0 {
    padding: 0 !important;
  }
  .md\:pt-0,
  .md\:py-0 {
    padding-top: 0 !important;
  }
  .md\:pr-0,
  .md\:px-0 {
    padding-right: 0 !important;
  }
  .md\:pb-0,
  .md\:py-0 {
    padding-bottom: 0 !important;
  }
  .md\:pl-0,
  .md\:px-0 {
    padding-left: 0 !important;
  }
  .md\:p-1 {
    padding: 0.25rem !important;
  }
  .md\:pt-1,
  .md\:py-1 {
    padding-top: 0.25rem !important;
  }
  .md\:pr-1,
  .md\:px-1 {
    padding-right: 0.25rem !important;
  }
  .md\:pb-1,
  .md\:py-1 {
    padding-bottom: 0.25rem !important;
  }
  .md\:pl-1,
  .md\:px-1 {
    padding-left: 0.25rem !important;
  }
  .md\:p-2 {
    padding: 0.5rem !important;
  }
  .md\:pt-2,
  .md\:py-2 {
    padding-top: 0.5rem !important;
  }
  .md\:pr-2,
  .md\:px-2 {
    padding-right: 0.5rem !important;
  }
  .md\:pb-2,
  .md\:py-2 {
    padding-bottom: 0.5rem !important;
  }
  .md\:pl-2,
  .md\:px-2 {
    padding-left: 0.5rem !important;
  }
  .md\:p-3 {
    padding: 0.75rem !important;
  }
  .md\:pt-3,
  .md\:py-3 {
    padding-top: 0.75rem !important;
  }
  .md\:pr-3,
  .md\:px-3 {
    padding-right: 0.75rem !important;
  }
  .md\:pb-3,
  .md\:py-3 {
    padding-bottom: 0.75rem !important;
  }
  .md\:pl-3,
  .md\:px-3 {
    padding-left: 0.75rem !important;
  }
  .md\:p-4 {
    padding: 1rem !important;
  }
  .md\:pt-4,
  .md\:py-4 {
    padding-top: 1rem !important;
  }
  .md\:pr-4,
  .md\:px-4 {
    padding-right: 1rem !important;
  }
  .md\:pb-4,
  .md\:py-4 {
    padding-bottom: 1rem !important;
  }
  .md\:pl-4,
  .md\:px-4 {
    padding-left: 1rem !important;
  }
  .md\:p-5 {
    padding: 1.25rem !important;
  }
  .md\:pt-5,
  .md\:py-5 {
    padding-top: 1.25rem !important;
  }
  .md\:pr-5,
  .md\:px-5 {
    padding-right: 1.25rem !important;
  }
  .md\:pb-5,
  .md\:py-5 {
    padding-bottom: 1.25rem !important;
  }
  .md\:pl-5,
  .md\:px-5 {
    padding-left: 1.25rem !important;
  }
  .md\:p-6 {
    padding: 1.5rem !important;
  }
  .md\:pt-6,
  .md\:py-6 {
    padding-top: 1.5rem !important;
  }
  .md\:pr-6,
  .md\:px-6 {
    padding-right: 1.5rem !important;
  }
  .md\:pb-6,
  .md\:py-6 {
    padding-bottom: 1.5rem !important;
  }
  .md\:pl-6,
  .md\:px-6 {
    padding-left: 1.5rem !important;
  }
  .md\:p-7 {
    padding: 1.75rem !important;
  }
  .md\:pt-7,
  .md\:py-7 {
    padding-top: 1.75rem !important;
  }
  .md\:pr-7,
  .md\:px-7 {
    padding-right: 1.75rem !important;
  }
  .md\:pb-7,
  .md\:py-7 {
    padding-bottom: 1.75rem !important;
  }
  .md\:pl-7,
  .md\:px-7 {
    padding-left: 1.75rem !important;
  }
  .md\:p-8 {
    padding: 2rem !important;
  }
  .md\:pt-8,
  .md\:py-8 {
    padding-top: 2rem !important;
  }
  .md\:pr-8,
  .md\:px-8 {
    padding-right: 2rem !important;
  }
  .md\:pb-8,
  .md\:py-8 {
    padding-bottom: 2rem !important;
  }
  .md\:pl-8,
  .md\:px-8 {
    padding-left: 2rem !important;
  }
  .md\:p-9 {
    padding: 2.25rem !important;
  }
  .md\:pt-9,
  .md\:py-9 {
    padding-top: 2.25rem !important;
  }
  .md\:pr-9,
  .md\:px-9 {
    padding-right: 2.25rem !important;
  }
  .md\:pb-9,
  .md\:py-9 {
    padding-bottom: 2.25rem !important;
  }
  .md\:pl-9,
  .md\:px-9 {
    padding-left: 2.25rem !important;
  }
  .md\:p-10 {
    padding: 2.5rem !important;
  }
  .md\:pt-10,
  .md\:py-10 {
    padding-top: 2.5rem !important;
  }
  .md\:pr-10,
  .md\:px-10 {
    padding-right: 2.5rem !important;
  }
  .md\:pb-10,
  .md\:py-10 {
    padding-bottom: 2.5rem !important;
  }
  .md\:pl-10,
  .md\:px-10 {
    padding-left: 2.5rem !important;
  }
  .md\:p-11 {
    padding: 2.75rem !important;
  }
  .md\:pt-11,
  .md\:py-11 {
    padding-top: 2.75rem !important;
  }
  .md\:pr-11,
  .md\:px-11 {
    padding-right: 2.75rem !important;
  }
  .md\:pb-11,
  .md\:py-11 {
    padding-bottom: 2.75rem !important;
  }
  .md\:pl-11,
  .md\:px-11 {
    padding-left: 2.75rem !important;
  }
  .md\:p-12 {
    padding: 3rem !important;
  }
  .md\:pt-12,
  .md\:py-12 {
    padding-top: 3rem !important;
  }
  .md\:pr-12,
  .md\:px-12 {
    padding-right: 3rem !important;
  }
  .md\:pb-12,
  .md\:py-12 {
    padding-bottom: 3rem !important;
  }
  .md\:pl-12,
  .md\:px-12 {
    padding-left: 3rem !important;
  }
  .md\:m-auto {
    margin: auto !important;
  }
  .md\:mt-auto,
  .md\:my-auto {
    margin-top: auto !important;
  }
  .md\:mr-auto,
  .md\:mx-auto {
    margin-right: auto !important;
  }
  .md\:mb-auto,
  .md\:my-auto {
    margin-bottom: auto !important;
  }
  .md\:ml-auto,
  .md\:mx-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .lg\:m-0 {
    margin: 0 !important;
  }
  .lg\:mt-0,
  .lg\:my-0 {
    margin-top: 0 !important;
  }
  .lg\:mr-0,
  .lg\:mx-0 {
    margin-right: 0 !important;
  }
  .lg\:mb-0,
  .lg\:my-0 {
    margin-bottom: 0 !important;
  }
  .lg\:ml-0,
  .lg\:mx-0 {
    margin-left: 0 !important;
  }
  .lg\:m-1 {
    margin: 0.25rem !important;
  }
  .lg\:mt-1,
  .lg\:my-1 {
    margin-top: 0.25rem !important;
  }
  .lg\:mr-1,
  .lg\:mx-1 {
    margin-right: 0.25rem !important;
  }
  .lg\:mb-1,
  .lg\:my-1 {
    margin-bottom: 0.25rem !important;
  }
  .lg\:ml-1,
  .lg\:mx-1 {
    margin-left: 0.25rem !important;
  }
  .lg\:m-2 {
    margin: 0.5rem !important;
  }
  .lg\:mt-2,
  .lg\:my-2 {
    margin-top: 0.5rem !important;
  }
  .lg\:mr-2,
  .lg\:mx-2 {
    margin-right: 0.5rem !important;
  }
  .lg\:mb-2,
  .lg\:my-2 {
    margin-bottom: 0.5rem !important;
  }
  .lg\:ml-2,
  .lg\:mx-2 {
    margin-left: 0.5rem !important;
  }
  .lg\:m-3 {
    margin: 0.75rem !important;
  }
  .lg\:mt-3,
  .lg\:my-3 {
    margin-top: 0.75rem !important;
  }
  .lg\:mr-3,
  .lg\:mx-3 {
    margin-right: 0.75rem !important;
  }
  .lg\:mb-3,
  .lg\:my-3 {
    margin-bottom: 0.75rem !important;
  }
  .lg\:ml-3,
  .lg\:mx-3 {
    margin-left: 0.75rem !important;
  }
  .lg\:m-4 {
    margin: 1rem !important;
  }
  .lg\:mt-4,
  .lg\:my-4 {
    margin-top: 1rem !important;
  }
  .lg\:mr-4,
  .lg\:mx-4 {
    margin-right: 1rem !important;
  }
  .lg\:mb-4,
  .lg\:my-4 {
    margin-bottom: 1rem !important;
  }
  .lg\:ml-4,
  .lg\:mx-4 {
    margin-left: 1rem !important;
  }
  .lg\:m-5 {
    margin: 1.25rem !important;
  }
  .lg\:mt-5,
  .lg\:my-5 {
    margin-top: 1.25rem !important;
  }
  .lg\:mr-5,
  .lg\:mx-5 {
    margin-right: 1.25rem !important;
  }
  .lg\:mb-5,
  .lg\:my-5 {
    margin-bottom: 1.25rem !important;
  }
  .lg\:ml-5,
  .lg\:mx-5 {
    margin-left: 1.25rem !important;
  }
  .lg\:m-6 {
    margin: 1.5rem !important;
  }
  .lg\:mt-6,
  .lg\:my-6 {
    margin-top: 1.5rem !important;
  }
  .lg\:mr-6,
  .lg\:mx-6 {
    margin-right: 1.5rem !important;
  }
  .lg\:mb-6,
  .lg\:my-6 {
    margin-bottom: 1.5rem !important;
  }
  .lg\:ml-6,
  .lg\:mx-6 {
    margin-left: 1.5rem !important;
  }
  .lg\:m-7 {
    margin: 1.75rem !important;
  }
  .lg\:mt-7,
  .lg\:my-7 {
    margin-top: 1.75rem !important;
  }
  .lg\:mr-7,
  .lg\:mx-7 {
    margin-right: 1.75rem !important;
  }
  .lg\:mb-7,
  .lg\:my-7 {
    margin-bottom: 1.75rem !important;
  }
  .lg\:ml-7,
  .lg\:mx-7 {
    margin-left: 1.75rem !important;
  }
  .lg\:m-8 {
    margin: 2rem !important;
  }
  .lg\:mt-8,
  .lg\:my-8 {
    margin-top: 2rem !important;
  }
  .lg\:mr-8,
  .lg\:mx-8 {
    margin-right: 2rem !important;
  }
  .lg\:mb-8,
  .lg\:my-8 {
    margin-bottom: 2rem !important;
  }
  .lg\:ml-8,
  .lg\:mx-8 {
    margin-left: 2rem !important;
  }
  .lg\:m-9 {
    margin: 2.25rem !important;
  }
  .lg\:mt-9,
  .lg\:my-9 {
    margin-top: 2.25rem !important;
  }
  .lg\:mr-9,
  .lg\:mx-9 {
    margin-right: 2.25rem !important;
  }
  .lg\:mb-9,
  .lg\:my-9 {
    margin-bottom: 2.25rem !important;
  }
  .lg\:ml-9,
  .lg\:mx-9 {
    margin-left: 2.25rem !important;
  }
  .lg\:m-10 {
    margin: 2.5rem !important;
  }
  .lg\:mt-10,
  .lg\:my-10 {
    margin-top: 2.5rem !important;
  }
  .lg\:mr-10,
  .lg\:mx-10 {
    margin-right: 2.5rem !important;
  }
  .lg\:mb-10,
  .lg\:my-10 {
    margin-bottom: 2.5rem !important;
  }
  .lg\:ml-10,
  .lg\:mx-10 {
    margin-left: 2.5rem !important;
  }
  .lg\:m-11 {
    margin: 2.75rem !important;
  }
  .lg\:mt-11,
  .lg\:my-11 {
    margin-top: 2.75rem !important;
  }
  .lg\:mr-11,
  .lg\:mx-11 {
    margin-right: 2.75rem !important;
  }
  .lg\:mb-11,
  .lg\:my-11 {
    margin-bottom: 2.75rem !important;
  }
  .lg\:ml-11,
  .lg\:mx-11 {
    margin-left: 2.75rem !important;
  }
  .lg\:m-12 {
    margin: 3rem !important;
  }
  .lg\:mt-12,
  .lg\:my-12 {
    margin-top: 3rem !important;
  }
  .lg\:mr-12,
  .lg\:mx-12 {
    margin-right: 3rem !important;
  }
  .lg\:mb-12,
  .lg\:my-12 {
    margin-bottom: 3rem !important;
  }
  .lg\:ml-12,
  .lg\:mx-12 {
    margin-left: 3rem !important;
  }
  .lg\:p-0 {
    padding: 0 !important;
  }
  .lg\:pt-0,
  .lg\:py-0 {
    padding-top: 0 !important;
  }
  .lg\:pr-0,
  .lg\:px-0 {
    padding-right: 0 !important;
  }
  .lg\:pb-0,
  .lg\:py-0 {
    padding-bottom: 0 !important;
  }
  .lg\:pl-0,
  .lg\:px-0 {
    padding-left: 0 !important;
  }
  .lg\:p-1 {
    padding: 0.25rem !important;
  }
  .lg\:pt-1,
  .lg\:py-1 {
    padding-top: 0.25rem !important;
  }
  .lg\:pr-1,
  .lg\:px-1 {
    padding-right: 0.25rem !important;
  }
  .lg\:pb-1,
  .lg\:py-1 {
    padding-bottom: 0.25rem !important;
  }
  .lg\:pl-1,
  .lg\:px-1 {
    padding-left: 0.25rem !important;
  }
  .lg\:p-2 {
    padding: 0.5rem !important;
  }
  .lg\:pt-2,
  .lg\:py-2 {
    padding-top: 0.5rem !important;
  }
  .lg\:pr-2,
  .lg\:px-2 {
    padding-right: 0.5rem !important;
  }
  .lg\:pb-2,
  .lg\:py-2 {
    padding-bottom: 0.5rem !important;
  }
  .lg\:pl-2,
  .lg\:px-2 {
    padding-left: 0.5rem !important;
  }
  .lg\:p-3 {
    padding: 0.75rem !important;
  }
  .lg\:pt-3,
  .lg\:py-3 {
    padding-top: 0.75rem !important;
  }
  .lg\:pr-3,
  .lg\:px-3 {
    padding-right: 0.75rem !important;
  }
  .lg\:pb-3,
  .lg\:py-3 {
    padding-bottom: 0.75rem !important;
  }
  .lg\:pl-3,
  .lg\:px-3 {
    padding-left: 0.75rem !important;
  }
  .lg\:p-4 {
    padding: 1rem !important;
  }
  .lg\:pt-4,
  .lg\:py-4 {
    padding-top: 1rem !important;
  }
  .lg\:pr-4,
  .lg\:px-4 {
    padding-right: 1rem !important;
  }
  .lg\:pb-4,
  .lg\:py-4 {
    padding-bottom: 1rem !important;
  }
  .lg\:pl-4,
  .lg\:px-4 {
    padding-left: 1rem !important;
  }
  .lg\:p-5 {
    padding: 1.25rem !important;
  }
  .lg\:pt-5,
  .lg\:py-5 {
    padding-top: 1.25rem !important;
  }
  .lg\:pr-5,
  .lg\:px-5 {
    padding-right: 1.25rem !important;
  }
  .lg\:pb-5,
  .lg\:py-5 {
    padding-bottom: 1.25rem !important;
  }
  .lg\:pl-5,
  .lg\:px-5 {
    padding-left: 1.25rem !important;
  }
  .lg\:p-6 {
    padding: 1.5rem !important;
  }
  .lg\:pt-6,
  .lg\:py-6 {
    padding-top: 1.5rem !important;
  }
  .lg\:pr-6,
  .lg\:px-6 {
    padding-right: 1.5rem !important;
  }
  .lg\:pb-6,
  .lg\:py-6 {
    padding-bottom: 1.5rem !important;
  }
  .lg\:pl-6,
  .lg\:px-6 {
    padding-left: 1.5rem !important;
  }
  .lg\:p-7 {
    padding: 1.75rem !important;
  }
  .lg\:pt-7,
  .lg\:py-7 {
    padding-top: 1.75rem !important;
  }
  .lg\:pr-7,
  .lg\:px-7 {
    padding-right: 1.75rem !important;
  }
  .lg\:pb-7,
  .lg\:py-7 {
    padding-bottom: 1.75rem !important;
  }
  .lg\:pl-7,
  .lg\:px-7 {
    padding-left: 1.75rem !important;
  }
  .lg\:p-8 {
    padding: 2rem !important;
  }
  .lg\:pt-8,
  .lg\:py-8 {
    padding-top: 2rem !important;
  }
  .lg\:pr-8,
  .lg\:px-8 {
    padding-right: 2rem !important;
  }
  .lg\:pb-8,
  .lg\:py-8 {
    padding-bottom: 2rem !important;
  }
  .lg\:pl-8,
  .lg\:px-8 {
    padding-left: 2rem !important;
  }
  .lg\:p-9 {
    padding: 2.25rem !important;
  }
  .lg\:pt-9,
  .lg\:py-9 {
    padding-top: 2.25rem !important;
  }
  .lg\:pr-9,
  .lg\:px-9 {
    padding-right: 2.25rem !important;
  }
  .lg\:pb-9,
  .lg\:py-9 {
    padding-bottom: 2.25rem !important;
  }
  .lg\:pl-9,
  .lg\:px-9 {
    padding-left: 2.25rem !important;
  }
  .lg\:p-10 {
    padding: 2.5rem !important;
  }
  .lg\:pt-10,
  .lg\:py-10 {
    padding-top: 2.5rem !important;
  }
  .lg\:pr-10,
  .lg\:px-10 {
    padding-right: 2.5rem !important;
  }
  .lg\:pb-10,
  .lg\:py-10 {
    padding-bottom: 2.5rem !important;
  }
  .lg\:pl-10,
  .lg\:px-10 {
    padding-left: 2.5rem !important;
  }
  .lg\:p-11 {
    padding: 2.75rem !important;
  }
  .lg\:pt-11,
  .lg\:py-11 {
    padding-top: 2.75rem !important;
  }
  .lg\:pr-11,
  .lg\:px-11 {
    padding-right: 2.75rem !important;
  }
  .lg\:pb-11,
  .lg\:py-11 {
    padding-bottom: 2.75rem !important;
  }
  .lg\:pl-11,
  .lg\:px-11 {
    padding-left: 2.75rem !important;
  }
  .lg\:p-12 {
    padding: 3rem !important;
  }
  .lg\:pt-12,
  .lg\:py-12 {
    padding-top: 3rem !important;
  }
  .lg\:pr-12,
  .lg\:px-12 {
    padding-right: 3rem !important;
  }
  .lg\:pb-12,
  .lg\:py-12 {
    padding-bottom: 3rem !important;
  }
  .lg\:pl-12,
  .lg\:px-12 {
    padding-left: 3rem !important;
  }
  .lg\:m-auto {
    margin: auto !important;
  }
  .lg\:mt-auto,
  .lg\:my-auto {
    margin-top: auto !important;
  }
  .lg\:mr-auto,
  .lg\:mx-auto {
    margin-right: auto !important;
  }
  .lg\:mb-auto,
  .lg\:my-auto {
    margin-bottom: auto !important;
  }
  .lg\:ml-auto,
  .lg\:mx-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .xl\:m-0 {
    margin: 0 !important;
  }
  .xl\:mt-0,
  .xl\:my-0 {
    margin-top: 0 !important;
  }
  .xl\:mr-0,
  .xl\:mx-0 {
    margin-right: 0 !important;
  }
  .xl\:mb-0,
  .xl\:my-0 {
    margin-bottom: 0 !important;
  }
  .xl\:ml-0,
  .xl\:mx-0 {
    margin-left: 0 !important;
  }
  .xl\:m-1 {
    margin: 0.25rem !important;
  }
  .xl\:mt-1,
  .xl\:my-1 {
    margin-top: 0.25rem !important;
  }
  .xl\:mr-1,
  .xl\:mx-1 {
    margin-right: 0.25rem !important;
  }
  .xl\:mb-1,
  .xl\:my-1 {
    margin-bottom: 0.25rem !important;
  }
  .xl\:ml-1,
  .xl\:mx-1 {
    margin-left: 0.25rem !important;
  }
  .xl\:m-2 {
    margin: 0.5rem !important;
  }
  .xl\:mt-2,
  .xl\:my-2 {
    margin-top: 0.5rem !important;
  }
  .xl\:mr-2,
  .xl\:mx-2 {
    margin-right: 0.5rem !important;
  }
  .xl\:mb-2,
  .xl\:my-2 {
    margin-bottom: 0.5rem !important;
  }
  .xl\:ml-2,
  .xl\:mx-2 {
    margin-left: 0.5rem !important;
  }
  .xl\:m-3 {
    margin: 0.75rem !important;
  }
  .xl\:mt-3,
  .xl\:my-3 {
    margin-top: 0.75rem !important;
  }
  .xl\:mr-3,
  .xl\:mx-3 {
    margin-right: 0.75rem !important;
  }
  .xl\:mb-3,
  .xl\:my-3 {
    margin-bottom: 0.75rem !important;
  }
  .xl\:ml-3,
  .xl\:mx-3 {
    margin-left: 0.75rem !important;
  }
  .xl\:m-4 {
    margin: 1rem !important;
  }
  .xl\:mt-4,
  .xl\:my-4 {
    margin-top: 1rem !important;
  }
  .xl\:mr-4,
  .xl\:mx-4 {
    margin-right: 1rem !important;
  }
  .xl\:mb-4,
  .xl\:my-4 {
    margin-bottom: 1rem !important;
  }
  .xl\:ml-4,
  .xl\:mx-4 {
    margin-left: 1rem !important;
  }
  .xl\:m-5 {
    margin: 1.25rem !important;
  }
  .xl\:mt-5,
  .xl\:my-5 {
    margin-top: 1.25rem !important;
  }
  .xl\:mr-5,
  .xl\:mx-5 {
    margin-right: 1.25rem !important;
  }
  .xl\:mb-5,
  .xl\:my-5 {
    margin-bottom: 1.25rem !important;
  }
  .xl\:ml-5,
  .xl\:mx-5 {
    margin-left: 1.25rem !important;
  }
  .xl\:m-6 {
    margin: 1.5rem !important;
  }
  .xl\:mt-6,
  .xl\:my-6 {
    margin-top: 1.5rem !important;
  }
  .xl\:mr-6,
  .xl\:mx-6 {
    margin-right: 1.5rem !important;
  }
  .xl\:mb-6,
  .xl\:my-6 {
    margin-bottom: 1.5rem !important;
  }
  .xl\:ml-6,
  .xl\:mx-6 {
    margin-left: 1.5rem !important;
  }
  .xl\:m-7 {
    margin: 1.75rem !important;
  }
  .xl\:mt-7,
  .xl\:my-7 {
    margin-top: 1.75rem !important;
  }
  .xl\:mr-7,
  .xl\:mx-7 {
    margin-right: 1.75rem !important;
  }
  .xl\:mb-7,
  .xl\:my-7 {
    margin-bottom: 1.75rem !important;
  }
  .xl\:ml-7,
  .xl\:mx-7 {
    margin-left: 1.75rem !important;
  }
  .xl\:m-8 {
    margin: 2rem !important;
  }
  .xl\:mt-8,
  .xl\:my-8 {
    margin-top: 2rem !important;
  }
  .xl\:mr-8,
  .xl\:mx-8 {
    margin-right: 2rem !important;
  }
  .xl\:mb-8,
  .xl\:my-8 {
    margin-bottom: 2rem !important;
  }
  .xl\:ml-8,
  .xl\:mx-8 {
    margin-left: 2rem !important;
  }
  .xl\:m-9 {
    margin: 2.25rem !important;
  }
  .xl\:mt-9,
  .xl\:my-9 {
    margin-top: 2.25rem !important;
  }
  .xl\:mr-9,
  .xl\:mx-9 {
    margin-right: 2.25rem !important;
  }
  .xl\:mb-9,
  .xl\:my-9 {
    margin-bottom: 2.25rem !important;
  }
  .xl\:ml-9,
  .xl\:mx-9 {
    margin-left: 2.25rem !important;
  }
  .xl\:m-10 {
    margin: 2.5rem !important;
  }
  .xl\:mt-10,
  .xl\:my-10 {
    margin-top: 2.5rem !important;
  }
  .xl\:mr-10,
  .xl\:mx-10 {
    margin-right: 2.5rem !important;
  }
  .xl\:mb-10,
  .xl\:my-10 {
    margin-bottom: 2.5rem !important;
  }
  .xl\:ml-10,
  .xl\:mx-10 {
    margin-left: 2.5rem !important;
  }
  .xl\:m-11 {
    margin: 2.75rem !important;
  }
  .xl\:mt-11,
  .xl\:my-11 {
    margin-top: 2.75rem !important;
  }
  .xl\:mr-11,
  .xl\:mx-11 {
    margin-right: 2.75rem !important;
  }
  .xl\:mb-11,
  .xl\:my-11 {
    margin-bottom: 2.75rem !important;
  }
  .xl\:ml-11,
  .xl\:mx-11 {
    margin-left: 2.75rem !important;
  }
  .xl\:m-12 {
    margin: 3rem !important;
  }
  .xl\:mt-12,
  .xl\:my-12 {
    margin-top: 3rem !important;
  }
  .xl\:mr-12,
  .xl\:mx-12 {
    margin-right: 3rem !important;
  }
  .xl\:mb-12,
  .xl\:my-12 {
    margin-bottom: 3rem !important;
  }
  .xl\:ml-12,
  .xl\:mx-12 {
    margin-left: 3rem !important;
  }
  .xl\:p-0 {
    padding: 0 !important;
  }
  .xl\:pt-0,
  .xl\:py-0 {
    padding-top: 0 !important;
  }
  .xl\:pr-0,
  .xl\:px-0 {
    padding-right: 0 !important;
  }
  .xl\:pb-0,
  .xl\:py-0 {
    padding-bottom: 0 !important;
  }
  .xl\:pl-0,
  .xl\:px-0 {
    padding-left: 0 !important;
  }
  .xl\:p-1 {
    padding: 0.25rem !important;
  }
  .xl\:pt-1,
  .xl\:py-1 {
    padding-top: 0.25rem !important;
  }
  .xl\:pr-1,
  .xl\:px-1 {
    padding-right: 0.25rem !important;
  }
  .xl\:pb-1,
  .xl\:py-1 {
    padding-bottom: 0.25rem !important;
  }
  .xl\:pl-1,
  .xl\:px-1 {
    padding-left: 0.25rem !important;
  }
  .xl\:p-2 {
    padding: 0.5rem !important;
  }
  .xl\:pt-2,
  .xl\:py-2 {
    padding-top: 0.5rem !important;
  }
  .xl\:pr-2,
  .xl\:px-2 {
    padding-right: 0.5rem !important;
  }
  .xl\:pb-2,
  .xl\:py-2 {
    padding-bottom: 0.5rem !important;
  }
  .xl\:pl-2,
  .xl\:px-2 {
    padding-left: 0.5rem !important;
  }
  .xl\:p-3 {
    padding: 0.75rem !important;
  }
  .xl\:pt-3,
  .xl\:py-3 {
    padding-top: 0.75rem !important;
  }
  .xl\:pr-3,
  .xl\:px-3 {
    padding-right: 0.75rem !important;
  }
  .xl\:pb-3,
  .xl\:py-3 {
    padding-bottom: 0.75rem !important;
  }
  .xl\:pl-3,
  .xl\:px-3 {
    padding-left: 0.75rem !important;
  }
  .xl\:p-4 {
    padding: 1rem !important;
  }
  .xl\:pt-4,
  .xl\:py-4 {
    padding-top: 1rem !important;
  }
  .xl\:pr-4,
  .xl\:px-4 {
    padding-right: 1rem !important;
  }
  .xl\:pb-4,
  .xl\:py-4 {
    padding-bottom: 1rem !important;
  }
  .xl\:pl-4,
  .xl\:px-4 {
    padding-left: 1rem !important;
  }
  .xl\:p-5 {
    padding: 1.25rem !important;
  }
  .xl\:pt-5,
  .xl\:py-5 {
    padding-top: 1.25rem !important;
  }
  .xl\:pr-5,
  .xl\:px-5 {
    padding-right: 1.25rem !important;
  }
  .xl\:pb-5,
  .xl\:py-5 {
    padding-bottom: 1.25rem !important;
  }
  .xl\:pl-5,
  .xl\:px-5 {
    padding-left: 1.25rem !important;
  }
  .xl\:p-6 {
    padding: 1.5rem !important;
  }
  .xl\:pt-6,
  .xl\:py-6 {
    padding-top: 1.5rem !important;
  }
  .xl\:pr-6,
  .xl\:px-6 {
    padding-right: 1.5rem !important;
  }
  .xl\:pb-6,
  .xl\:py-6 {
    padding-bottom: 1.5rem !important;
  }
  .xl\:pl-6,
  .xl\:px-6 {
    padding-left: 1.5rem !important;
  }
  .xl\:p-7 {
    padding: 1.75rem !important;
  }
  .xl\:pt-7,
  .xl\:py-7 {
    padding-top: 1.75rem !important;
  }
  .xl\:pr-7,
  .xl\:px-7 {
    padding-right: 1.75rem !important;
  }
  .xl\:pb-7,
  .xl\:py-7 {
    padding-bottom: 1.75rem !important;
  }
  .xl\:pl-7,
  .xl\:px-7 {
    padding-left: 1.75rem !important;
  }
  .xl\:p-8 {
    padding: 2rem !important;
  }
  .xl\:pt-8,
  .xl\:py-8 {
    padding-top: 2rem !important;
  }
  .xl\:pr-8,
  .xl\:px-8 {
    padding-right: 2rem !important;
  }
  .xl\:pb-8,
  .xl\:py-8 {
    padding-bottom: 2rem !important;
  }
  .xl\:pl-8,
  .xl\:px-8 {
    padding-left: 2rem !important;
  }
  .xl\:p-9 {
    padding: 2.25rem !important;
  }
  .xl\:pt-9,
  .xl\:py-9 {
    padding-top: 2.25rem !important;
  }
  .xl\:pr-9,
  .xl\:px-9 {
    padding-right: 2.25rem !important;
  }
  .xl\:pb-9,
  .xl\:py-9 {
    padding-bottom: 2.25rem !important;
  }
  .xl\:pl-9,
  .xl\:px-9 {
    padding-left: 2.25rem !important;
  }
  .xl\:p-10 {
    padding: 2.5rem !important;
  }
  .xl\:pt-10,
  .xl\:py-10 {
    padding-top: 2.5rem !important;
  }
  .xl\:pr-10,
  .xl\:px-10 {
    padding-right: 2.5rem !important;
  }
  .xl\:pb-10,
  .xl\:py-10 {
    padding-bottom: 2.5rem !important;
  }
  .xl\:pl-10,
  .xl\:px-10 {
    padding-left: 2.5rem !important;
  }
  .xl\:p-11 {
    padding: 2.75rem !important;
  }
  .xl\:pt-11,
  .xl\:py-11 {
    padding-top: 2.75rem !important;
  }
  .xl\:pr-11,
  .xl\:px-11 {
    padding-right: 2.75rem !important;
  }
  .xl\:pb-11,
  .xl\:py-11 {
    padding-bottom: 2.75rem !important;
  }
  .xl\:pl-11,
  .xl\:px-11 {
    padding-left: 2.75rem !important;
  }
  .xl\:p-12 {
    padding: 3rem !important;
  }
  .xl\:pt-12,
  .xl\:py-12 {
    padding-top: 3rem !important;
  }
  .xl\:pr-12,
  .xl\:px-12 {
    padding-right: 3rem !important;
  }
  .xl\:pb-12,
  .xl\:py-12 {
    padding-bottom: 3rem !important;
  }
  .xl\:pl-12,
  .xl\:px-12 {
    padding-left: 3rem !important;
  }
  .xl\:m-auto {
    margin: auto !important;
  }
  .xl\:mt-auto,
  .xl\:my-auto {
    margin-top: auto !important;
  }
  .xl\:mr-auto,
  .xl\:mx-auto {
    margin-right: auto !important;
  }
  .xl\:mb-auto,
  .xl\:my-auto {
    margin-bottom: auto !important;
  }
  .xl\:ml-auto,
  .xl\:mx-auto {
    margin-left: auto !important;
  }
}
.p-content {
  padding: 1rem;
}
.p-content-md {
  padding: 2rem;
}
.p-content-lg {
  padding: 2rem;
}
@media (min-width: 992px) {
  .p-content-lg {
    padding: 3rem 7rem;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.hover\:text-decoration-none:hover {
  text-decoration: none;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .sm\:text-left {
    text-align: left !important;
  }
  .sm\:text-right {
    text-align: right !important;
  }
  .sm\:text-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .md\:text-left {
    text-align: left !important;
  }
  .md\:text-right {
    text-align: right !important;
  }
  .md\:text-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .lg\:text-left {
    text-align: left !important;
  }
  .lg\:text-right {
    text-align: right !important;
  }
  .lg\:text-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .xl\:text-left {
    text-align: left !important;
  }
  .xl\:text-right {
    text-align: right !important;
  }
  .xl\:text-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-italic {
  font-style: italic !important;
}
.title-fade {
  text-transform: uppercase;
  color: #939393;
}
.annotation {
  font-size: 0.85rem;
  line-height: 0.85rem;
  color: #34B991;
}
.text-white {
  color: #fff !important;
}
.text-black {
  color: #000 !important;
}
a.text-black:hover,
a.text-black:focus {
  color: #000000 !important;
}
.text-static-black {
  color: #000 !important;
}
.text-almost-black {
  color: #17171B !important;
}
a.text-almost-black:hover,
a.text-almost-black:focus {
  color: #000000 !important;
}
.text-static-almost-black {
  color: #17171B !important;
}
.text-darkest-gray {
  color: #28292F !important;
}
a.text-darkest-gray:hover,
a.text-darkest-gray:focus {
  color: #111113 !important;
}
.text-static-darkest-gray {
  color: #28292F !important;
}
.text-darker-gray {
  color: #333a46 !important;
}
a.text-darker-gray:hover,
a.text-darker-gray:focus {
  color: #1e2228 !important;
}
.text-static-darker-gray {
  color: #333a46 !important;
}
.text-dark-gray {
  color: #40424B !important;
}
a.text-dark-gray:hover,
a.text-dark-gray:focus {
  color: #292a2f !important;
}
.text-static-dark-gray {
  color: #40424B !important;
}
.text-gray {
  color: #636974 !important;
}
a.text-gray:hover,
a.text-gray:focus {
  color: #4c5058 !important;
}
.text-static-gray {
  color: #636974 !important;
}
.text-light-gray {
  color: #979EA8 !important;
}
a.text-light-gray:hover,
a.text-light-gray:focus {
  color: #7b8491 !important;
}
.text-static-light-gray {
  color: #979EA8 !important;
}
.text-lighter-gray {
  color: #C7CBCF !important;
}
a.text-lighter-gray:hover,
a.text-lighter-gray:focus {
  color: #acb2b7 !important;
}
.text-static-lighter-gray {
  color: #C7CBCF !important;
}
.text-lightest-gray {
  color: #E8E9EB !important;
}
a.text-lightest-gray:hover,
a.text-lightest-gray:focus {
  color: #cdcfd3 !important;
}
.text-static-lightest-gray {
  color: #E8E9EB !important;
}
.text-almost-white {
  color: #F5F6F7 !important;
}
a.text-almost-white:hover,
a.text-almost-white:focus {
  color: #d9dde0 !important;
}
.text-static-almost-white {
  color: #F5F6F7 !important;
}
.text-white {
  color: #fff !important;
}
a.text-white:hover,
a.text-white:focus {
  color: #e6e6e6 !important;
}
.text-static-white {
  color: #fff !important;
}
.text-navy {
  color: #003463 !important;
}
a.text-navy:hover,
a.text-navy:focus {
  color: #001930 !important;
}
.text-static-navy {
  color: #003463 !important;
}
.text-blue {
  color: #027AA2 !important;
}
a.text-blue:hover,
a.text-blue:focus {
  color: #015470 !important;
}
.text-static-blue {
  color: #027AA2 !important;
}
.text-green {
  color: #00C596 !important;
}
a.text-green:hover,
a.text-green:focus {
  color: #00926f !important;
}
.text-static-green {
  color: #00C596 !important;
}
.text-red {
  color: #FF5865 !important;
}
a.text-red:hover,
a.text-red:focus {
  color: #ff2536 !important;
}
.text-static-red {
  color: #FF5865 !important;
}
.text-success {
  color: #00C596 !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #00926f !important;
}
.text-static-success {
  color: #00C596 !important;
}
.text-info {
  color: #027AA2 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #015470 !important;
}
.text-static-info {
  color: #027AA2 !important;
}
.text-warning {
  color: #ffa64a !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #ff8c17 !important;
}
.text-static-warning {
  color: #ffa64a !important;
}
.text-danger {
  color: #FF5865 !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #ff2536 !important;
}
.text-static-danger {
  color: #FF5865 !important;
}
.text-body {
  color: #333a46 !important;
}
.text-muted {
  color: #979EA8 !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-link-hover:hover {
  text-decoration: none;
  color: #027AA2 !important;
}

