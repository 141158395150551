@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.ReactModal__Content.my-drawer-content {
  box-shadow: 0px 32px 72px rgba(50, 50, 93, 0.12), 0px 10px 15px rgba(0, 0, 0, 0.07), 0px -4px 16px rgba(0, 0, 0, 0.03);
  margin-right: -27rem;
  opacity: 0;
  transition: margin 200ms ease-in-out, opacity 200ms ease-in-out;
  background-color: white;
}

.my-drawer-content.ReactModal__Content--after-open {
  margin-right: 0rem;
  opacity: 1;
}

.my-drawer-content.ReactModal__Content--before-close {
  margin-right: -27rem;
  opacity: 0;
}

.custom-form-tooltip,
.custom-table-tooltip {
  box-shadow: 0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 8px !important ;
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-size: 0.875rem !important;
  line-height: 1.375rem;
  z-index: 1000 !important;
  opacity: 1 !important;
  padding: 1.8rem 2rem;
  max-width: 20rem;
}

.custom-form-tooltip {
  font-weight: 500;
}

.custom-table-tooltip {
  font-size: 1.125rem !important;
  line-height: 1.625rem;
}
